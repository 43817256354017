@import "variables";

.buddyModal {
  padding: 20px;

  h2 {
    font-weight: bold;
    text-align: center;
    margin: 10px 0 15px 0;
  }
}

.coverPhotoEdit,
.profilePictureWrapper {
  .loadingWrapper {
    z-index: 3;
    position: absolute;
    left: 0;
    top: 35px;
    right: 0;
    justify-content: center;
    align-items: center;
  }
}

.coverPhotoEdit {
  .loadingWrapper {
    top: 110px;
  }

  .fileUpload {
    margin: 0;
    width: 100%;
    position: absolute;
    top: 49px;
    left: 0;
    right: 0;
    height: 150px;
    background-color: black;

    input {
      width: 100%;
      height: 150px;
    }
  }

  .coverPhoto {
    height: 150px;
    background-position: center;
    background-size: cover;
  }
}

.page.profile {
  margin-left: -60px;
  display: flex;
  justify-content: center;

  &.locked {
    .profileCard {
      opacity: 0.5;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: block;
  }

  .profileCard {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    margin: 20px 10px;
    width: 350px;
    position: relative;
    overflow: hidden;
    label {
      top: 12px;
      background-color: white;
      padding: 0 5px;
    }

    .images-text {
      font-weight: bold;
      bottom: 15px;
      right: 15px;
      position: absolute;
      text-align: right;
      font-size: 14px;

      span {
        opacity: 0.5;
        font-size: 12px;
        font-weight: normal;
        display: block;
      }
    }

    &.images {
      height: 265px;

      .text {
        position: absolute;
        display: flex;
        color: white;
        font-weight: bold;
        font-size: 12px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0);
        transition: 0.4s;
        opacity: 0;

        .icon.photo {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          background-image: url("../img//photo.svg");
        }
      }
    }

    .bio {
      margin-top: 15px;
      .MuiInputBase-root {
        padding-top: 12px;
      }
      label {
        top: 12px;
        padding-left: 0;
      }

      p {
        line-height: 14px;
        opacity: 0;
      }
    }

    .profileContent.editing {
      .bio p {
        margin-bottom: 15px;
        opacity: 1;
      }
    }

    h3 {
      text-transform: none;
      color: black;
      font-weight: bold;
      font-size: 16px;
      border-bottom: 1px solid $light-grey;
      padding: 15px 20px;
      margin: -20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      height: 50px;
      z-index: 12;
      position: relative;
      background: white;

      .button {
        margin-right: -10px;
      }

      span {
        flex: 1;
      }
    }

    &.buddy {
      .inner {
        border-radius: 8px;
        background-color: transparent;
        cursor: pointer;
        transition: 0.4s;
        padding: 15px;

        &:hover {
          background-color: $light-grey;
        }
      }

      strong {
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        margin-top: 10px;
        font-size: 14px;
      }

      .buddy-container {
        margin: 0;

        .buddy-image {
          width: 125px;
          height: 125px;
        }
      }
    }
  }

  .profileWrapper {
    padding: 20px;
    transition: 0.4s;
    position: relative;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }

    .loader {
      left: 0;
      right: 0;
      position: absolute;
      pointer-events: none;
    }
  }

  .button.primary {
    animation: fadeInUp 0.4s;
    margin-top: 10px;
  }

  .saved {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeOutDown 1s;
    animation-delay: 1s;
    font-size: 14px;
    font-weight: 700;

    .check {
      display: block;
      background-image: url("../img/i-check.svg");
      margin: 10px;
      width: 50px;
      height: 50px;
      background-size: cover;
      border-radius: 100%;
    }
  }

  h2 {
    margin-bottom: 20px;
    margin-top: 10px;

    @media (max-width: $screen-sm-max) {
      text-align: center;
      margin-top: 40px;
    }
  }

  .MuiInputBase-root {
    margin: 10px 0 25px;
  }

  .MuiInputBase-input[type="text"] {
    border-bottom: 0px;
    margin-bottom: 0px;
    height: 35px;
    width: 250px;
  }

  .MuiTextField-root .MuiOutlinedInput-root {
    padding: 5px 15px 5px 15px;
  }

  .flexRow {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: $screen-sm-max) {
      align-items: center;
    }
  }

  form {
    @media (max-width: $screen-sm-max) {
      footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .inputSelect {
    .MuiFormLabel-root {
      transform: translate(20px, 22px);

      &.MuiInputLabel-shrink {
        transform: translate(0, -2px) scale(0.75);
      }
    }
  }

  .profileForm {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    .MuiInputBase-root {
      margin-bottom: 10px;

      &:before,
      svg {
        display: none;
      }
    }

    &.enabled {
      pointer-events: all;

      .MuiInputBase-root {
        margin-bottom: 30px;

        &:before,
        svg {
          display: block;
        }
      }
    }

    .jobTitle {
      pointer-events: none;
      opacity: 0.7;
      label {
        left: 15px;
        top: 5px;
      }
      .MuiInputBase-root {
        margin-bottom: 5px;

        &:before {
          display: none;
        }

        svg {
          display: none !important;
        }
      }
    }

    footer {
      display: flex;
      justify-content: center;
    }
  }
}

.profilePictureOnboarding {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  flex-direction: column;
  align-items: center;

  .confirmation {
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    z-index: 1;
    font-weight: bold;
    font-size: 24px;
    transition: 0.4s;
    pointer-events: none;
    animation: fadeInUp 1s;
  }

  .profilePictureWrapper {
    margin: 0;

    .tooltip {
      .button {
        display: none;
      }
    }

    .border {
      width: 203px;
      height: 203px;
    }
  }

  .tooltip {
    display: none;
  }
}

.profilePictureWrapper {
  cursor: pointer;
  position: relative;
  transition: opacity 0.4s;
  opacity: 1;
  height: 100px;
  width: 100px;
  position: absolute;
  top: 145px;
  background-color: white;
  border-radius: 100%;

  @media (max-width: $screen-sm-max) {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  img {
    opacity: 0.2;
  }

  .profilePicture {
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    background-position: center;
    border: 6px solid white;
  }

  .border {
    width: 203px;
    height: 203px;
    position: absolute;
    left: -3px;
    top: -3px;
    background-image: url("../img/dotted-circle.svg");
    background-size: 100%;
  }

  .tooltip {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .button {
      margin-top: 0px;
    }
  }
}

.fileUpload {
  width: 200px;
  height: 200px;
  position: relative;
  transform: scale(1);
  transition: 0.4s;
  margin: 0 auto;
  transition: 0.4s;
  opacity: 1;
  cursor: pointer;

  &:hover {
    .text {
      opacity: 1 !important;
      background-color: rgba(0, 0, 0, 0.6) !important;
    }
  }

  &.isDragging {
    transform: scale(1.1);
    opacity: 0.5;
    top: 42px !important;
  }

  &.isUploading {
    transform: scale(1.1);
    opacity: 0.1;
  }

  &.userPictureUpload {
    &.isDragging {
      top: 0 !important;
    }
  }

  .tooltip {
    width: 200px;
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    flex-direction: column;

    span {
      font-weight: 400;
      color: #686868;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      margin-top: 10px;
    }
  }

  input {
    cursor: pointer;
    z-index: 5;
    width: 200px;
    height: 200px;
    opacity: 0;
    position: absolute;
  }

  &.userPictureUpload {
    width: 100px;
    height: 100px;

    input {
      width: 100px;
      height: 100px;
    }
  }
}

.groupList {
  ul {
    margin: 0 -20px;
    margin-top: -20px;
  }
  li {
    padding: 10px 20px;
    border-bottom: 1px solid $light-grey;
  }
  .icon.school {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    border: 3px solid white;
    box-sizing: content-box;
    background-image: url("../img/school.svg");
  }
}
