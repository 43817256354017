@import "variables";

.showMorePopover {
  .MuiPopover-paper {
    overflow: visible;
    margin-top: 6px;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      margin-right: -20px;
      right: 50%;
    }
  }
}

.icon.google-symbol {
  background-image: url("../img/google-symbol.svg");
}

#view-select {
  strong {
    display: none;
  }
}

#current-time-indicator {
  background-color: $green;
  height: 2px;
}

.viewSelect {
  margin-right: 50px;

  .label {
    margin-right: 30px;
  }
}

.keySelect {
  .label {
    width: 135px;
  }

  strong {
    background-color: $light-grey;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    font-size: 12px;
  }
}

.sameDayEvents {
  padding: 0;
  list-style-type: none;

  a {
    padding: 10px 20px;
    align-items: center;
    display: flex;
    color: black;
    background-color: white;
    transition: 0.3s;

    &:hover {
      background-color: $light-grey;
    }

    .rsvp {
      width: 20px !important;
      height: 20px !important;
      margin: 0 10px 0 0;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 100%;
    }

    strong {
      flex: 1;
      margin-right: 50px;
    }

    .time {
      width: 80px;
      margin-right: 10px;
    }
  }
}

.monthCell {
  display: table-cell;
  padding: 0;
  font-size: 0.875rem;
  text-align: left;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgb(224, 224, 224);
  border-right: 1px solid rgb(224, 224, 224);
  vertical-align: inherit;
  border-radius: 0px;

  div {
    background-color: white;
  }
}

.maxEventOverride {
  display: table-cell;
  padding: 0;
  font-size: 0.875rem;
  text-align: left;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;

  .Cell-text {
    padding: 1em;
    text-align: center;
    padding-top: 0.5em;
    margin-top: -27px;
  }
}

.showMoreWrapper {
  z-index: 4;
  position: relative;
  text-align: right;
  cursor: pointer;
  padding-right: 3px;
  padding-top: 7px;
  opacity: 0.7;
  border-top: 1px solid rgb(224, 224, 224);
  transition: 0.3s;
  top: -1px;

  &:hover {
    opacity: 1;
  }

  .showMore {
    border-radius: 18px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    padding: 5px;
    background-color: $light-grey;
  }
}

.page.calendarLayout {
  padding: 0px 0px 100px;
  position: relative;

  .loadingWrapper {
    margin-left: 10px;
  }

  .controlWrapper {
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: $screen-sm-max) {
    margin-left: -50px;

    .calendarInner {
      margin-top: -15px;
      margin-left: 0 !important;
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      height: 100vh;
      overflow: scroll;
      padding-bottom: 100px;

      &.hidden {
        position: absolute;
      }

      .toolbar {
        padding: 0px !important;
        padding-bottom: 50px;

        .button.small {
          background-color: $light-grey;
          margin-bottom: 25px;
          margin-right: 0 !important;
          border-radius: 20px;
        }

        .checkbox {
          width: 250px;
        }
      }

      .searchWrapper {
        overflow: hidden;
      }

      .inputSearch {
        .MuiFormControl-root {
          width: 100%;
        }
      }

      .monthSwitcher {
        font-size: 16px !important;

        span {
          text-align: center;
          flex: 1;
        }

        .caret {
          position: absolute;
        }

        .caret.flipped {
          right: 10px;
        }
      }

      .searchWrapper,
      .viewControl {
        margin: 15px 0;
      }

      .viewControl {
        margin-right: 0 !important;

        .MuiFormControl-root {
          width: 100%;
        }
      }
    }
  }

  .icon.google-symbol {
    background-image: url("../img/google-symbol.svg");
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
    left: -8px;
    animation: rotate 3s infinite;
    animation-timing-function: linear;
  }

  .button.googleSync {
    animation: fadeIn 0.3s forwards;
  }

  .pageHidden {
    display: block;
    content: "";
    height: 50vh;
    position: fixed;
    left: 280px;

    background-color: white;
    z-index: -1;
    right: 35px;
    top: 150px;

    &.top {
      z-index: 5;
      top: 50px;
      height: 100px;
      right: 0;
      left: 270px;
      background-color: $light-grey;
    }
  }

  header.flexRow {
    position: relative;
    margin-bottom: 20px;
    max-width: 1440px;
  }

  .MuiPaper-rounded {
    transition: margin-top 0.5s;
  }

  &.addPadding .MuiPaper-rounded {
    margin-top: 80px;
  }

  &.filtersOpen .MuiPaper-rounded {
    margin-top: 130px;
  }

  &.filtersOpen .Schedule .MuiPaper-root.MuiPaper-rounded {
    margin-top: 130px;
  }

  .MuiPaper-rounded {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: none;
  }

  .searchWrapper {
    margin-top: -15px;
    margin-right: 20px;

    .MuiInputBase-root {
      margin-top: 0px;
    }

    input {
      height: 2rem;
      margin-bottom: 0;
      margin-top: 15px;
    }
  }

  .calendarInner {
    padding: 0;
    background-color: transparent;
    box-shadow: none !important;

    .MuiPaper-root {
      box-shadow: none !important;
    }

    &.Month {
      .monthCell {
        height: calc(10.8vh + 10px) !important;
      }

      .MuiPaper-root {
        background-color: transparent;
        border-radius: 12px;
        box-shadow: none;

        td[tabindex="0"] {
          height: 11vh;
        }
      }

      [class*="ordinaryHeaderBorder"] {
        flex: 1;

        table tbody,
        table tr:hover {
          div {
            font-size: 14px;
            padding-bottom: 5px;
            color: black;
          }
        }
      }
    }

    &.Schedule {
      background-color: white;
      box-shadow: none;

      .MuiPaper-root {
        box-shadow: none;

        border-top: 1px solid $light-grey;
        margin-top: 70px;

        .calendarList {
          border-radius: 12px;

          .MuiPaper-rounded {
            margin-top: 0;
          }
        }
      }
    }

    &.Week,
    &.Day {
      [class*="ordinaryHeaderBorder"] {
        flex: 1;
        table tbody,
        table tr:hover {
          div {
            p {
              font-size: 12px;
              text-transform: uppercase;
              padding-top: 10px;
            }

            div {
              padding-bottom: 10px;
            }

            p,
            div {
              color: black;
            }
          }
        }
      }
    }

    .MuiPaper-root {
      background-color: transparent;
      border-radius: 0 0 6px 6px;

      > div > div > div > div {
        background-color: transparent;
      }

      table {
        max-width: none;
      }
    }
  }

  header.toolbar {
    background-color: white;
    border-radius: 12px 12px 0 0;
    flex-direction: column;
    padding: 10px;
    top: 90px;
    margin-bottom: 0;
    position: fixed;
    z-index: 11;
    right: 35px;
    left: 280px;

    .filtersRow {
      margin-top: 60px;
      padding-left: 20px;
      padding-right: 20px;
      pointer-events: all;
      position: absolute;
      right: 0px;
      display: flex;
      align-items: center;
      left: 0px;
      justify-content: flex-end;
    }

    .flexRow {
      padding: 10px;
    }

    .loadingWrapper {
      position: relative;
      font-size: 12px;
      flex: 1;
      z-index: 5;
      display: flex;
      justify-content: flex-end;
      top: 2px;
    }
  }

  .calendarInner {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    transition: 0.6s;
    padding-bottom: 5px;

    td {
      background-color: white;
    }

    td > div {
      p {
        color: rgba(0, 0, 0, 0.6);
        font-family: "Nunito";
      }

      div {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .MuiPaper-root > div > div {
      overflow: hidden;
    }

    tr {
      display: flex;
      position: relative;

      td {
        border-radius: 0px;
        flex: 1;
      }
    }

    .monthSwitcher {
      font-size: 24px;
      font-weight: bold;
      height: 42px;
      display: flex;
      flex: 1;
      align-items: center;

      &.hidden {
        pointer-events: none;
        opacity: 0;
      }

      .caret {
        transform: rotate(90deg) scale(0.8);
        background-image: url("../img/caret.svg");
        background-size: cover;
        box-sizing: content-box;
        opacity: 0.35;
        width: 25px;
        height: 25px;
        background-size: 25px;
        background-position: center top 7px;
        background-repeat: no-repeat;
        cursor: pointer;
        padding: 10px;
        border-radius: 100%;
        transition: 0.3s;

        &.flipped {
          transform: rotate(-90deg) scale(0.8);
          margin-right: 10px;
        }

        &:hover {
          background-color: rgba(201, 201, 201, 0.7);
        }
      }
    }

    .toolbar {
      padding: 0px 20px;
      display: flex;

      .inputSearch {
        position: relative;

        .MuiInputBase-input {
          padding-left: 20px;
        }

        [data-shrink="false"] {
          transition: 0.3s;
          margin-left: 20px;
        }

        [data-shrink="true"] {
          transition: 0.3s;
          margin-left: 0px;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          background-size: contain;
          left: 10px;
          bottom: 17px;
          opacity: 0.4;
          background-image: url("../img/search.svg");
          width: 15px;
          height: 15px;
        }
      }

      .tagSelectWrapper {
        width: 175px;
        margin-right: 10px;

        .selected {
          position: absolute;
          right: 590px;
          margin-top: -7px;
        }

        .suggestions {
          width: 175px;
        }

        .tagInput {
          position: relative;
          margin-right: 20px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            background-size: contain;
            left: 0;
            bottom: 4px;
            opacity: 0.4;
            background-image: url("../img/tag.svg");
            width: 15px;
            height: 15px;
          }

          input {
            font-size: 16px;
            margin-bottom: 0;
            position: relative;
            top: 2px;
            padding-left: 22px;

            &::placeholder {
              opacity: 0.6;
              color: black;
            }
          }
        }
      }

      @media (max-width: $screen-sm-max) {
        flex-direction: column;
      }

      .button.small {
        margin-right: 5px;

        &.hidden {
          pointer-events: none;
          opacity: 0;
        }
      }

      .viewControl {
        position: relative;
        z-index: 15;
        display: flex;
        margin-right: 20px;
        align-items: center;

        .button.small {
          &:active,
          &:focus {
            background-color: white !important;
          }
        }
      }

      a {
        text-decoration: none !important;
      }

      .button.primary {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: normal;
        border-radius: 8px;
        padding: 8px 20px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.14);
      }

      .checkbox {
        margin-left: 5px;
        color: black;
      }

      .MuiRadio-root {
        opacity: 0;
        position: absolute;
        pointer-events: none;
      }

      .viewControl {
        .MuiFormControlLabel-root {
          position: relative;
          z-index: 12;
          background-color: $light-grey;
          padding: 5px 10px;
          margin: 0;
          border-right: 1px solid white;
          transition: 0.3s;

          .MuiTypography-root {
            width: 65px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
          }

          &:first-child {
            border-radius: 6px 0 0 6px;
          }

          &:last-child {
            border-right: none;
            border-radius: 0 6px 6px 0;
          }

          &.active {
            color: white;
            background-color: #575757;
          }
        }
      }
    }
  }
}

.calendarInner.Day {
  .loadingWrapper {
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    bottom: 0;
    top: 120px;
    z-index: 6;
    background-color: rgba(250, 250, 250, 0.7);
    z-index: 13;
    position: absolute;
    flex-direction: column;
  }
}

.scheduleWrapper {
  height: 620px;
  position: relative;
  left: -100px;
  margin-right: -120px;
  padding-right: 40px;
  transition: 0.5s;
  opacity: 1;
  margin-top: 15px;
}

.appointmentInner {
  color: white;
  padding: 5px 5px 5px 0;
  line-height: 11px;
  font-weight: bold;
  font-size: 10px;
  height: 100%;
  background-color: white;
  transition: 0.3s;
  cursor: pointer;
  padding-left: 6px;
  &.conference-event {
    padding-left: 3px;
    padding-top: 2px;
    header > div {
      margin-bottom: -10px;
    }
  }
  .timeLabel {
    font-family: "Nunito";
    font-weight: normal;
    font-size: 9px;
    padding-left: 2px;
  }

  @media (max-width: $screen-sm-max) {
    padding-left: 1px;
  }

  &.decline {
    text-decoration: line-through !important;
    border: 2px solid $red;
    border-radius: 6px;
    background-color: white !important;
    color: $red !important;

    div {
      color: $red !important;
    }
  }

  &.accept,
  &.attending {
    padding-left: 5px;

    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      border-right: 1px solid white;
      width: 5px;
      background-color: $green;
    }
  }

  &:hover {
    background-color: #50a8c6;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.conference {
    background-color: $dark-blue;

    &:hover {
      background-color: lighten($dark-blue, 10%);
    }
  }

  > div {
    margin-right: 15px;
  }

  &.userGoal {
    background-color: #24dc91;
    color: black;

    &:hover {
      background-color: lighten(#24dc91, 15%) !important;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 15px;
      width: 15px;
      background-image: url("../img/goal.svg");
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: center left 2px;
      right: 4px;
      top: 3px;
      border-radius: 100%;
    }
  }
}

a {
  text-decoration: none !important;
}

#sessionTooltipContainer {
  .MuiPaper-root {
    min-width: 650px;
    border-radius: 12px;
  }

  .loadingWrapper {
    position: absolute;
    right: 0;
    left: 0;
    top: 33px;
    display: flex;
    justify-content: center;
    z-index: 5;
  }
}

.sessionToolTip {
  margin-top: -14px;
  width: 650px;
  position: relative;

  .inner {
    padding: 20px 20px;

    .description {
      max-height: 35vh;
      overflow: auto;
    }

    .button.large {
      min-width: 0px;
      padding: 10px;
      width: 40px !important;
      height: 40px;
      margin-left: 25px;
    }

    .MuiInputLabel-shrink {
      transform: translate(0, 0px) scale(0.75);
    }

    .MuiInput-underline:after,
    .MuiInput-underline:before {
      bottom: 0px !important;
    }

    .MuiInputBase-input {
      margin-bottom: 0px;
    }

    .MuiFormControl-root {
      width: 100px;
      margin-left: 10px;
    }
  }

  label + .MuiInput-formControl {
    margin-top: 0;
  }

  .icon.check {
    background-size: 35px;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 15px;
    top: 30px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    z-index: 4;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px 10px;
  }

  header {
    font-weight: bold;
    font-size: 16px;
  }

  .description {
    position: relative;
  }

  .buddy-container {
    max-width: 100%;
    animation: fadeIn 1s;
    margin-bottom: -20px;
  }

  .buddy-message {
    border-radius: 14px;
    width: 100%;
    flex: 1;

    p {
      padding-top: 0px;
    }

    .buddy-image {
      margin-top: -60px;
    }
  }

  .sessionImage {
    height: 150px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;

    .rsvpButton {
      position: absolute;
      right: 15px;
      top: 30px;
      z-index: 4;

      &.active {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
        border-radius: 35px;

        .button {
          width: 35px;
          height: 35px;
          padding: 0;
          min-width: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          &.accept {
            background-color: $green;

            .icon {
              background-image: url("../img/rsvp-new.svg");
            }
          }

          &.decline {
            background-color: $red;

            .icon {
              background-image: url("../img/rsvp-decline-new.svg");
            }
          }

          &.tentative {
            background-color: #efefef;

            .icon {
              background-image: url("../img/rsvp-tentative-new.svg");
            }
          }

          .icon {
            margin: 0;
          }
        }
      }

      .button {
        min-width: 100px;
      }
    }

    .roomInfo {
      position: absolute;
      top: 35px;
      left: 10px;
      color: white;
      z-index: 4;
      display: flex;
      align-items: center;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
      font-size: 14px;

      .icon {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-right: 5px;

        &.room {
          background-image: url("../img/room-white.svg");
        }

        &.video-chat {
          background-image: url("../img/video-chat.svg");
        }
      }
    }

    h2 {
      color: white;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
      position: absolute;
      bottom: 25px;
      left: 10px;
      right: 10px;
      z-index: 2;
      font-weight: bold;
      font-size: 20px;
      text-align: center;

      .capsuleWrapper {
        position: absolute;
        font-weight: normal;
        bottom: -40px;
        height: 35px;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;

        section {
          display: flex;
          align-items: center;
          margin: 0 10px;
        }

        .icon.chair {
          background-image: url("../img/chair-white.svg");
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }

      .categoryImage {
        margin-right: 5px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 2px solid white;
        background-position: center;
        background-size: cover;
      }

      .dateTime {
        padding: 3px 15px;
        font-weight: normal;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 150px;
      left: 0;
      top: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75) 19%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}

.eventTooltip {
  margin-top: -14px;

  .eventImage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 175px;
    z-index: 3;
    background-size: cover;
    background-position: center;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;

    .rsvp {
      flex: 1;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      .icon {
        position: relative;
        top: 5px;
        left: 2px;
        background-repeat: no-repeat;
        background-image: url("../img/rsvp.svg");
        width: 20px;
        height: 20px;

        &.rsvp-decline {
          background-image: url("../img/rsvp-decline.svg");
        }

        &.rsvp-tentative {
          background-image: url("../img/rsvp-tentative.svg");
        }
      }
    }
  }

  header {
    margin-bottom: 15px;
    line-height: 20px;
    display: flex;
    font-size: 20px;
    font-weight: bold;
  }

  .icon.goal {
    background-image: url("../img/goal.svg");
    background-size: contain;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    display: block;
    flex-shrink: 0;
    padding: 0;
  }

  .chip {
    border-radius: 100%;
    margin-right: 10px;
    background-color: $blue;
    width: 20px;
    height: 20px;
    display: block;
    flex-shrink: 0;
    padding: 0;
  }

  .description {
    font-size: 14px;
    max-height: 30vh;
    overflow: auto;
  }
}

.icon.location {
  background-image: url("../img/location.svg");
}

.icon.video-conference {
  background-image: url("../img/video-conference.svg");
}

.flexRow.meta {
  align-items: flex-start;
  font-size: 14px;
  margin-bottom: 10px;

  .flexRow {
    padding-left: 20px;

    span {
      width: 10px;
      height: 10px;
      display: block;
      background-color: $blue;
      border-radius: 100%;
      left: 50px;
      position: absolute;
      margin-top: 4px;

      &.timeline:before {
        background-color: $blue;
        width: 2px;
        height: 30px;
        left: 4px;
        position: relative;
        content: "";
        display: block;
      }
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    opacity: 0.35;

    &.schedule {
      background-image: url("../img/calendar.svg");
    }
  }
}

.currentDay {
  font-weight: bold;
  background-color: transparent !important;

  div div {
    border-radius: 100%;
    position: relative;
    height: 23px;
    padding: 0 !important;
    width: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.icon.google-calendar.blue {
  background-color: rgba(0, 0, 0, 0) !important;
  background-image: url("../img/google-calendar-logo-blue.svg");
}

.calendarList {
  position: relative;
  z-index: 4;
  background-color: white;

  .buddy-wrapper {
    padding-top: 0;
    animation: fadeInDown 0.4s forwards;
  }

  .MuiAccordionSummary-root {
    width: 100%;
    padding-right: 20px;
  }

  .fetchButtonContainer {
    display: flex;
    justify-content: center;
    border: 0;
  }

  .loadingWrapper {
    position: absolute;
    right: 35px !important;
    display: flex;
    justify-content: center;
    z-index: 5;
    top: 12px !important;
  }

  ul {
    list-style-type: none;
    overflow: auto;
    margin: 0px -20px 0 0;
    padding: 10px 20px 0;

    .month-header {
      padding: 8px;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      background-color: #f0f0f0;
      margin: 10px 0px;
      border-radius: 80px;
      margin-right: 20px;
      cursor: auto;
    }

    @media (max-width: $screen-sm-max) {
      padding: 0px;

      .MuiButtonBase-root {
        padding: 0 !important;
      }

      .flexRow {
        .dateTime {
          margin-left: -15px;
          transform: scale(0.9);
        }

        .time {
          margin-left: -25px;
          font-size: 10px;
          min-width: 20px;
        }
      }
    }

    .MuiAccordionSummary-root:focus {
      background-color: white !important;
    }

    .MuiAccordion-rounded {
      border-radius: 0 !important;
    }

    li {
      border-bottom: 1px solid $light-grey;
      cursor: pointer;
      position: relative;

      &:before {
        display: block;
        width: 5px;
        background-color: transparent;
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0px;
      }

      &.Accept:before {
        background-color: $green;
      }

      &.Tentative:before {
        background-color: #000;
        opacity: 0.5;
      }

      &.Decline:before {
        background-color: $red;
      }

      .flexRow {
        flex: 1;
      }

      .rsvp {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        text-align: right;
        width: 150px;
        justify-content: flex-end;

        .icon {
          margin-left: 10px;
          width: 20px;
          height: 20px;
        }
      }

      a {
        margin-left: 25px;
        display: block;
      }

      .weekMonth {
        opacity: 0.5;
        font-weight: lighter;
        line-height: 18px;
        text-transform: uppercase;
        text-align: center;
        width: 50px;
      }

      .dateTime {
        display: flex;
        align-items: center;
      }

      .date {
        margin-right: 35px;
        width: 40px;
        display: block;
        text-align: center;
        font-size: 28px;
      }

      &:last-child {
        border-bottom: none;
      }

      .title {
        margin-left: 10px;
        flex: 1;

        header {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
        }
      }

      p {
        margin: 10px 0 0;
        font-size: 14px;
      }

      .MuiAccordionDetails-root {
        padding-left: 25px;
        flex-direction: column;
      }

      .flexRow.meta {
        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .time {
        font-size: 12px;
        display: flex;
        align-items: center;

        .icon.calendar {
          background-image: url("../img/calendar.svg");
          border-radius: 0;
          opacity: 0.5;
        }

        span {
          margin-right: 5px;
          flex-shrink: 0;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          display: block;
        }
      }
    }
  }
}

.banner {
  background-color: rgb(62, 136, 160);
  text-align: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -15px;
  right: 100px;
  left: 100px;
  display: flex;
  padding: 20px 0px 5px;
  border-radius: 0 0 25px 25px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 17;

  &:before {
    display: inline-block;
    content: "";
    width: 18px;
    height: 18px;
    background-image: url("../img/conference-white.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
    background-size: 15px;
  }
}

.adjustRange {
  left: 0;
  right: 0;
  font-size: 18px;
  font-weight: bold;
  height: 42px;
  display: flex;
  align-items: center;
  transition: 0.4s;
  position: relative;
  top: 0;
  opacity: 1;
  flex: 1;

  &.shift {
    top: -30px;
    opacity: 0;
  }

  &.center {
    justify-content: center;
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .caret {
    transform: rotate(90deg) scale(0.8);
    background-image: url("../img/caret.svg");
    background-size: cover;
    opacity: 0;
    width: 45px;
    height: 45px;
    background-size: 25px;
    background-position: center top 7px;
    background-repeat: no-repeat;
    cursor: pointer;
    padding: 10px;
    border-radius: 100%;
    transition: 0.3s;
    pointer-events: none;
    transition: 0.3s;

    &.flipped {
      transform: rotate(-90deg) scale(0.8);
      margin-right: 10px;
    }

    &.active {
      opacity: 0.3;
      pointer-events: all;
    }

    &:hover {
      background-color: rgba(201, 201, 201, 0.7);
    }
  }
}

.conferenceScheduleWrapper {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  position: relative;
  min-width: 670px;
  overflow: hidden;
  border-radius: 12px;
  margin-top: -35px;
}

.calendar-legend {
  padding: 15px 10px 10px 15px;

  .item {
    font-size: 12px;
    margin-right: 30px;
    display: flex;
    align-items: center;
  }

  .circle {
    margin-right: 5px;
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 5px;
  }
}

.MainLayout-leftPanel {
  table {
    margin-top: 35px;
    td {
      .Label-label {
        height: 50px;
      }
      .Label-emptyLabel {
        height: 0px;
      }
    }
  }
}
