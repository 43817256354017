@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  background-color: #f0f0f0;
  overflow: auto !important;
  padding: 0 !important;
  font-family: "Nunito", sans-serif;
  margin: 0px;

  > iframe {
    pointer-events: none !important;
    z-index: -1 !important;
  }
}

.appWrapper {
  background-color: $light-grey;
}

#root {
  margin-top: 50px;
}

input {
  font-family: "Nunito" !important;
}

.MuiSelect-select {
  font-family: "Nunito" !important;
}

.MuiInputLabel-formControl {
  font-family: "Nunito" !important;
}

.buddy-message {
  p {
    margin: 0 !important;
    text-align: center;
  }
}

#menu- {
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      font-family: "Nunito";
      flex: 1;
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      padding: 8px 12px;
    }
  }
}

main {
  padding: 35px;
  transition: margin-left 0.4s;
  margin-left: 60px;
  position: relative;
  transition: 0.4s;
  padding-left: 30px;

  @media (max-width: $screen-md-max) {
    left: 0;

    &[data-collapsed="false"] {
      padding-left: 0 !important;
      left: 200px;

      @media (min-width: $screen-sm-max) {
        margin-right: 220px;
        left: 220px;
      }
    }
  }

  &[data-is-tour-started="true"] {
    pointer-events: none;
  }

  &[data-collapsed="false"] {
    padding-left: 220px;

    .mobileFilters {
      left: 250px;
    }
  }

  &[data-is-print="true"] {
    padding: 0;
    margin: 0;
  }

  @media (max-width: $screen-sm-max) {
    padding: 50px 10px 20px 0;
  }
}

h2 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0px;
}

input {
  box-shadow: none !important;
}

strong {
  font-weight: 700;
}

.timeSpentToggle {
  transition: 0.3s;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: $light-grey;
  }
}

.timeSpentPopover {
  pointer-events: none;
  margin-top: -5px;

  .MuiPaper-root {
    max-width: 3000px;
    width: 320px;
  }

  ul {
    padding: 10px 0;
    margin: 0;
    width: 320px;
  }

  li {
    padding: 10px;

    .icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 5px;

      &.laptop {
        background-image: url("../img/laptop-black.svg");
      }

      &.external-pd {
        background-image: url("../img/external-black.svg");
      }

      &.calendar {
        background-size: 90%;
        background-image: url("../img/calendar.svg");
      }
    }

    .count {
      flex: 1;
      text-align: right;
      color: #59c3e5;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.browserWarning {
  border-radius: 4px;
  line-height: 18px;
  padding: 15px 15px 15px 50px;
  background-image: url("../img/i-information.svg");
  background-size: 20px;
  background-position: left 15px center;
  background-repeat: no-repeat;
  font-size: 14px;
  margin-bottom: 20px;

  a {
    font-weight: 600;
    text-decoration: underline;
    color: black;
  }
}
.revisionModalContainer {
  .MuiPaper-root {
    max-width: 100%;
  }
}

.revisionModal {
  footer {
    font-style: italic;
    padding: 0 !important;
    margin-bottom: -25px;
    margin-top: 10px !important;
    font-size: 12px;
  }
}

.view-toggle {
  .icon {
    width: 30px;
    height: 30px;
    background-size: 60px;
    background-position: left center;
    background-repeat: no-repeat;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 3px;

    &.grid {
      background-image: url("../img/i-grid.svg");
    }

    &.list {
      background-image: url("../img/i-list.svg");
    }

    &.active {
      background-position: right center;
    }
  }
}

.flipper {
  background-color: transparent;
  perspective: 1000px;

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.4s;
    transform-style: preserve-3d;
  }

  &:hover .inner {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    position: absolute;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #f0f0f0;
  }

  .back {
    transform: rotateY(180deg);
  }
}

.saveToggle {
  cursor: pointer;
  height: 25px;
  width: 60px;
  display: inline-block;
  margin-bottom: -2px;
  margin-left: 15px;
  animation: fadeInUp 0.4s;
  transform: scale(1);
  opacity: 1;
  transition: 0.4s;

  &.isUnsaved {
    transform: scale(0);
    opacity: 0;
  }

  .button {
    position: absolute;
    margin-left: -50px;
    transition: margin-left 0.4s;
  }

  &:hover {
    .icon {
      margin-left: 0px;
    }

    .button {
      margin-left: 5px;
    }
  }

  .flipper {
    height: 25px;

    .back {
      margin-left: 70px;
      margin-top: -3px;

      .icon {
        transform: scaleX(-1);
        margin-top: -1px;
      }
    }

    .front,
    .back {
      background-color: #f0f0f0;
    }

    .icon {
      margin-left: 1px;
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}

.appInner {
  background-color: #f0f0f0;
  position: relative;
  z-index: 10;
  min-height: 80vh;
}

.flexRow {
  width: 100%;
  display: flex;
  align-items: center;
}

.layout.achievements {
  padding-bottom: 150px;
}

a {
  .button.primary {
    color: black;
    text-transform: none;
  }
}

.button.small {
  background-color: transparent;

  &:hover {
    background-color: lighten($light-grey, 2%);
  }

  .MuiButton-label {
    text-align: left;
  }
}

.userInitials-initials {
  font-family: "Nunito";
}

textarea {
  font-family: "Nunito" !important;
}

.page.eventsAttended {
  position: relative;
  max-width: 1080px;
  padding-top: 30px;
  transition: 0.3s;

  .MuiGrid-root {
    position: relative;

    .button.small {
      position: absolute;
      bottom: 15px;
      right: 20px;

      .MuiButton-label {
        font-size: 10px;
      }
    }
  }

  .MuiCardContent-root {
    padding: 0;
    min-height: 240px;
    cursor: pointer;

    &:hover {
      footer {
        padding-bottom: 20px;
      }

      .event-bg {
        height: 105px;
      }
    }

    .capsuleContainer {
      .capsule {
        background-color: black !important;
        color: white;

        .icon {
          background-image: url("../img/clock-white.svg") !important;
        }
      }

      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      margin-top: -25px;
    }

    .capsule {
      padding: 5px 10px;
      color: black;
      font-size: 12px;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      border-radius: 25px;
    }

    .event-bg {
      transition: 0.4s;
      position: relative;
      height: 125px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../img/event-backdrop.svg");
      background-color: $blue;
      background-size: 65px;
      background-position: center bottom 15px;
    }

    .conferenceLabel {
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding: 5px 15px;
      font-size: 10px;
      position: absolute;
      border-radius: 30px;
      right: 10px;
      top: 10px;
    }

    footer {
      opacity: 0.75;
      margin-top: 10px;
      transition: 0.4s;
      font-size: 12px;
    }

    .credit {
      .icon {
        width: 13px;
        height: 13px;
        margin-right: 5px;
        background-image: url("../img/clock-black.svg");
      }

      display: flex;
      align-items: center;
    }

    .inner {
      position: relative;
      padding: 10px;

      h2 {
        padding-top: 15px;
        margin-top: 0px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.page.completedCourses {
  position: relative;
  max-width: 1080px;
  padding-top: 0;
  transition: 0.3s;

  &.hasFetched {
    .loadingWrapper {
      position: fixed;
      bottom: 35px;
      left: 258px;
      top: auto;
      max-width: 1080px;
    }
  }

  .course-library {
    padding: 0;
    transition: 0.3s;
  }

  .mobile-list {
    margin-top: 10px;
    transition: margin-top 0.4s;

    &.isExpanded {
      margin-top: 200px;
    }
  }
}

.itemCount {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  justify-content: flex-end;
  margin-top: -42px;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 75px;
  font-weight: 700;

  @media (max-width: $screen-sm-max) {
    display: none;
  }

  &.mobile {
    left: 0;
  }

  span {
    display: block;
    margin: 0 5px;
    color: $blue;
    font-size: 28px;
  }
}

.icon {
  display: inline-block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &.image {
    border-radius: 8px;
  }

  &.doc {
    background-image: url("../img/file-doc.svg");
  }

  &.docx {
    background-image: url("../img/file-docx.svg");
  }

  &.pdf {
    background-image: url("../img/file-pdf.svg");
  }

  &.ppt {
    background-image: url("../img/file-ppt.svg");
  }

  &.pptx {
    background-image: url("../img/file-pptx.svg");
  }

  &.txt {
    background-image: url("../img/file-txt.svg");
  }

  &.xls {
    background-image: url("../img/file-xls.svg");
  }

  &.csv {
    background-image: url("../img/file-csv.svg");
  }

  &.xlsx {
    background-image: url("../img/file-xlsx.svg");
  }
}

.icon.quick-links {
  background-image: url("../img/quick-links.svg");
}

.icon.rewind-blue {
  background-image: url("../img/rewind-blue.svg");
}

.icon.mandated {
  background-image: url("../img/mandatory.svg");
}

.icon.paper-plane {
  background-image: url("../img/paper-plane-black.svg");
}

.icon.organization {
  background-image: url("../img/district.svg");
}

.icon.chrome-logo {
  background-image: url("../img/chrome.svg");
}

.icon.edge-logo {
  background-image: url("../img/edge.svg");
}

.icon.laptop {
  background-image: url("../img/laptop.svg");
}

.icon.nodes {
  background-image: url("../img/nodes.svg");
}

.icon.badge {
  background-image: url("../img/i-badge.svg");
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 0;
}

.icon.check-black {
  background-image: url("../img/i-check-black.svg");
}

.icon.check {
  background-image: url("../img/i-check.svg");
}

.icon.chair {
  background-image: url("../img/chair.svg");
}

.icon.check.white {
  background-image: url("../img/check-white.svg");
  background-color: rgba(0, 0, 0, 0) !important;
}

.icon.community {
  background-image: url("../img/community-share.svg");
}

.icon.incorrect {
  background-image: url("../img/i-incorrect.svg");
}

.icon.quiz {
  background-image: url("../img/choose.svg");
}

.icon.goal {
  background-image: url("../img/goal.svg");
}

.icon.goal.black {
  background-image: url("../img/goals-black.svg");
  background-color: rgba(0, 0, 0, 0) !important;
  background-repeat: no-repeat;
}

.icon.certificate {
  background-image: url("../img/certificate.svg");
}

.icon.star {
  background-image: url("../img/star-white.svg");
}

.icon.search {
  background-image: url("../img/search.svg");
}

.icon.microsoft {
  background-image: url("../img/microsoft.svg");
}

.icon.raised-hand {
  background-image: url("../img/raised-hand.svg");
}

.icon.recommended {
  background-image: url("../img/recommended.svg");
}

.icon.external-pending {
  background-image: url("../img/external-pending.svg");
}

.icon.external-declined {
  background-image: url("../img/external-declined.svg");
}

.icon.external-accepted {
  background-image: url("../img/external-accepted.svg");
}

.icon.google {
  background-image: url("../img/i-google.svg");

  &.official {
    background-image: url("../img/google-official.svg");
  }
}

.icon.back {
  background-image: url("../img/back.svg");
}

.icon.rocket {
  background-image: url("../img/rocket.svg");
}

.icon.quiz-icon {
  background-image: url("../img/answer.svg");
}

.icon.share {
  background-image: url("../img/share.svg");
}

.icon.print {
  background-image: url("../img/printer.svg");
}

.icon.twitter {
  background-image: url("../img/twitter.svg");
}

.icon.linkedIn {
  background-image: url("../img/linkedin.svg");
}

.icon.facebook {
  background-image: url("../img/facebook.svg");
}

.icon.source {
  background-image: url("../img/folder.svg");
}

.icon.export {
  background-image: url("../img/export.svg");
}

.icon.review,
.icon.reviewer {
  background-image: url("../img/review.svg");
}

.icon.clock {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-image: url("../img/clock.svg");
}

.icon.pin {
  background-image: url("../img/pin.svg");

  &.black {
    background-image: url("../img/pin-black.svg");
  }
}

.icon.learningPath {
  background-image: url("../img/learning-path.svg");

  &.white {
    background-image: url("../img/learning-path-white.svg");
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

#startAssessment {
  animation: fadeInUp 0.5s forwards;
}

.MuiCard-root {
  border-radius: 10px !important;
}

.bounceIn {
  animation: bounceIn 1s forwards;
}

.button {
  opacity: 1;
  transition: 0.3s;

  &.large {
    font-family: "Nunito" !important;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    min-width: 175px;
    padding: 8px 30px;
    border-radius: 100px;

    &.secondary:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &.certificate {
    color: black;
  }

  &.small {
    font-size: 12px;
    padding: 6px 15px;
    min-width: 0;
    font-weight: 700;
    color: $blue;
    border-radius: 100px !important;
    font-family: "Nunito" !important;

    &:focus {
      background: #f0f0f0;
    }

    .icon {
      width: 20px;
      height: 20px;
      display: block;
      background-repeat: no-repeat;
      margin-right: 10px;
    }
  }
}

.category-icon {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: white;
  background-image: url("../img/mobilemind.png");
  border: 2px solid white;

  &.google {
    background-image: url("../img/i-google.svg");
  }

  &.digital.learning {
    background-image: url("../img/digital-learning.svg");
  }

  &.meet {
    background-image: url("../img/i-meet.svg");
  }

  &.sites {
    background-image: url("../img/i-google-sites.svg");
  }

  &.school {
    background-image: url("../img/school.svg");
  }

  &.curriculum {
    background-image: url("../img/curriculum.svg");
  }

  &.other {
    background-image: url("../img/other.svg");
  }

  &.hq {
    background-image: url("../img/hq.svg");
  }

  &.samr {
    background-image: url("../img/samr.svg");
  }

  &.district {
    background-image: url("../img/district.svg");
  }

  &.seesaw {
    background-image: url("../img/seesaw.svg");
  }

  &.zoom {
    background-image: url("../img/zoom.svg");
  }

  &.policies {
    background-image: url("../img/policies.svg");
  }

  &.teachers {
    background-image: url("../img/teachers.svg");
  }

  &.general {
    background-image: url("../img/general.svg");
  }

  &.technology {
    background-image: url("../img/technology.svg");
  }

  &.docs {
    background-image: url("../img/i-docs.svg");
  }

  &.drive {
    background-image: url("../img/i-drive.svg");
  }

  &.earth {
    background-image: url("../img/i-earth.svg");
  }

  &.forms {
    background-image: url("../img/i-forms.svg");
  }

  &.slides {
    background-image: url("../img/i-slides.svg");
  }

  &.youtube {
    background-image: url("../img/i-youtube.svg");
  }

  &.classroom {
    background-image: url("../img/i-classroom.svg");
  }

  &.calendar {
    background-image: url("../img/i-calendar.svg");
  }

  &.chrome.extensions {
    background-image: url("../img/i-chrome-extensions.svg");
  }

  &.certification {
    background-image: url("../img/i-certification-info.svg");
  }

  &.sheets {
    background-image: url("../img/i-sheets.svg");
  }
}

.learn {
  max-width: 1180px;

  .learningPathInfo {
    background-color: white;
    margin: 20px 0;
    padding: 5px 15px;
    border-radius: 6px;
    max-width: 1060px;

    p {
      @media (max-width: $screen-sm-max) {
        line-height: 16px;
      }
    }

    h2 {
      .icon {
        background-image: url("../img/learning-path.svg");
        width: 20px;
        height: 20px;
        margin-right: 10px;
        position: relative;
        top: 3px;

        @media (max-width: $screen-sm-max) {
          width: 15px;
          height: 15px;
          margin-right: 5px;
          top: 1px;
        }
      }

      margin: 15px 0;

      @media (max-width: $screen-sm-max) {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    padding-top: 55px;

    .badge-library {
      @media (max-width: $screen-sm-max) {
        margin-top: 15px;
      }

      &.isExpanded {
        margin-top: 190px;
      }

      .badgeWrapper {
        padding: 5px 0px 5px 15px !important;
      }
    }
  }
}

.pageLoader {
  position: absolute;
}

.badge-library {
  padding-bottom: 150px;
  padding-top: 0px;
  transition: 0.6s !important;

  &.loading {
    padding-top: 20px;
  }

  &.earned {
    position: relative;
    max-width: 960px;

    .pageHeader {
      display: flex;
      justify-content: space-between;
    }

    .itemCount {
      position: relative;
      left: 0;
      margin: 0;
    }

    .exportLink {
      position: relative;
    }
  }

  @media (max-width: $screen-sm-max) {
    margin-top: 65px;

    .MuiGrid-container {
      max-width: 100%;
    }
  }

  &.isExpanded {
    margin-top: 150px;
  }
}

.badgeWrapper {
  min-width: 325px;
  opacity: 0;
  animation: fadeInUp 0.5s forwards;

  @media (max-width: $screen-md-max) {
    margin-bottom: -10px !important;
  }

  @media (max-width: $screen-sm-max) {
    min-width: 100%;
    margin: 0px !important;
  }

  .button.certificate {
    margin-left: 15px;
  }

  .caret {
    cursor: pointer;
    height: 20px;
    background-image: url("../img/i-caret.svg");
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    transform: scaleY(1);
    transition: 0.4s;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    opacity: 0;
  }
}

.learning-path-library {
  padding-top: 0;
  transition: 0.6s;

  &.backpack {
    .certificate-menu-wrapper {
      display: flex;
      justify-content: center;
    }

    .loadingWrapper {
      margin-top: 20px;
    }
  }

  &.loading {
    padding-top: 20px;
  }

  .widget.learningPath {
    width: 285px;
  }
}

.widget.learningPath {
  padding: 10px;
  margin: 8px;

  .status {
    font-weight: 700;
    color: #555;
    font-size: 12px;
    align-items: center;
    line-height: 16px;
  }

  .speedometer {
    position: relative;
    background-image: url("../img/speedometer.svg");
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
  }

  .needle {
    position: absolute;
    transform-origin: center;
    transition: 0.5s;
    transform: rotate(10deg);
    left: -8px;
    top: -8px;
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../img/speedometer-needle.svg");
    width: 37px;
    height: 37px;
    display: block;
    animation: stutter 1s infinite;

    &.onTrack {
      animation: onTrack 1s infinite;
    }
  }

  .count {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .goalBanner {
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 2;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    padding: 2px 10px;
    font-size: 10px;
    height: 24px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    &.complete {
      background-color: $green;
      color: black;
    }
  }

  .goalBadge {
    width: 15px;
    height: 15px;
    margin-right: 5px;

    .icon {
      display: block;
      width: 25px;
      height: 25px;
      background-image: url("../img/goal-white.svg");
    }
  }

  .progressBarGoal,
  .pathStatus {
    left: 10px !important;
    right: 10px !important;
  }

  &:not(.completed):hover {
    .bottomContent.inProgress:not(.goal) {
      .count {
        opacity: 1 !important;
        bottom: 38px !important;
        left: 0;
      }
    }

    .bottomContent.goal {
      .count {
        left: 12px;
        opacity: 1 !important;
        bottom: 38px !important;
      }

      .stats {
        opacity: 1;
      }
    }
  }

  .bottomContent {
    transition: 0.3s;
    padding-bottom: 50px;

    .stats {
      margin: 0px 0 20px;
      position: relative;
      transition: 0.3s;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -5px;
      }

      span {
        text-transform: uppercase;
        opacity: 0.6;
        font-weight: bold;
        font-size: 12px;
      }

      strong {
        color: $blue;
        font-size: 18px;
      }
    }

    &:not(.goal) {
      div.count {
        left: 0;
      }
    }

    &.inProgress:not(.goal) {
      .count {
        position: absolute;
        bottom: 25px;
        left: 0px;
        opacity: 0;
        transition: 0.3s;
      }
    }

    h2 {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 5%,
        rgba(0, 0, 0, 0.8) 95%
      );
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      height: 98px;
      text-align: center;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
      align-items: flex-end;
      justify-content: center;
      padding: 15px 15px 25px;
      display: flex;
      color: white;
      margin: 0;
    }

    &.goal {
      .count {
        position: absolute;
        bottom: 25px;
        left: 12px;
        opacity: 0;
        transition: 0.3s;
      }

      .progressBarGoal {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
      }
    }
  }

  .progressBarWrapper {
    height: 12px;
    border-radius: 12px;

    .progressBarInner {
      height: 12px;
      border-radius: 12px;
      background-color: $green;
    }
  }

  .icon.lock {
    margin: 0 auto;
    width: 30px;
    height: 30px;
    margin-bottom: -5px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .certificate-link {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    .button {
      width: 100%;
    }

    .MuiButton-label {
      font-size: 10px !important;
    }
  }
}

.progressBarWrapper {
  height: 12px;
  border-radius: 12px;

  .progressBar {
    animation: grow 1.5s forwards;

    .progressBarInner {
      height: 12px;
      border-radius: 12px;
      background-color: $green;
    }
  }
}

.certificate-menu {
  .MuiPopover-paper {
    position: relative;
    width: 215px;
    border-radius: 6px;
    overflow: visible;
    background-color: white;
    margin-left: -20px;
    margin-top: 40px;

    ul {
      padding: 0;
    }

    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 15px;
      font-family: "Nunito";

      &:focus {
        background-color: rgba(0, 0, 0, 0);
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        color: black;
      }

      &:last-child {
        border-bottom: none;
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      right: 50%;
      margin-right: -8px;
    }
  }

  &.certificate-menu-badge {
    .MuiPopover-paper {
      margin-left: -82px;
      margin-top: 35px;
    }
  }
}

.badge-library {
  .MuiGrid-item {
    margin: 5px 0;
  }
}

.badgeItem {
  position: relative;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  transition: 0.2s;
  overflow: hidden;
  height: 85px;
  transform: scale(1);

  img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    margin-right: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  }

  &.completed {
    background: #f0f0f0;
    box-shadow: none;

    .innerTop {
      transition: 0.3s;
      background-color: #f0f0f0;
    }

    .innerBottom {
      background-color: #f0f0f0;

      li a {
        border-radius: 6px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      height: auto !important;

      .innerTop {
        padding: 15px 15px;
        margin-bottom: -28px;
      }
    }

    .icon.check {
      opacity: 0;
      animation: fadeInUp 0.5s forwards;
      width: 25px;
      height: 25px;
      background-image: url("../img/i-check.svg");
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 100%;
      position: absolute;
      top: 12px;
      left: 63px;
    }
  }

  &:hover {
    .caret {
      opacity: 0.75;
    }
  }

  &.active {
    background-color: white;
    z-index: 10;

    @media (max-width: $screen-md-max) {
      transform: scale(1);
    }

    .caret {
      opacity: 0;
    }

    .innerTop {
      z-index: 10;
      position: relative;
    }
  }

  .innerTop {
    cursor: pointer;
    padding: 15px 15px;
    display: flex;
    align-items: center;

    h2 {
      font-size: 18px;
      margin: 0 0 5px 0;

      @media (max-width: $screen-sm-max) {
        font-size: 14px;
        margin-bottom: 0px;
      }
    }

    .details {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .statusText {
      margin: 0;
      font-size: 12px;

      @media (max-width: $screen-sm-max) {
        margin-top: 3px;
        font-size: 10px;
      }
    }

    .badgeImage {
      margin-right: 10px;
      width: 80px;
      height: 80px;

      @media (max-width: $screen-sm-max) {
        width: 60px;
        height: 60px;
      }
    }
  }

  .innerBottom {
    border-top: 1px solid #f0f0f0;
    padding: 15px;
    position: relative;
    z-index: 10;
    background-color: white;
    height: 100%;

    header {
      text-transform: uppercase;
      color: #888888;
      font-size: 12px;
    }

    ul {
      margin-top: 10px;
    }

    li {
      a,
      &.notReleased {
        transition: background-color 0.4s;
        padding: 0px 15px;
        margin: 0 -15px;
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 45px;
        color: black;
        cursor: pointer;

        .not-released-popover {
          position: absolute;
          opacity: 0;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          border-radius: 12px;
          text-align: right;
          right: 5px;
          font-size: 12px;
          padding: 5px 8px;
          display: inline-flex;
          transition: 0.4s;
        }
        &:hover {
          background-color: #f0f0f0;
          .not-released-popover {
            opacity: 1;
          }
        }
      }

      .category-icon {
        width: 20px;
        height: 20px;
        display: block;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        border-radius: 100%;
        margin-right: 10px;
      }

      .title {
        flex: 1;
        line-height: 16px;
        margin-right: 10px;
      }

      .icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;

        &.in_progress {
          background-image: url("../img/laptop.svg");
        }

        &.submitted,
        &.grading_in_progress {
          padding: 0;
          background-image: url("../img/inbox.svg");
        }

        &.completed {
          background-image: url("../img/i-check.svg");
        }
      }

      .icon-wrapper {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.quizResults {
  flex: 1;
  padding: 20px 0;

  .flexRow {
    margin-bottom: 10px;
    align-items: center;
    padding: 0 !important;
  }

  .score {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    font-size: 12px;
    color: #737373;

    strong {
      color: black;
      margin: 0 5px;
      font-size: 24px;
    }
  }
}

.quizResultsModal {
  .dialog {
    max-height: 75vh;
    padding-bottom: 48px;
    overflow: auto;
  }
}

.quizResultsModal .MuiDialog-paper {
  min-width: 667px;
  border-radius: 12px;

  h2 {
    font-size: 22px !important;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    padding: 20px 40px;
    background-color: white;
    z-index: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .title {
      flex: 1;
      text-align: left;
    }

    .timestamp {
      font-size: 12px;
      text-align: right;
      opacity: 0.5;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.87);
    }

    .icon {
      width: 25px;
      position: relative;
      top: -1px;
      height: 25px;
      margin-right: 10px;
    }
  }

  .quizResults {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    background: white;
    padding: 20px;

    .status {
      font-weight: bold;
      font-size: 18px;
      flex: 1;
      text-align: right;
    }
  }

  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 25px;
    margin-bottom: 50px;

    li {
      margin: 0px -40px;
      padding: 15px 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      position: relative;

      .icon {
        width: 25px;
        position: absolute;
        right: 40px;
        background-repeat: no-repeat;
        top: 0;
        bottom: 0;
      }

      header {
        font-weight: bold;
        margin-bottom: 10px;
      }

      span {
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0.7;
      }

      p {
        margin: 5px 35px 0 0;
      }
    }
  }
}

.progressBarWrapper {
  display: flex;
  margin-top: 2px;
  height: 12px;
  background-color: #f0f0f0;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;

  .progressItem {
    border-right: 2px solid white;

    &:last-child {
      border-right: 0px;
    }

    flex: 1;

    &.completed {
      opacity: 0;
      animation: fadeIn 0.2s forwards;
      background-color: $green;
    }

    &.fail {
      background-image: url("../img/progress-bg.svg");
      background-color: #c4c4c4;
      background-size: 550px;
      background-position: top -10px center;
    }

    &.incomplete {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.mobileFilters {
  position: fixed;
  z-index: 20;
  transition: left 0.4s;
  background-color: #f0f0f0;
  left: 50px;
  top: 55px;
  right: 0;

  .filters {
    margin-left: 0;
    margin-top: 30px;
    min-width: 130px;
    width: auto;
    right: 0;
    height: 70px;
    overflow: hidden;
    padding: 10px;

    .toggle {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      height: 15px;
      display: flex;
      justify-content: center;

      .icon {
        border-radius: 100%;
        width: 20px;
        height: 20px;
        display: block;
        margin: 0 auto;
        position: relative;
        top: 4px;
        background-color: $blue;
        background-position: center top 55%;
        background-image: url("../img/caret-white.svg");
        background-size: 6px;
        background-repeat: no-repeat;
        transform: scaleY(1) rotate(90deg);
      }
    }

    &.collapsed {
      opacity: 1;
      left: 0;
    }

    &.badgesEarned {
      .MuiFormControl-root {
        margin-top: 30px;
      }
    }

    &.expanded {
      .toggle {
        transform: scaleY(-1);
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 0px;

        .icon {
          top: -10px;
        }
      }

      &.explore {
        height: 305px;
      }

      &.badges {
        height: 240px;
      }

      &.coursesComplete {
        height: 300px;
      }

      &.badgesEarned {
        height: 155px;
      }
    }

    .MuiFormControlLabel-root {
      margin-top: 10px;
    }

    header {
      margin-bottom: -10px;
      height: 40px;
      min-width: 200px;
      padding: 0;

      button {
        margin-right: 0;
      }
    }
  }
}

.MuiFormControlLabel-label {
  font-family: "Nunito" !important;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  border-radius: 14px;
  overflow: hidden;

  > div {
    width: 100% !important;
  }

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.learning-path-library {
  padding-bottom: 165px !important;
  max-width: 1110px;
  position: relative;

  @media (max-width: $screen-sm-max) {
    .MuiGrid-root {
      margin: 0;
      width: 100%;
    }
  }

  .locked-message {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.65);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    z-index: 4;

    p {
      margin: 0;
      line-height: 14px;
      font-size: 12px;
    }
  }

  .itemCount {
    margin-top: -4px;
    position: relative;
    left: 10px;
    font-weight: 700;
    margin-bottom: 20px;
    justify-content: flex-start;

    span {
      margin-left: 0px;
    }
  }

  .widget {
    transition: 0.3s;
    position: relative;
    top: 0;
    margin: 10px;

    .widgetLabel {
      right: 0;
      top: 0;
    }

    .bottomContent {
      border-top: 5px solid white;

      &.unstarted {
        &:not(.goal) {
          padding-bottom: 0;
        }
      }
    }

    .status-icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      margin-right: 5px;
      position: absolute;
      top: 10px;
      right: 10px;

      &.check {
        background-image: url("../img/i-check.svg");
      }

      &.laptop {
        background-image: url("../img/laptop.svg");
      }
    }

    .timestamp {
      font-weight: 700;
      font-size: 12px;
      color: #555;
      right: 0;
      left: 0;
      top: 0;
      opacity: 1;
      text-align: center;
      display: flex;
      justify-content: center;
      position: relative;
    }

    &.inProgress {
      .bottomContent {
        border-top: 5px solid $blue;
      }
    }

    &.completed {
      .pathImage {
        &:after {
          content: "";
          display: block;
          position: absolute;
          background-color: $green;
          mix-blend-mode: color;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0.8;
          top: 0;
        }
      }

      .bottomContent {
        border-top: 5px solid $green;
      }

      &:hover {
        top: 0px;
        box-shadow: 0px 2px 8px 0 rgba(111, 89, 89, 0.34);
      }
    }

    &:hover {
      transform: scale(1.05);
      z-index: 12;
      top: -5px;
      box-shadow: 0px 2px 8px 0 rgba(111, 89, 89, 0.34);
    }
  }

  .capsule {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    justify-content: center;
    top: 111px;
    z-index: 2;
    padding: 0;
    background-color: transparent;

    div {
      font-size: 12px;
      border-radius: 25px;
      padding: 5px 15px;
      background-color: black;
      color: white;
      display: inline-flex;
      align-items: center;
    }
  }

  .topContent {
    height: 125px;
  }

  .bottomContent {
    padding: 0 20px;
    margin: 0 -20px;
    padding: 10px 20px 50px 20px;

    .already {
      background-color: #444;
      position: absolute;
      color: white;
      font-weight: normal;
      bottom: 0;
      display: block;
      text-align: center;
      padding: 10px 5px;
      left: 0;
      right: 0;
      font-size: 12px;
      line-height: 12px;
    }

    .categoryList {
      padding-bottom: 40px;
    }

    .pathStatus {
      font-size: 12px !important;
      text-transform: none !important;
      position: absolute !important;
      margin: 0 !important;
      bottom: 20px;
      left: 20px;
      right: 20px;

      .progressBarWrapper {
        background-color: #f0f0f0;
      }
    }
  }
}

.extensionModal {
  .button.large {
    min-width: 260px;
  }

  .flexRow {
    flex-direction: column;
  }

  .MuiPaper-root {
    .button {
      .icon {
        margin-right: 10px;
        width: 35px;
        height: 35px;
      }
    }

    p {
      max-width: 768px;
      margin: 0 auto 25px auto;
    }

    footer {
      margin-top: 0px !important;
      justify-content: center;
    }

    img {
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.14);
      border-radius: 8px;
      max-width: 100%;
    }
  }
}

@keyframes slamDown {
  0% {
    transform: scale(1);
    opacity: 1;
    margin-top: 260px;
  }

  25% {
    opacity: 1;
  }

  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0) rotate(0);
  }

  100% {
    transform: translateX(999px) rotate(720deg);
  }
}

@keyframes slideOutLock {
  0% {
    height: 50px;
    transform: translateX(0) rotate(0);
  }

  100% {
    height: 0;
    transform: translateX(999px) rotate(720deg);
  }
}

@keyframes fadeOutShrink {
  0% {
    opacity: 1;
    height: 50px;
  }

  100% {
    opacity: 0;
    height: 1px;
  }
}

.goalCompleteModal {
  position: relative;

  .MuiPaper-root {
    padding: 25px;
    min-width: 500px;
  }

  .goalWrapper {
    margin-top: -75px;
    margin-bottom: 10px;
    transform: scale(0.4);
  }

  .goal-ribbon {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    background-position: center;
    background-size: 320px;
    height: 80px;
    background-image: url("../img/goal-ribbon.svg");
    background-repeat: no-repeat;
    animation: rubberBand 1.5s forwards;
    margin: 0 auto;
  }

  .nextGoals {
    li {
      display: flex;
      width: 100%;
    }

    a {
      justify-content: center;
      display: flex;
      width: 100%;
      color: black;
      padding: 10px;
      border-radius: 50px;
      transition: 0.3s;
      align-items: center;

      .completeBy {
        flex: 1;
        font-size: 14px;
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .icon {
      width: 25px;
      height: 25px;
      margin: 0 10px 0 0;
      background-repeat: no-repeat;
    }
  }

  .goal-person {
    animation: shakeY 1.5s forwards;
    display: block;
    margin: 0 auto;
    width: 250px;
    height: 225px;
    background-position: center;
    background-image: url("../img/goal-person.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: -45px;
  }

  .modalContent {
    margin-top: 105px;
    text-align: center;

    p {
      font-size: 18px;
    }
  }
}

.pathCompleteModal {
  .dialog {
    padding-bottom: 55px;
    overflow-y: auto;
    margin: 0 auto;
    overflow-x: hidden;
    max-height: 650px;
    width: 600px;

    canvas {
      height: 650px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .widget.learningPath {
    width: 350px;
    min-height: 250px;
    text-align: left;

    h2 {
      margin-bottom: 60px !important;
      text-align: left;
    }

    .widgetLabel {
      background-color: black;
    }

    .topContent {
      height: 145px;
    }

    .bottomContent {
      border-top: 5px solid white;
      padding: 0 20px;
      margin: 0 -20px;
    }
  }

  h2 {
    z-index: 200;
    position: relative;
  }

  .message {
    margin: 0 auto 0px;
    text-align: center;
    font-size: 14px;
    line-height: 24px;

    .icon {
      display: block;
      margin: 0 auto 15px;
      width: 35px;
      height: 35px;
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .prompt {
    opacity: 0;
    margin-top: -50px;
    animation: fadeInUp 0.7s forwards;
    position: relative;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @keyframes unlockTop {
    from {
      top: 9px;
    }

    to {
      top: 2px;
    }
  }

  @keyframes reveal {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes explode {
    from {
      opacity: 1;
      transform: scale(0.5);
    }

    to {
      transform: scale(2);
      opacity: 0;
    }
  }

  .unlock {
    opacity: 0;
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    animation: fadeInUp 0.3s forwards;
    z-index: 12;
    pointer-events: none;
  }

  .unlockedPaths {
    text-align: left;
    margin-bottom: -15px;
    opacity: 0;
    animation: fadeInUp 0.3s forwards;
    animation-delay: 5.75s;

    p {
      line-height: 22px;
    }

    .icon {
      width: 35px;
      height: 35px;
      transition: margin-right 0.4s;
      margin-right: 10px;
    }

    li a {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      color: black;
      font-weight: bold;
      transition: 0.3s;
      padding: 10px 20px;
      border-radius: 160px;
      font-size: 16px;
      margin: 0 -15px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);

        .icon {
          margin-right: 15px;
        }
      }
    }
  }

  .lock {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 60px;
    margin-top: -55px;
    z-index: 10;
    animation: fadeOutDown 0.6s forwards;
    animation-delay: 5.5s;

    .top {
      animation: unlockTop 0.6s forwards;
      animation-delay: 4.5s;
      position: relative;
      top: 7px;
      margin-bottom: -3px;
      display: block;
      transform: rotateY(0deg);
      width: 18px;
      height: 25px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../img/lock-top.svg");
    }

    .bottom {
      display: block;
      width: 25px;
      height: 22px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 4px;
      overflow: hidden;
      background-image: url("../img/lock-bottom.svg");
    }
  }

  .flexRow {
    margin-top: 15px;
    justify-content: center;
    align-items: center;
  }

  .close {
    width: 25px;
    height: 25px;
    background-image: url("../img/x.svg");
    background-size: 12px;
    opacity: 0.3;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    right: 0px;
    top: 15px;
    transition: 0.3s;
    cursor: pointer;
  }

  .deck {
    width: 210px;
    height: 50px;
    margin: 25px auto;
    transform: scale(1);
    animation: fadeOutShrink 1s forwards;

    .cardWrapper {
      display: flex;
      position: absolute;
      pointer-events: none;
      animation: slamDown 1s forwards;
      opacity: 0;

      .icon.check {
        width: 50px;
        height: 50px;
        background-image: url("../img/i-check.svg");
      }
    }
  }

  .category-icon {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    transition: 0.4s;
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 100%;
  }
}

.exportLink {
  position: absolute;
  right: 20px;
  top: 5px;

  .icon {
    position: relative;
    top: -1px;
  }
}

.progressBarGoal {
  border-radius: 12px;
  height: 12px;
  background-color: #f0f0f0;
  overflow: hidden;
  position: relative;

  .goalProgress {
    position: absolute;
    height: 12px;
    border-radius: 0 12px 12px 0;
    background-color: $green;
    z-index: 2;
  }

  .track {
    position: absolute;
    height: 12px;
    background-color: #84d2e9;
    border-radius: 0 20px 20px 0;
    background-image: url("../img/progress-bg.svg");
    background-size: 340px 45px;
    background-position: -5px -15px;
  }
}

@keyframes stutter {
  0% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(40deg);
  }

  100% {
    transform: rotate(12deg);
  }
}

@keyframes onTrack {
  0% {
    transform: rotate(247deg);
  }

  50% {
    transform: rotate(260deg);
  }

  100% {
    transform: rotate(247deg);
  }
}

.reviewerImage,
.userInitial {
  width: 36px;
  height: 36px;
  display: block;
  background-size: contain;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  flex-shrink: 0;

  &.leaderboard {
    width: 52px;
    height: 52px;
    margin-right: 10px;

    span {
      font-size: 22px;
    }
  }
}

.userInitial {
  background-color: #4093a7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;

  span {
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
}

// The main loading wrapper thing
.loadingWrapper {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;

  .panel {
    background-color: white;
    border-radius: 6px;
  }

  &.fullPage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    strong {
      padding: 0;
    }
  }
}

.MuiInput-underline:after {
  border-bottom: 2px solid #888 !important;
}

.MuiInputLabel-formControl {
  color: #888 !important;
}

.poweredBy {
  font-weight: bold;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  position: fixed;
  bottom: 15px;
  left: 10px;
  z-index: 25;
  font-size: 12px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 30px;

  .logo {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../img/logo-circle.png");
    margin-right: 5px;
  }
}

.MuiPickersCalendar-week {
  .MuiPickersDay-daySelected,
  .MuiPickersDay-daySelected:hover {
    background-color: black;
  }

  .MuiPickersDay-current {
    color: black;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.MuiCalendarPicker-root div,
[role="gridcell"] {
  font-family: "Nunito", sans-serif !important;
}

.dateTimeSelect {
  align-items: flex-end;

  .MuiFormControlLabel-root {
    position: relative;
    top: -11px;
  }

  &:last-child {
    margin-top: 10px;
  }

  .MuiFormControl-root {
    margin-right: 20px !important;

    &.time {
      .MuiIconButton-root {
        display: none;
      }
    }
  }

  &.registrationWindow {
    flex-wrap: wrap;
    margin-top: 10px;

    > div {
      margin-bottom: 10px;

      &:first-child {
        margin-right: 20px;
      }

      .MuiFormControl-root {
        margin: 0;

        &:last-child {
          margin-top: -10px;

          .MuiSvgIcon-root {
            display: none;
          }
        }
      }
    }
  }
}

.capsule {
  background-color: black;
  display: inline-block;
  color: white;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px;
  font-size: 14px;

  .icon.clock {
    margin-right: 5px;
    width: 18px;
    height: 18px;
    background-color: transparent !important;
    background-image: url("../img/clock-white.svg");
  }
}

.mobileAppSuggestion {
  .MuiPaper-root {
    border-radius: 14px;
    animation: bounceIn 1s forwards !important;

    .button .icon {
      width: 30px;
      height: 30px;
    }

    h2 {
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      max-width: 300px;
      margin: 5px auto;
    }

    .icon.responsive {
      width: 35px;
      height: 35px;
      background-image: url("../img/responsive.svg");
      margin-right: 10px;
    }

    .button.large {
      font-size: 14px;
      background-color: $light-grey;
      margin-bottom: 25px;
      margin-top: 10px;
    }
  }
}

.icon.app-store {
  background-image: url("../img/app-store.svg");
}

.icon.play-store {
  background-image: url("../img/google-play.svg");
}

.button.large {
  color: black;
}

.page-hubspot {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -220px;

  .buddy-message p {
    text-align: center;
  }

  footer {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
}
