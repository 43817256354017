@import "variables";

.collapse {
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  left: 238px;
  z-index: 200;
  border: 0px;
  border-radius: 100%;
  background-color: $blue;
  padding: 0px;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.24);
  transition: 0.4s;
  top: 73px;
  display: none;
  pointer-events: none;

  &[data-show="true"],
  &[data-collapsed="true"] {
    opacity: 1;
    pointer-events: all;
  }

  .icon {
    width: 25px;
    height: 25px;
    display: block;
    background-image: url("../img/caret-white.svg");
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    transition: 0.3s;
  }

  &[data-collapsed="true"] {
    left: 48px;

    .icon {
      transform: rotate(0deg);
    }

    &.peek {
      left: 238px;
      opacity: 0;
    }
  }

  @media (max-width: $screen-md-max) {
    opacity: 1;
    pointer-events: all;
    top: 60px;
  }

  @media (max-width: $screen-sm-max) {
    left: 12px !important;
    top: 65px;

    &[data-collapsed="false"] {
      left: 210px !important;
    }
  }
}

#sidebar {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 70px 20px 20px;
  z-index: 7 !important;
  transition: 0.4s;
  overflow-x: hidden;
  margin-right: 120px;

  button {
    color: black;
  }

  .related-courses {
    li {
      a {
        width: 100%;
        display: flex;
        transition: background-color 0.3s;
        align-items: center;
        padding: 6px 5px;
        background-color: transparent;
        transition: 0.4s;
        border-radius: 8px;

        &:hover {
          background-color: $light-grey;
        }

        .category-icon-wrapper,
        .category-icon {
          width: 20px !important;
          height: 20px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px;
          flex-shrink: 0;
        }

        .title {
          line-height: 16px;
          font-size: 14px;
          text-align: left;
          flex: 1;
          margin-left: 5px;
          color: black;
        }
      }
    }
  }

  button {
    font-family: "Nunito" !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: $screen-sm-max) {
    padding: 105px 20px 20px;
  }

  ul {
    margin: 0;
  }

  .itemLabel {
    position: absolute;
    left: 45px;
    width: 300px;
  }

  &[data-peek="true"] {
    .filters {
      left: 0px !important;
      opacity: 1 !important;
    }
  }

  &[data-collapsed="true"] {
    width: 60px;

    @media (max-width: $screen-sm-max) {
      width: 50px;
    }

    .disclaimer {
      opacity: 0;
    }

    .icon.badge {
      transition: 0.3s;
      margin-top: -195px !important;
    }

    .goal {
      opacity: 0;
    }

    .course-time {
      margin-left: -15px !important;
      padding: 0 4px;
      width: 60px;
      display: flex;
      justify-content: center;
      font-size: 12px !important;
      margin-top: -40px;

      .icon {
        display: none;
      }
    }

    li button {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
      }

      .title {
        opacity: 0;
      }
    }

    h3 {
      height: 0px !important;
      padding: 0px 20px !important;
      color: white !important;
    }

    .entity-meta {
      margin-right: -5px;
      margin-left: -5px;

      .message {
        display: none;
      }

      .pathStatus {
        width: 60px;
        margin-left: -15px;
        margin-top: -40px;
        margin-bottom: 60px;
        text-align: center;
        padding: 0 5px;

        @media (max-width: $screen-sm-max) {
          padding-right: 15px;
        }
      }

      .courseCount {
        display: none;
      }

      .backButton {
        border-bottom: 1px solid $green;
        margin-top: 0px !important;

        @media (max-width: $screen-sm-max) {
          margin-top: -10px;
          padding: 20px 15px;
        }
      }

      header {
        opacity: 0;
      }

      .MuiButton-label {
        margin-left: 5px;
      }

      .course-level {
        justify-content: center;
        width: 30px;
        margin-left: 1px;
        margin-top: -80px;

        &.started {
          margin-top: -70px;
        }

        @media (max-width: $screen-sm-max) {
          margin-left: -3px;
          margin-top: -80px;
        }
      }

      ul {
        margin-top: -30px;

        &.pathList {
          margin-top: -50px;
        }

        li,
        a {
          display: none;

          &:first-child {
            display: block;
          }
        }

        .icon {
          width: 25px;
          height: 25px;
          margin: 0;
          margin-left: 2px;

          @media (max-width: $screen-sm-max) {
            margin-left: -2px;
          }
        }
      }

      .buttonText {
        opacity: 0;
      }

      .itemLabel {
        opacity: 0;
        position: absolute;
        left: 45px;
      }

      .category-icon-wrapper {
        position: absolute;
        opacity: 0;
      }

      .star {
        width: 10px;
        height: 10px;

        @media (max-width: $screen-sm-max) {
          margin-bottom: -10px;
        }
      }
    }
  }

  .icon.timer {
    background-image: url("../img/goals-black.svg");
    display: block;
    margin: 0;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -14px;
    border-radius: 7px;
    background-color: $green;
    bottom: 0;
    background-position: center;
    background-size: 15px;
    width: 25px !important;
    height: 25px !important;
  }

  &:hover {
    .collapse {
      opacity: 1;
      pointer-events: all;
    }
  }

  li button {
    height: 73px;
    width: 250px;
    justify-content: left;
    margin: 0 -20px;
    border-radius: 0;
    padding: 15px 20px;

    @media (max-width: $screen-sm-max) {
      padding: 5px 15px;
      height: 50px;
    }

    &.active {
      span.count,
      .icon.timer {
        background-color: rgba(0, 0, 0, 0);
        font-size: 14px;
      }
    }

    span.count {
      background-color: #f0f0f0;
      color: black;
      border-radius: 7px;
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      padding-top: 2px;
      font-weight: 700;
      transition: 0.15s;
    }

    // &:focus,
    // &.active {
    //   background-color: rgba(0, 0, 0, .1) !important;
    // }

    &:active {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .MuiButton-label {
      font-size: 18px;
      text-transform: none;
      font-weight: normal;
    }

    .title {
      opacity: 1;
      flex: 1;
      text-align: left;
      transition: 0.4s;
      text-transform: none;
      font-size: 18px;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 20px;
      background-repeat: no-repeat;
      margin-top: -2px;

      &#dashboard {
        background-image: url("../img/dashboard.svg");
      }

      &#goals {
        background-image: url("../img/goals-black.svg");
        width: 25px;
        margin-right: 15px;
        height: 25px;
      }

      &#learningPaths,
      &#learningPathsCompleted {
        background-image: url("../img/learning-path-black.svg");
      }

      &#badges,
      &#badgesEarned {
        background-image: url("../img/i-badge-black.svg");
      }

      &#eventsAttended {
        background-image: url("../img/i-calendar-black.svg");
      }

      &#explore {
        background-image: url("../img/search.svg");
      }

      &#saved_for_later {
        background-image: url("../img/pin-black.svg");
      }

      &#in_progress {
        background-image: url("../img/laptop-black.svg");
      }

      &#feedback {
        background-image: url("../img/feedback-black.svg");
      }

      &#submitted {
        background-image: url("../img/inbox-black.svg");
      }

      &#completed {
        background-image: url("../img/i-check-black.svg");
      }
    }
  }

  .entity-meta.lp {
    width: 280px;

    button {
      text-align: left !important;
      justify-content: flex-start !important;
      text-transform: none;
      font-size: 18px;
    }

    .message .icon.check {
      width: 35px;
      height: 35px;
      border-radius: 35px;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
        0 3px 1px -2px rgb(0 0 0 / 20%);
      position: absolute;
      top: 130px;
      right: 10px;
    }

    .goal {
      border-bottom: 1px solid darken($light-grey, 8%);
      padding: 20px 15px;

      .flexRow strong {
        line-height: 18px;
      }

      .icon {
        border: none;
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        background-repeat: no-repeat;
      }

      p {
        font-size: 12px;
        align-items: center;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: bold;
        color: #999;

        strong {
          display: block;
          opacity: 1;
          margin-top: 10px;
          text-transform: none;
          color: $blue;
          font-size: 18px;
        }
      }
    }

    .created-by {
      font-size: 12px;
    }

    .icon.community {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-position: center;
      background-repeat: no-repeat;
    }

    .learningPathCategories {
      display: flex;
      flex-wrap: wrap;
      width: 250px;

      .categoryHover {
        margin-right: 5px;
        cursor: pointer;

        .category-icon-wrapper {
          box-shadow: 0 0px 2px 1px rgb(0 0 0 / 17%);
        }

        .category-icon {
          margin-right: 0 !important;
        }

        &:hover {
          .popup {
            margin-left: 25px;
            opacity: 1;
          }
        }

        .popup {
          background-color: white;
          border-radius: 5px;
          padding: 5px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
          position: absolute;
          margin-left: 10px;
          opacity: 0;
          transition: 0.4s;
          pointer-events: none;
          z-index: 3;
          font-weight: bold;
        }
      }

      .category-icon-wrapper {
        width: 20px;
        margin: 0;
      }
    }

    .message {
      margin-top: 5px;
    }

    .pathList {
      margin: -5px 0 10px;
    }

    .pathStatus {
      border-bottom: 1px solid darken($light-grey, 8%);
      font-size: 12px;
      padding: 20px 10px;
      transition: 0.3s;
      text-align: center;

      .buddy-container {
        margin-top: 40px;

        p {
          margin: 0 !important;
          font-size: 13px;
        }
      }
    }

    .courseCount {
      height: 32px;
      display: flex;
      align-items: center;
      margin: 25px 15px 0;

      span {
        font-weight: 700;
        color: $blue;
        font-size: 32px;
        position: relative;
        top: 1px;
        margin-right: 5px;
        transition: 0.3s;
      }
    }

    .backButton {
      width: 280px;
      padding: 20px;
      margin-top: -20px;
      border-radius: 0px;
      transition: 0.4s;
      background-color: transparent !important;

      &:hover {
        background-color: lighten($light-grey, 2%) !important;
      }

      @media (max-width: $screen-sm-max) {
        margin-top: -50px;
      }

      &:focus {
        background-color: white;
      }

      .MuiButton-label {
        justify-content: flex-start;
        font-size: 18px;
        color: $blue;
        text-transform: capitalize;
        font-weight: normal;
      }

      .icon {
        margin-right: 10px;
        width: 20px;
        height: 17px;
      }
    }

    header {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
      opacity: 0.75;
    }

    .category-icon-wrapper {
      transition: 0.4s;
      width: 250px;
      display: flex;
      text-transform: capitalize;
      font-size: 14px;
      align-items: center;
      margin-bottom: 10px;
    }

    .learningPathCover {
      position: relative;

      .deadline {
        background-color: white;
        padding: 3px 10px;
        font-weight: bold;
        border-radius: 20px;
        font-size: 10px;
        position: absolute;
        right: 10px;
        top: 15px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .icon.goal {
          border: none;
          margin: 0 5px 0 0;
          padding: 0;
          width: 15px;
          height: 15px;
          background-image: url("../img/goal.svg");
        }
      }

      .lp-image {
        height: 150px;
        width: 100%;
        background-size: cover;
        background-position: center;
      }

      .capsule-container {
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        margin-top: -13px;
      }

      .capsule {
        background-color: black;
        border-radius: 60px;
        display: inline-block;
        color: white;
        font-size: 12px;
        padding: 4px 15px;
        text-align: center;
      }
    }

    .category-icon {
      position: relative;
      top: -1px;
      width: 20px;
      display: block;
      height: 20px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
      border-radius: 100%;
      margin-right: 5px;

      &.large {
        transition: 0.4s;
        margin-right: 0px;
        width: 60px;
        height: 60px;
        position: absolute;
        top: 86px;
        right: 12px;
        border: 6px solid white;
        box-shadow: none;
        box-sizing: content-box;

        @media (max-width: $screen-sm-max) {
          top: 141px;
          opacity: 0;
        }
      }
    }

    .goalItem {
      margin-bottom: 10px;
    }

    .goals {
      .icon {
        margin-right: 10px;
        position: relative;
        top: -2px;
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
      }

      strong {
        flex: 1;
        display: block;
        line-height: 16px;
      }
    }

    .course-time {
      color: $blue;
      font-size: 18px;
      font-weight: 700;
      margin-left: 0px;

      .icon {
        margin-right: 5px;
        position: relative;
        top: -2px;
      }

      .flexRow {
        align-items: center;
      }
    }

    .course-level {
      margin-top: 0;
      transition: 0.4s;
      display: flex;
    }

    .star {
      transition: 0.4s;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 3px;
      background-image: url("../img/star.svg");
    }

    .badgeLabel {
      opacity: 1;
      transition: 0.4s;
    }

    ul {
      margin-top: 0;
      transition: margin-top 0.4s;

      a {
        color: black;

        li {
          &:hover {
            background-color: #f0f0f0;

            .itemLabel {
              margin-left: 5px !important;
            }
          }
        }
      }

      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        transition: 0.3s;

        &.learning-path-item {
          line-height: 14px;
          padding: 10px 20px;
          margin: 0 -20px;

          .itemLabel {
            transition: 0.3s;
            width: 200px;
          }
        }
      }
    }

    .icon.learningPath {
      background-image: url("../img/learning-path.svg");
      background-size: 90%;
      background-repeat: no-repeat;
    }

    .icon.badge,
    .icon.learningPath {
      flex-shrink: 0;
      display: block;
      float: none;
      height: 20px;
      width: 20px;
      margin: 0 5px 0 0;
    }
  }
}

.filters {
  width: 220px;
  margin-left: -10px;
  margin-top: 20px;
  position: relative;
  opacity: 1;
  left: 0;
  transition: 0.6s;
  padding-bottom: 30px;

  &.collapsed {
    left: -100px;
    opacity: 0;
  }

  .icon {
    position: absolute;
    width: 12px;
    height: 12px;
    margin-top: 9px;

    &.learningPath {
      background-image: url("../img/learning-path-black.svg");
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 33px;

    strong {
      text-transform: uppercase;
      font-weight: 700;
      flex: 1;
      font-size: 12px;
    }

    .backButton {
      margin-right: -6px;
      color: $blue;
      background-color: white;

      &:focus {
        background-color: white;
      }
    }
  }

  input {
    font-family: "Nunito" !important;
    margin: 0;
    padding-left: 20px;
  }

  .category {
    .icon {
      background-image: url("../img/i-categories.svg");
    }
  }

  .learningPath {
    .icon {
      background-image: url("../img/learning-path-black.svg");
    }
  }

  .badgeSelect {
    .icon {
      margin-left: 0px !important;
    }
  }

  .MuiTextField-root .MuiInput-underline:before {
    display: none;
  }

  .inputSelect,
  .MuiFormControlLabel-root {
    margin-top: 20px;
    font-family: "Nunito";

    .MuiFormLabel-root {
      transform: translate(20px, 22px);
      font-family: "Nunito";

      &.MuiInputLabel-shrink {
        transform: translate(0, 2px) scale(0.75);
      }
    }

    .MuiSelect-root {
      font-family: "Nunito";
      padding-left: 20px;
    }

    .icon {
      margin-top: 23px;
    }
  }

  .inputSearch .MuiFormLabel-root {
    font-family: "Nunito";

    &.MuiInputLabel-shrink {
      font-family: "Nunito";
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }
}
.filters.explore .course-author-field {
  .MuiAutocomplete-root {
    margin-top: 0px;

    label[data-shrink="false"] {
      transform: translate(0px, 22px);
    }
    .MuiAutocomplete-endAdornment {
      display: none;
    }
    .MuiInputBase-root.MuiInput-root {
      padding-right: 0;
      .MuiInput-input {
        height: auto;
      }
    }
  }
}

.inputSelect.courseAuthor {
  position: relative;
}

.inputSelect.difficulty,
.inputSelect.learningPath,
.inputSelect.type,
.inputSelect.status,
.inputSelect.source {
  margin-bottom: -20px;
  [role="combobox"] {
    padding-top: 22px !important;
  }

  .MuiInputBase-root {
    padding-left: 20px;
  }

  .icon.star {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../img/star-black.svg");
  }
}

.filters.learningPaths {
  .inputSelect.status {
    .icon {
      top: 2px;
      height: 10px;
      width: 10px;
    }
  }

  .MuiSelect-root {
    font-family: "Nunito";

    .icons {
      display: none;
    }
  }
}

[aria-labelledby="label-level-select"] {
  .icons {
    position: absolute;
    right: 10px;
  }

  .icon.star {
    background-image: url("../img/star.svg");
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    margin-left: 3px;
  }
}

#sidebar.sectioned {
  padding: 55px 0 0 0;

  h3 {
    margin: 25px 0 15px;
    font-weight: bold;
    font-size: 18px;
    padding: 0 20px;
  }

  .icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    &.checked:after {
      content: "";
      width: 40px;
      height: 20px;
      display: block;
      background-image: url("../img/switch-on.svg");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 5px;
      bottom: 17px;
    }
  }

  .icon.google,
  .icon.outlook {
    &:after {
      content: "";
      width: 40px;
      height: 20px;
      display: block;
      background-image: url("../img/switch-off.svg");
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 5px;
      bottom: 17px;
    }
    &.checked:after {
      background-image: url("../img/switch-on.svg");
    }
  }

  .icon.calendar {
    background-image: url("../img/calendar-sidebar.svg");
  }

  .icon.google {
    background-image: url("../img/google-sidebar.svg");
  }

  .icon.outlook {
    background-image: url("../img/outlook.svg");
  }

  ul {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 10px;

    li {
      transition: 0.4s;

      button {
        .MuiButton-label {
          font-weight: bold;
          font-size: 14px;
          text-align: left;
        }

        height: 55px;
        display: flex;
        align-items: center;
        width: 250px;
        margin: 0;
      }
    }
  }
}
