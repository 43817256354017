@import "../../styles/variables.scss";

.page.mandated-course-layout {
  margin-left: -245px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:after {
    display: block;
    content: "";
    background: $light-grey;

    position: fixed;
    left: 0;
    margin-left: -245px;
    right: 0;
    z-index: 0;
    top: -110px;
    height: 300px;
  }
  .mandated-training-history {
    li {
      padding: 20px 15px;
      border-bottom: 1px solid $light-grey;
      display: flex;
      align-items: center;
      .icon.check {
        width: 25px;
        height: 25px;
      }
      strong {
        color: $green;
        font-size: 15px;
      }
    }
  }
  .backButton {
    button {
      position: absolute;
      top: 10px;
      left: 20px;
      z-index: 2;
      background-color: transparent;
      border-radius: 30px;
      width: 35px;
      height: 35px;
      min-width: 35px;
      background-image: url("../../img/back-white.svg");
      background-position: center;
      background-repeat: no-repeat;

      padding: 0 !important;
      &:hover {
        background-color: rgba(255, 255, 255, 0.45);
      }
    }
  }
  .inner {
    display: flex;
    width: 860px;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
    overflow: hidden;
  }
  .status-panel {
    padding: 35px;
    background-color: $blue;
    border-radius: 12px 12px 0 0;
    width: 100%;
    height: 200px;
    position: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 860px;
    top: 75px;
    z-index: 4;
    .loadingWrapper {
      left: 0;
      right: 0;
      position: absolute;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -20px;
    }
    &:before {
      display: block;
      content: "";
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 8%,
        rgba(255, 255, 255, 0) 100%
      );
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      top: 0;
      bottom: 0;
    }

    .status-complete.icon {
      width: 35px;
      height: 35px;
      position: absolute;
      z-index: 3;
      right: 15px;
      top: 15px;
    }

    .deadline {
      background-color: white;
      font-weight: bold;
      text-transform: uppercase;
      padding: 4px 12px;
      font-size: 10px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 2;
    }
    .learning-buddy-success {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: -45px;
      animation: fadeInUp 1s forwards;
      .buddy-container {
        max-width: 550px;
      }
      .buddy-image {
        margin-top: -50px;
      }
      .buddy-message {
        padding: 20px 20px 10px 20px;
        button {
          margin-top: 5px;
          color: black !important;
          margin-bottom: -2.5px;
          &:hover,
          &:focus {
            background-color: rgba(255, 255, 255, 0.5);
          }
          svg path {
            fill: black !important;
          }
        }
      }
    }
    .progress-circle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -55px;
      display: flex;
      justify-content: center;
      z-index: 2;
      &.start-now {
        bottom: -56px;
        button {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
          border: 5px solid white;
          width: 112px;
          height: 112px;
          border-radius: 112px;
          font-weight: bold;
          text-transform: capitalize;
          font-size: 24px;
        }
      }
    }
    [data-test-id="CircularProgressbarWithChildren"] {
      width: 110px;
      height: 110px;
      background-color: black;
      padding: 12px;
      border-radius: 120px;
    }

    .progress-text {
      color: white;
      font-weight: bold;
      font-size: 22px;
      position: relative;
      left: -2.5px;
      &:after {
        content: "%";
        display: block;
        font-size: 12px;
        position: absolute;
        right: -12px;
        top: 8px;
      }
    }
    .CircularProgressbar-path {
      stroke: $green;
    }
    .CircularProgressbar-trail {
      stroke-width: 6px;
      stroke: white;
    }
    .text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 70px;
    }
    .date-range {
      color: white;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
    }
    h2 {
      text-align: center;
      text-transform: capitalize;
      margin: 0;
      color: white;
      font-weight: bold;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
    }
  }

  .courses-panel {
    border-radius: 0 0 12px 12px;
    background-color: white;
    padding-top: 205px;
    &.disabled {
      .mandated-filters,
      .mandated-course-list {
        opacity: 0.2;
        pointer-events: none;
      }
    }
    .mandated-filters {
      display: flex;
      align-items: center;
      padding: 20px 15px;

      .status-select {
        .MuiInputBase-root {
          min-width: 100px;
          height: 43px;
        }
        label {
          top: 1px;
          background-color: white;
          padding: 0 10px;
          left: -7px;
        }
      }
      label[data-shrink="false"] {
        top: -4px;
      }
      input {
        border-bottom: none;
        margin-bottom: 0;
        padding: 0 10px;
      }
      .MuiOutlinedInput-notchedOutline {
        display: block;
      }
    }
    ul {
      margin: 0 0 25px 0;
      padding: 0;
      li {
        a {
          padding: 20px 15px;
          border-bottom: 1px solid $light-grey;
          display: flex;
          align-items: center;
          color: black;
          background-color: transparent;
          transition: 0.3s;
          &:hover {
            padding: 20px 15px 20px 20px;
            background-color: $light-grey;
          }
        }
        .title {
          display: block;
          margin-bottom: -7px;
        }
        .category-label {
          opacity: 0.6;
          text-transform: uppercase;
          font-size: 10px;
        }
        .status-icon {
          width: 30px;
          height: 30px;
          margin-left: 10px;
          &.overdue {
            background-image: url("../../img/clock-overdue.svg");
          }
        }
        .category-icon {
          width: 30px;
          height: 30px;
          border-radius: 25px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
          display: block;
          border: 3px solid white;
          background-color: $green;
          margin-right: 8px;
          position: relative;
          top: 1px;
        }
        &:first-child {
          border-top: 1px solid $light-grey;
        }
      }
    }
  }
}
