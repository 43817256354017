@import "variables";

#orgSelectPopover {
  .MuiPaper-root {
    border-radius: 0 0 6px 6px;
    margin-top: 1px;
    margin-left: -50px;
    box-shadow: none;
  }

  z-index: 43 !important;

  ul {
    margin: 0;
    padding-top: 0;

    li {
      font-family: "Nunito", sans-serif !important;
    }
  }
}

.hqLink {
  background-color: #575757;
  border-radius: 100px;
  transition: 0.3s;
  margin-right: 20px;
  color: white;

  .swap.icon {
    background-image: url("../img/swap.svg");
    width: 15px;
    height: 15px;
    margin-right: 5px;
    position: relative;
    top: -1px;
  }

  @media (max-width: $screen-sm-max) {
    display: none;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    height: 32px;
    padding: 0 15px;
    line-height: 22px;
  }

  &:hover {
    background-color: lighten(#575757, 8%);
  }
}

.notification-peek {
  animation: fadeInUp 0.4s;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.1);
  width: 450px;
  position: fixed;
  padding: 20px;
  z-index: 10;
  right: 20px;
  bottom: 20px;

  @media (max-width: 500px) {
    left: 10px;
    right: 10px;
    width: auto;
  }

  button.close {
    position: absolute;
    right: 5px;
    top: 5px;
    background-size: 10px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5px;
    min-width: 0;
    background-image: url("../img/x.svg");
    text-indent: -9999px;

    &:focus {
      background-color: white;
    }
  }

  .MuiSlider-root {
    margin-top: 25px;
    margin-bottom: -5px;
  }

  .MuiSlider-thumb {
    .MuiSlider-valueLabel {
      transform: scale(1) translateY(6px) !important;
    }

    span {
      width: 20px;
      height: 20px;

      > span > span {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 700;
        font-size: 12px;
        padding-top: 1px;
      }
    }
  }

  .MuiTextField-root {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  p {
    margin: 10px 0 0;
    font-size: 12px;
    line-height: 16px;
  }

  footer {
    margin-bottom: -10px;
    display: flex;
    justify-content: flex-end;
  }

  .MuiFormGroup-root {
    flex-direction: row;
    margin: 10px 0;

    .MuiButtonBase-root {
      display: none;
    }

    .MuiFormControlLabel-root {
      background-color: white;
      transition: 0.2s;

      &.active {
        background-color: $blue;
        color: white;
      }
    }

    label {
      border-top: 1px solid $blue;
      border-bottom: 1px solid $blue;
      border-left: 1px solid $blue;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 35px;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-right: 1px solid $blue;
        border-radius: 0 5px 5px 0;
      }

      .MuiTypography-root {
        font-weight: 300;
        font-size: 12px;
      }
    }

    .MuiIconButton-root {
      padding: 0;
      border-radius: 0%;
    }
  }
}

.notification-popup {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 60px;
  width: 350px;
  overflow: hidden;
  padding-bottom: 20px;
  padding-left: 20px;

  &.slideOut .inner {
    transition: 1.5s;
    opacity: 0;
  }

  .icon {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    margin-right: 10px;
    background-repeat: no-repeat;

    &.assessment_feedback {
      background-image: url("../img/feedback.svg");
    }

    &.assessment_complete {
      background-image: url("../img/i-check.svg");
    }

    &.quiz {
      background-image: url("../img/quiz-color.svg");
    }
  }

  p {
    margin: 0;
    line-height: 16px;
    font-size: 12px;
  }

  .inner {
    color: black;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    padding: 15px;
    opacity: 1;
    transition: 0.3s;
    animation: slideInDown forwards 0.5s;
    background-color: white;
    border-radius: 6px;
    border-left: 5px solid rgba(0, 0, 0, 0);

    &:hover {
      border-left: 5px solid $blue;
    }
  }
}

#notification-menu {
  .MuiPopover-paper {
    width: 350px;
    border-radius: 6px;
    overflow: visible;
    background-color: rgba(0, 0, 0, 0) !important;
    top: 40px !important;
    right: 8px;
    left: auto !important;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      right: 15px;
    }
  }

  .message-empty {
    background-color: white;
    padding: 15px;
    border-radius: 6px;
    font-size: 12px;
    text-align: center;
    font-weight: 700;
  }

  footer {
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -33px;
    background-color: white !important;
    border-radius: 0 0 6px 6px;
    border-top: 1px solid #f0f0f0;

    &:hover {
      background-color: white;
    }

    .button {
      text-transform: capitalize;
      width: 100%;
      border-radius: 0 !important;
    }
  }

  .MuiMenu-list {
    padding: 0;
  }

  .notifications-list {
    padding: 0;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    padding: 0px;
    margin: 0;
    max-height: 250px;
    overflow-y: auto;
    position: relative;
    margin-bottom: 33px;
    background-color: white;

    &.empty {
      margin-bottom: 0px;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      padding: 0px 10px 0 0;
      background-color: white;
      transition: 0.3s;
      min-height: 0px;
      cursor: pointer;
      position: relative;

      .notification-item {
        display: block;
        display: flex;
        align-items: center;
        color: black;
        padding: 10px 30px 10px 15px;
        transition: 0.3s;
        position: relative;
        right: 0;
        height: 69px;
        overflow: hidden;
        flex: 1;
        margin-right: -10px;
        border-bottom: 1px solid #f0f0f0;
        font-family: "Nunito";

        .icon.quiz {
          background-image: url("../img/choose.svg");
        }

        &.new {
          padding-left: 10px;
        }

        &.removed {
          right: 150px;
          padding: 0 !important;
          height: 0;
          border-bottom: none;

          .icon.remove {
            display: none !important;
          }
        }
      }

      .timestamp {
        color: #888;
        font-weight: 700;
        font-size: 10px;
        margin-top: 2px;
      }

      &:hover {
        background-color: #f0f0f0;
      }

      .content {
        white-space: pre-line;
        padding-top: 5px;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        margin-right: 5px;
        flex-direction: column;
      }

      .icon {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        background-repeat: no-repeat;

        &.assessment_complete {
          background-image: url("../img/i-check.svg");
        }

        &.assessment_feedback {
          background-image: url("../img/feedback.svg");
        }

        &.ulp_complete {
          background-image: url("../img/learning-path.svg");
        }

        &.user_goal_complete {
          background-image: url("../img/goal.svg");
        }

        &.user_badge_created {
          background-image: url("../img/i-badge.svg");
        }

        &.event_personnel {
          background-image: url("../img/calendar-color.svg");
        }

        &.check {
          background-image: url("../img/i-check.svg");
        }

        &.remove {
          background-image: url("../img/x.svg");
          transform: scale(0.8);
          transition: 0.3s;
          opacity: 0.3;
          display: block;
          position: absolute;
          right: 0px;
          width: 13px;
          height: 13px;
          background-size: contain;
          background-repeat: no-repeat;

          &:hover {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
}

.navbar {
  position: relative;
  z-index: 15;

  .icon.menu {
    background-repeat: no-repeat;
    display: block;
    width: 25px;
    height: 25px;
    background-image: url("../img/menu.svg");
    position: fixed;
    top: 12px;
    left: 15px;
  }

  .smallText {
    font-weight: 300;
    font-size: 10px;
  }

  .notifications {
    background-image: url("../img/bell.svg");
    width: 25px;
    height: 25px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transform: scale(1);
    transition: 0.3s;
    position: relative;

    &.swing {
      transform-origin: top center;
      animation: swing 1s;
    }

    &:hover {
      transform-origin: center;
      transform: scale(1.2);
    }

    .count {
      background-color: $green;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid black;
      color: black;
      font-size: 10px;
      padding-top: 1px;
      position: absolute;
      right: -3px;
      top: -2px;
      font-weight: 700;
      transition: 0.5s;
      transform: scale(1);
      opacity: 1;

      &.fade {
        transform: scale(0.5);
        opacity: 0;
      }
    }
  }

  .trialText {
    font-weight: 700;
    color: black;
    margin-top: -3px;
    display: inline-block;
  }

  &.locked {
    pointer-events: none;

    li {
      a {
        opacity: 0.25;
      }
    }
  }

  h1 {
    height: 50px;
    padding: 0 20px;
    font-size: 0px;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 30px 0 0;

    @media (max-width: $screen-md-min) {
      background-image: url("../img/logo-white-mobile.png");
      width: 25px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 10px;
      background-position: center;

      img {
        display: none;
      }
    }

    img {
      filter: saturate(0) contrast(0) brightness(100);
      max-width: 200px;
      max-height: 30px;
    }
  }

  .profile {
    display: flex;
    align-items: center;
  }

  .userInfo {
    text-align: right;
    text-transform: capitalize;
    line-height: 16px;
    font-family: "Nunito";

    [role="combobox"] {
      color: white;
      font-size: 12px;
    }

    .MuiSelect-icon {
      color: white;
      top: calc(50% - 14px);
    }

    .MuiSelect-root {
      font-size: 11px;
      color: rgba(255, 255, 255, 0.8);
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      display: none;
    }

    @media (max-width: $screen-md-min) {
      line-height: 12px;
    }

    strong {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
      transition: 0.3s;
      position: relative;
      right: 6px;
      top: 5px;

      @media (max-width: $screen-md-min) {
        font-size: 12px;
      }
    }

    span {
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      transition: 0.3s;
      position: relative;
      right: 0;
      opacity: 0.8;
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: $screen-md-min) {
        font-size: 8px;
        font-weight: 700;
      }
    }
  }

  .profilePicture {
    padding: 0px;
    width: 32px;
    height: 32px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 100%;
    background-size: cover;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    transform: scale(1);
    background-position: center;
    border: 3px solid white;
    box-sizing: content-box;

    &.noPic {
      .userInitial {
        width: 32px;
        height: 32px;
        padding: 0;
        box-shadow: none;
      }
    }

    &:hover {
      transform: scale(1.2);
    }

    @media (max-width: $screen-sm-max) {
      height: 25px;
      width: 25px;
      margin: 0 12px;
    }
  }
}

#user-menu {
  footer {
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 0 0 4px 4px;
  }

  .MuiPopover-paper {
    top: 40px !important;
    right: 51px;
    left: auto !important;
    overflow: visible;
    background-color: rgba(0, 0, 0, 0);

    @media (max-width: $screen-sm-max) {
      right: 35px;
    }

    .MuiList-root {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .MuiMenuItem-root {
      min-width: 150px;
      padding: 0px;
      background-color: white;
      overflow: hidden;
      font-family: "Nunito";

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:hover {
        background-color: #f0f0f0;
      }

      a {
        display: block;
        text-align: left;
        padding: 15px;
        width: 100%;
        color: black;

        &:focus {
          background-color: white;
        }
      }
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      right: 15px;
    }
  }
}

nav {
  background-color: #48bfe5;
  height: 50px;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  padding: 10px 20px;
  overflow: "scroll";

  &[data-tour-elem="navigation"] {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
  }

  @media (max-width: $screen-md-min) {
    padding-left: 60px;
  }

  @media (max-width: $screen-sm-max) {
    padding: 10px;
    justify-content: flex-end;
  }

  ul.menu {
    flex: 1;
    color: white;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    height: 50px;

    #tour-navbar-in-progress {
      min-width: 121px;
    }

    @media (max-width: $screen-sm-max) {
      display: none;
    }

    li {
      text-transform: uppercase;
      font-weight: 500;
      line-height: 38px;
      display: flex;
      align-items: center;
      position: relative;

      &.active {
        background-color: rgba(250, 250, 250, 0.2) !important;
      }

      &:hover {
        background-color: rgba(250, 250, 250, 0.2);
      }

      button {
        transition: background-color 0.3s;
        font-size: 1rem;
        color: #fff;
        display: block;
        padding: 0 15px;
        text-transform: uppercase;
        font-size: 12px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        font-weight: 700;

        &.active {
          background-color: rgba(250, 250, 250, 0.2) !important;
        }

        &:focus {
          background-color: rgba(0, 0, 0, 0);
        }
      }

      a {
        padding: 0 20px;
        font-size: 12px;
        font-weight: 700;

        @media (max-width: $screen-sm-max) {
          color: $blue;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0) !important;
        }
      }
    }
  }
}

.MuiPopover-root {
  z-index: 10;
}

// Sidebar category icons
.MuiList-root,
#tour-sidebar-filters {
  .category-icon-wrapper {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.17);
    border-radius: 25px;
    overflow: hidden;
    display: inline-block;

    .category-icon {
      box-sizing: border-box;
      border-radius: 25px;
      overflow: hidden;
      width: 20px;
      height: 20px;
      display: block;
    }
  }
}

.MuiAutocomplete-popper {
  .category-icon {
    width: 30px !important;
    display: block;
    border-radius: 30px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 5px 0 rgba(0, 0, 0, 0.12);
    border: 3px solid white;
    height: 30px !important;
    position: relative;
    top: -2px;
    left: -6px;
  }

  .MuiPaper-root {
    overflow-x: hidden;
  }

  ul {
    margin: 0 !important;
  }

  .top-level,
  .third-level,
  .second-level {
    max-width: 400px;
    cursor: pointer;

    &:hover {
      background-color: $light-grey;
    }
  }

  span,
  div,
  .category-name-label {
    font-family: "Nunito", sans-serif !important;
  }
}

#tour-sidebar-filters {
  .category-icon-wrapper {
    position: relative;
    top: -2px;

    .category-icon {
      border: 2px solid white;
    }
  }

  .MuiSelect-root {
    display: flex;
  }

  .tagSelectWrapper {
    input {
      &::placeholder {
        opacity: 0.6;
        color: black;
      }
      padding-left: 0;
      font-size: 16px;
    }
  }

  .categorySelectFormControl {
    .MuiFormControl-root input {
      padding-left: 0 !important;
    }

    .hasValue input {
      padding-left: 20px !important;
    }

    .category-icon-wrapper {
      position: relative;
      top: -2px;
    }

    input {
      &::placeholder {
        opacity: 0.6;
        color: black;
      }
    }
  }
}

.timeBlockList {
  .categorySelectFormControl {
    .MuiFormControl-root input {
      padding-left: 0;
    }

    &.hasValue {
      .MuiFormControl-root input {
        padding-left: 125px !important;
      }
    }
  }
}

#admin-menu {
  .MuiPopover-paper {
    background-color: $blue;
    border-radius: 0;
    margin-top: 34px;

    li {
      padding: 0;
      height: 50px;
      display: flex;
      align-items: center;
    }

    .MuiList-root {
      padding: 0;
    }

    a {
      font-weight: 700;
      padding: 6px 30px 6px 15px;
      color: white;
      display: block;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.tour-mask {
  color: rgba(0, 0, 0, 0.3);
}

#tour-sidebar-filters {
  margin: 0 -20px;
  padding: 0 20px 5px;
}

#___reactour {
  .buddy-container .buddy-message {
    background-color: white !important;
  }

  .reactour__helper {
    padding: 0;
    border-radius: 6px;

    .flexEnd {
      margin-top: 15px;
      margin-bottom: -10px;
    }

    .buddy-message {
      padding: 30px 10px 20px;
    }

    .buddy-container {
      margin: 0;
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }

  footer {
    margin-top: 15px;
    margin-bottom: -10px;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .step {
      flex: 1;
      justify-content: center;
      display: flex;
      font-size: 12px;
    }

    .button.disabled {
      opacity: 0;
      pointer-events: none;
    }

    &.flexEnd {
      justify-content: flex-end;
    }
  }

  .reactour__close {
    top: 10px;
    padding: 5px;
    right: 10px;
    width: 20px;
    height: 20px;
    transition: 0.3s;
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
    }

    &:focus {
      background-color: white;
    }
  }

  [data-tour-elem="right-arrow"],
  [data-tour-elem="left-arrow"] {
    background-color: white;
  }

  [data-tour-elem="controls"] {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.MuiDialog-paper {
  overflow: hidden !important;
  animation: fadeInUp 1s;
  border-radius: 12px !important;
  overflow: hidden;
}

.dialog {
  padding: 40px;

  @media (max-width: $screen-sm-max) {
    max-width: 90vw;

    .flexRow {
      flex-direction: column;

      .thumbnail {
        margin: 0 0 20px 0;
      }
    }

    footer {
      margin-top: 10px !important;
    }
  }

  .thumbnail {
    margin-right: 20px;

    .icon {
      width: 100px;
      height: 100px;
      display: block;
    }
  }

  .icon.map {
    background-image: url("../img/map.svg");
  }

  .icon.new-features {
    background-image: url("../img/parachute.svg");
  }

  h2 {
    font-size: 36px;
    margin: 0 0 20px;
    text-align: center;
    font-weight: 700;
  }

  p {
    line-height: 18px;
  }

  footer {
    justify-content: flex-end;
    display: flex;
    margin-top: 20px;
    align-items: center;

    .button {
      margin-left: 25px;
    }
  }
}

.onboarding-background {
  position: fixed;
  top: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: grayscale(1);
  background-size: cover;
  z-index: -1;
}

.onboarding-gradient {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.85;
  mix-blend-mode: hard-light;
  z-index: -1;
}

#onboarding {
  transition: 0.4s;

  .buddy-container {
    position: relative;
    overflow: visible;
  }

  .profilePicture {
    margin-top: 0px !important;
  }

  .profilePictureWrapper {
    position: relative !important;
    top: 0;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.1);
  }

  .profilePictureOnboarding {
    margin: 10px;

    .fileUpload.isUploading {
      top: 0 !important;
    }

    .text {
      border: 6px solid red;
      border-radius: 100%;
      width: 100px;
      height: 100px;
      display: block;
      position: absolute;
      transition: 0.3s;
    }
  }

  .loadingWrapper {
    width: 100%;
    justify-content: center;
  }

  .MuiBackdrop-root {
    background-size: cover;
    background-position: right bottom;
  }

  &[data-onboarding-started="false"] {
    .MuiBackdrop-root {
      background-image: none;
    }

    .MuiDialog-container {
      background: none;
    }
  }

  .dialog {
    padding: 40px;
    display: flex;
    flex-direction: column;

    @media (max-width: $screen-md-max) {
      padding: 20px;

      h2 {
        font-size: 24px;
      }
    }
  }

  .MuiDialog-paper {
    animation: fadeInUp 1s;
    min-width: 1024px;
    overflow: auto !important;

    @media (max-width: $screen-sm-max) {
      min-width: 0px;
      margin: 0px;
      max-width: 100vw;
    }
  }

  .history {
    display: flex;
    justify-content: center;

    .response {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      margin-right: 20px;
      opacity: 0;
      animation: fadeIn forwards 0.3s;
      width: 100px;

      @media (max-width: $screen-md-max) {
        width: 25%;
      }

      .title {
        height: 25px;
        margin-top: 5px;

        p {
          line-height: 12px;
          margin: 0px;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      .onboarding-icon {
        margin-bottom: 5px;
        background-color: $green;
        width: 35px;
        height: 35px;

        &.compliance {
          background-position: left 5px top 5px;
        }
      }
    }
  }

  header {
    font-size: 18px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: 700;

    @media (max-width: $screen-md-max) {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  footer.actions {
    background: white;
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    padding-bottom: 20px;
    justify-content: center;

    &.pager {
      background-color: rgba(0, 0, 0, 0);
      left: 40px;
      right: 40px;
      bottom: 20px;
      padding: 0;

      .button {
        margin: 0 20px;
      }
    }

    &.course {
      position: relative;
    }
  }

  .suggestedCourseWrapper {
    padding: 20px;

    .MuiGrid-root .actions {
      display: none;
    }

    .MuiGrid-root {
      max-width: 100%;

      .card.small {
        .category-icon-wrapper {
          height: 50px;
          transition: 0.4s;

          .category-icon {
            transition: 0.4s;
            width: 50px;
            height: 50px;
          }
        }
      }

      .MuiCard-root:hover {
        .category-icon,
        .category-icon-wrapper {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInput-root {
    margin-top: 0px !important;
  }

  input {
    border-bottom: 0px;
    padding-top: 5px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .MuiFormControl-root {
    @media (max-width: $screen-sm-max) {
      top: -10px;
    }
  }

  .selectGrid {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
    justify-content: center;
    flex-direction: row;
    transition: 1s;
    transform: scale(1);

    .title {
      white-space: nowrap;

      text-align: center;
    }

    button {
      display: flex;
      flex-direction: column;
      width: 250px !important;

      .onboarding-icon {
        flex-shrink: 0;
      }
    }

    @media (max-height: 715px) and (min-width: $screen-sm-max) {
      transform: scale(0.8);
      transform-origin: top center;
    }

    &.goals {
      margin: 0 auto;
    }

    &.certification {
      .button {
        width: 200px;
      }
    }

    @media (max-width: $screen-md-max) {
      width: 100%;

      .button {
        margin: 25px;
      }
    }

    @media (max-width: $screen-sm-max) {
      &.certification {
        .button {
          width: 100px;
        }
      }

      .button {
        margin: 0 15px 0 0 !important;
      }
    }

    .button {
      animation: fadeInUp forwards 0.6s;
      width: 120px;
      height: 120px;
      margin: 10px;
      padding: 10px;
      opacity: 0;

      &.small {
        border-radius: 8px !important;
      }

      @media (max-width: $screen-md-max) {
        width: 120px;
        height: 120px;

        .onboarding-icon {
          width: 50px;
          height: 50px;
        }
      }

      @media (max-width: $screen-sm-max) {
        width: 50px;
        height: 100px;

        .onboarding-icon {
          width: 30px;
          height: 30px;
        }
      }

      &.active {
        background-color: rgba(0, 0, 0, 0);

        .title {
          opacity: 1;
          font-weight: 700;
        }

        .onboarding-icon {
          background-color: $green;
          margin-bottom: 15px;
          transform: scale(1.3);
          opacity: 1;
        }
      }

      .MuiButton-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .title {
        color: black;
        text-transform: none;
        font-weight: 400;
        line-height: 18px;
        font-size: 14px;
        font-weight: bold;
        transform: scale(1);
        transition: 0.3s;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        p {
          line-height: 16px;
          margin: 0;
          text-align: center;
        }

        @media (max-width: $screen-sm-max) {
          font-size: 10px;
          line-height: 12px;
          width: 50px;
        }
      }
    }
  }

  .onboarding-icon {
    transition: 0.3s;
    transform: scale(1);
    margin-bottom: 8px;
    background-color: #f0f0f0;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.1);
    display: block;
    border-radius: 100%;
    width: 65px;
    height: 65px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &.category-icon {
      background-size: 105%;
    }

    &.district {
      background-image: url("../img/onboarding-district.svg");
    }

    &.google.certified {
      background-image: url("../img/i-certification-info.svg");
    }

    &.school {
      background-image: url("../img/onboarding-school.svg");
    }

    &.classroom.teacher {
      background-position: left 2px top 6px;
      background-image: url("../img/onboarding-teacher.svg");
      background-size: 110%;
    }

    &.media {
      background-size: 80%;
      background-image: url("../img/onboarding-media.svg");
    }

    &.coach {
      background-image: url("../img/onboarding-tech.svg");
      background-position: bottom 6px center;
      background-size: 80%;
    }

    &.instructional {
      background-image: url("../img/onboarding-instructional.svg");
      background-size: 80%;
      background-position: left 55% center;
    }

    &.substitute {
      background-image: url("../img/onboarding-substitute.svg");
      background-size: 80%;
      background-position: left 55% center;
    }

    &.leadership {
      background-image: url("../img/onboarding-leadership.svg");
      background-size: 80%;
      background-position: left 55% center;
    }

    &.support {
      background-image: url("../img/onboarding-support.svg");
      background-size: 85%;
      background-position: left 55% center;
    }

    &.special.education {
      background-image: url("../img/special-ed.svg");
      background-size: 85%;
      background-position: left 55% center;
    }

    &.other {
      background-image: url("../img/onboarding-other.svg");
      background-size: 70%;
    }

    &.blended {
      background-image: url("../img/onboarding-distance.svg");
      background-size: 90%;
    }

    &.earn {
      background-image: url("../img/onboarding-certification.svg");
      background-size: 80%;
    }

    &.improve {
      background-image: url("../img/onboarding-improve.svg");
    }

    &.develop.skill {
      background-image: url("../img/onboarding-skill.svg");
      background-size: 80%;
      background-position: top 12px center;
    }

    &.master.microsoft {
      background-image: url("../img/ms-logo.svg");
      background-size: 80%;
      background-position: top 5px center;
    }

    &.compliance {
      background-image: url("../img/onboarding-compliance.svg");
      background-size: 90%;
      background-position: top 8px left 9px;
    }

    &.master.google {
      background-image: url("../img/onboarding-master-google.svg");
      background-size: 70%;
    }

    &.skill.none {
      background-image: url("../img/onboarding-star-1.svg");
      background-size: auto 15px;

      @media (max-width: $screen-sm-max) {
        background-size: auto 8px;
      }
    }

    &.skill.some {
      background-image: url("../img/onboarding-star-2.svg");
      background-size: auto 15px;

      @media (max-width: $screen-sm-max) {
        background-size: auto 8px;
      }
    }

    &.skill.alot {
      background-image: url("../img/onboarding-star-3.svg");
      background-size: auto 30px;

      @media (max-width: $screen-md-max) {
        background-size: auto 25px;
        background-position: center top 45%;
      }

      @media (max-width: $screen-sm-max) {
        background-size: auto 15px;
        background-position: center;
      }
    }
  }

  .widget {
    margin: 0px auto;
    position: relative;
    top: 0;
    transition: 0.3s;

    @media (max-width: $screen-sm-max) {
      transform: scale(0.5) !important;
      transform-origin: center;
      animation: none;
      opacity: 1;
      margin-top: -25%;
    }

    &:hover {
      top: -5px;
      box-shadow: 0px 2px 8px 0 rgba(111, 89, 89, 0.34);
    }
  }

  .widgetLabel {
    background-color: black;
    color: white;
    display: inline-flex;
    align-items: center;

    .level {
      margin-left: 10px;

      .icon.star {
        background-image: url("../img/star.svg");
        width: 8px;
        height: 8px;
        margin-right: 4px;
      }
    }
  }

  .topContent {
    height: 125px;
  }

  .bottomContent {
    h2 {
      text-align: left;
    }
  }

  footer {
    justify-content: space-between;

    button {
      margin: 0;
    }
  }

  p {
    .icon.learningPath {
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
  }

  .progressWrapper {
    position: absolute;
    right: 40px;
    left: 40px;
    background-color: #f0f0f0;
    height: 4px;
    border-radius: 4px;
    bottom: 20px;
    overflow: hidden;
    transition: 1s;

    @media (max-width: $screen-md-max) {
      right: 20px;
      left: 20px;
    }

    .progress {
      margin: 0;
      height: 4px;
      border-radius: 0px;
      transition: 0.8s;

      .inner {
        width: 100%;
        height: 14px;
        background-color: $green;
      }
    }
  }

  .course-list-item {
    list-style-type: none;

    .prompt {
      display: none;
    }

    h2 {
      text-align: left;
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 15px !important;
    }

    .course-details {
      width: 100%;
      margin-top: -20px;
    }

    .icon.star {
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      margin-bottom: -3px;
    }

    .category-icon-wrapper {
      width: 20px;
      height: 20px;

      .category-icon {
        width: 20px;
        height: 20px;
        display: block;
        background-size: contain;
        border-radius: 100%;
        box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.12),
          0 2px 3px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.1);
      }
    }

    header {
      margin-bottom: 0;
    }
  }

  .button.black {
    background-color: white !important;
    color: black;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.actions {
  .cancel-button {
    .button.large {
      background-color: darken($light-grey, 10%);

      &:hover {
        background-color: darken($light-grey, 5%);
      }
    }
  }
}

#mobile-menu {
  .MuiPaper-root {
    width: 80vw;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;

    h3 {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        opacity: 0;
        margin: 0;
        animation: fadeInUp 1s forwards;
        background-color: $blue;
        border-radius: 100px;
        padding: 15px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        &.active {
          background-color: $green;
        }

        &:last-child {
          border-bottom: none;
        }

        a {
          color: white;
          font-weight: normal;
          font-size: 24px;
          padding: 15px 0px;
        }
      }
    }
  }
}
