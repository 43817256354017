@import "./variables.scss";

#categorySelect-popup {
  li {
    padding: 0;
    font-family: "Nunito";

    > div {
      padding: 10px 15px;
      position: relative;

      div.category-icon-wrapper {
        position: relative;
        top: -1px;
        margin-right: 5px;
        display: block;
        box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.17);
        width: 25px !important;
        height: 25px !important;
        border-radius: 30px;

        .category-icon {
          border-radius: 30px;
          border: 3px solid white;
          width: 25px !important;
          height: 25px !important;
          display: block;
        }
      }
    }
  }
}

.categorySelectFormControl {
  .MuiInputBase-input {
    height: 1.1876em;
  }

  .MuiAutocomplete-inputRoot {
    padding-right: 0 !important;
  }

  .MuiAutocomplete-endAdornment {
    .MuiSvgIcon-fontSizeSmall {
      display: none;
    }
  }

  .MuiFormLabel-root {
    &[data-shrink="false"] {
      transform: translate(0px, 22px) !important;
    }
  }

  .category-icon-wrapper.display {
    position: absolute !important;
    top: 18px !important;
    width: 20px !important;
    height: 20px !important;
  }
}

.top-level {
  background-color: white;
  z-index: 5;
  width: 100%;

  &:hover {
    background-color: $light-grey;
  }
}

.second-level,
.third-level {
  position: relative;
}

.second-level {
  margin-left: 15px;
  padding-left: 15px;
}

.third-level {
  margin-left: 30px;
  padding-left: 5px;
}

.parentCategory-select {
  margin-top: 25px;
}

[aria-labelledby="label-parent-category"] {
  .MuiListItem-button {
    height: 50px;
  }
}

.filters.coursesComplete {
  .MuiInputBase-formControl {
    padding-left: 0;
  }
  .MuiFormControl-root {
    margin: 10px 0 !important;
  }
}

.filters.coursesComplete,
.timeBlockList {
  .categorySelectFormControl {
    .MuiAutocomplete-inputRoot {
      input {
        padding-left: 0 !important;
      }
    }

    &.hasValue {
      .MuiAutocomplete-inputRoot {
        input {
          padding-left: 0px !important;
        }
      }
    }
    .category-breadcrumbs {
      margin-left: 10px;
    }
    .menu-category-icon.display {
      top: 25px !important;
      box-shadow: 0 2px 1px 1px rgb(0 0 0 / 17%);
      width: 25px !important;
      height: 25px !important;
      border-radius: 30px;

      .category-icon {
        border-radius: 30px;
        border: 3px solid white;
        width: 25px !important;
        height: 25px !important;
        display: block;
      }
    }
  }
}

.timeBlockList {
  li:first-child {
    .categorySelectFormControl {
      .menu-category-icon.display {
        top: 15px !important;
      }
    }
  }

  .categorySelectFormControl {
    .menu-category-icon.display {
      top: -2px !important;
    }
  }
}
