@import "variables";

@keyframes rotateHour {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(30deg);
  }
}

@keyframes zoomInFadeIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.rewindBanner {
  max-width: 1090px;
  width: 100%;
  margin: 10px;
  margin-bottom: -5px;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  color: black;
  display: inline-flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
  .iconWrapper {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    transition: 0.6s;
  }
  &:hover {
    .iconWrapper {
      padding: 10px 20px;
    }
    .icon {
      transform: rotate(-180deg);
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../img/rewind.svg");
    transition: 0.6s;
    transform: rotate(0deg);
  }
}

.page.rewind {
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;

  .button.primary {
    font-weight: bold;
    margin: 0 auto;
    display: block;
  }

  .loadingWrapper {
    margin-left: -25px !important;
    position: relative !important;
    top: 0 !important;
  }
}

.slide.intro {
  footer {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.slide {
  z-index: 4;
  left: 0;
  top: 0px;
  flex-direction: column;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  animation-delay: 2s;
  transform: translateZ(0);

  &.intro-exit {
    animation: fadeOutDown 1s forwards;
  }

  &.timeSpent-exit {
    animation: fadeOutRightBig 1s forwards;
  }

  &.categoryResults-exit {
    animation: fadeOutDown 1s forwards;
  }

  &.pioneer-exit {
    animation: rotateOutDownRight 1s forwards;
  }

  &.dedication-exit {
    animation: fadeOut 1s forwards;
  }

  &.achievements-exit {
    animation: fadeOutDown 1s forwards;
  }

  &.perfection-exit {
    animation: rotateOutDownRight 1s forwards;
  }

  &.encouragement-exit {
    animation: fadeOutRightBig 1s forwards;
  }

  p {
    font-size: 24px;
    max-width: 750px;
    text-align: center;
  }
}

.slide.timeSpent-enter {
  opacity: 0;
  animation-delay: 1s !important;
  animation: zoomInFadeIn 1s forwards;
}

.slide.categoryResults-enter,
.slide.categoryResults-enter-done {
  opacity: 0;
  animation: zoomInFadeIn 1s forwards;
}

.slide.achievements-enter,
.slide.achievements-enter-done {
  animation-delay: 2s !important;
  opacity: 0;
  animation: zoomInFadeIn 1s forwards;
}

.slide.outro-enter,
.slide.outro-enter-done {
  animation-delay: 2s !important;
  opacity: 0;
  animation: zoomInFadeIn 1s forwards;
}

.slide.goGetter-enter,
.slide.goGetter-enter-done {
  animation-delay: 2s !important;
  opacity: 0;
  animation: flipInX 1s forwards;
}

.slide.dedication-enter,
.slide.dedication-enter-done {
  animation-delay: 2s !important;
  opacity: 0;
  animation: zoomInFadeIn 1s forwards;
}

.slide.encouragement-enter,
.slide.encouragement-enter-done {
  animation-delay: 2s !important;
  opacity: 0;
  animation: zoomInFadeIn 1s forwards;
}

.slide.perfection-enter,
.slide.perfection-enter-done {
  animation-delay: 2s !important;
  opacity: 0;
  animation: backInDown 2s forwards;
}

.rockstar {
  display: block;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../img/rockstar.svg");
  animation-delay: 2s !important;
  margin-top: -100px;
  animation: zoomInUp 2s forwards;
  position: relative;
  transform: translateZ(0);

  &.isMobile {
    animation: fadeIn 2s forwards;
  }

  .eyes {
    z-index: 123;
    position: absolute;
    height: 20px;
    left: 60px;
    right: 60px;
    display: flex;
    top: 86px;
    justify-content: space-between;

    .eye {
      background-color: black;
      border-radius: 40px;
      height: 14px;
      width: 7px;

      &:nth-child(2) {
        animation-delay: 3.75s !important;
        animation: blink 1s forwards;
        transform: translateZ(0);
      }

      @keyframes blink {
        0% {
          height: 14px;
        }

        50% {
          margin-top: 6px;
          height: 4px;
        }

        100% {
          height: 14px;
        }
      }
    }
  }

  .mouth {
    height: 10px;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 80px;
    background-repeat: no-repeat;
    background-image: url("../img/rockstar-mouth.svg");
    animation-delay: 3.75s !important;
    animation: smirk 1s forwards;
    transform: translateZ(0);

    @keyframes smirk {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(20deg);
        left: -10px;
        height: 8px;
      }

      100% {
        transform: rotate(0deg);
      }
    }
  }
}

.slide.encouragement {
  .firstWrapper,
  .secondWrapper,
  .thirdWrapper {
    position: absolute;
  }

  .part.first {
    opacity: 0;
    animation-delay: 2.5s !important;
    animation: bounceIn 1s forwards;
  }

  .firstWrapper {
    animation-delay: 5s !important;
    animation: backOutUp 1s forwards;
  }

  .thirdWrapper {
    animation-delay: 16s !important;
    animation: fadeOut 1s forwards;

    .flipWrapper {
      perspective: 1000px;
      animation-delay: 12s !important;
      opacity: 0;
      animation: fadeInUp 1s forwards;
      margin-bottom: 50px;
    }

    @keyframes flip {
      from {
        transform: rotateY(0deg);
      }

      to {
        transform: rotateY(12000deg);
      }
    }

    .flipWrapper,
    .front,
    .back {
      width: 125px;
      height: 125px;
    }

    .flipper {
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;
      animation: flip 60s forwards;
      animation-timing-function: linear;
    }

    .front,
    .back {
      backface-visibility: hidden;
      background-color: rgba(0, 0, 0, 0);
      background-size: 125px;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    .front {
      z-index: 2;
      background-image: url("../img/i-badge.svg");
      transform: rotateY(0deg);
    }

    .back {
      background-image: url("../img/certificate-large.svg");
      transform: rotateY(180deg);
    }
  }

  .part.second {
    opacity: 0;
    animation-delay: 5.5s !important;
    animation: flipInX 1s forwards;
  }

  .secondWrapper {
    animation-delay: 11s !important;
    animation: backOutUp 1s forwards;
  }

  .part.third {
    opacity: 0;
    animation-delay: 11.5s !important;
    animation: fadeInUp 1s forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.slide.outro {
  color: white;

  .firstWrapper,
  .secondWrapper,
  .thirdWrapper {
    position: absolute;
  }

  .part.first {
    opacity: 0;
    animation-delay: 2.5s !important;
    animation: bounceIn 1s forwards;

    &.isMobile {
      animation: fadeIn 1s forwards;
    }
  }

  .firstWrapper {
    animation-delay: 6.5s !important;
    animation: backOutUp 1s forwards;

    &.isMobile {
      animation: fadeOut 1s forwards;
    }
  }

  .part.second {
    opacity: 0;
    animation-delay: 7s !important;
    animation: bounceIn 1s forwards;
  }

  .categoryList {
    position: absolute;
    left: 100vw;
    display: flex;
    animation: scrollLeft 4.5s forwards;
    animation-timing-function: linear;
    animation-delay: 8s !important;

    &.needsEncouragement {
      animation-delay: 2s !important;
    }

    @keyframes scrollLeft {
      0% {
        left: 100vw;
      }

      99% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        left: -300vw;
      }
    }

    li {
      margin: 10px 30px;
      animation: rotate 5s infinite;

      span {
        display: block;
        width: 50px;
        height: 50px;
        background-size: contain;
      }
    }
  }

  .secondWrapper {
    animation-delay: 11s !important;

    &.needsAnimation {
      animation: backOutUp 1s forwards;
    }
  }

  .mobileSecond {
    p {
      opacity: 0;
      animation-delay: 5s !important;
      animation: fadeIn 1s forwards;
      font-weight: bold;
      font-size: 1.5em !important;
    }
  }

  footer {
    opacity: 0;
    animation-delay: 12.5s !important;
    animation: fadeInUp 1s forwards;
    margin-top: 50px;
    display: flex;
    justify-content: center;

    &.needsEncouragement {
      animation-delay: 8s !important;
    }

    .button {
      color: white;
      margin: 0 25px 0 0;
    }

    .button.white {
      color: black;
    }

    p {
      text-transform: uppercase;
      font-size: 16px;
    }
  }

  .part.third {
    opacity: 0;
    animation-delay: 11.5s !important;
    animation: fadeInUp 1s forwards;
  }
}

.slide.century {
  color: white;

  .disco-ball {
    background-image: url("../img/disco-ball.svg");
    width: 150px;
    margin: 20px auto;
    height: 150px;
    background-size: contain;
    position: relative;
    animation-delay: 2s;

    p {
      z-index: 3;
      margin-top: -100px;
      opacity: 0;
      animation-delay: 2.5s !important;
      animation: fadeInUp 1s forwards;
      font-weight: bold;
      font-size: 75px;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
    }

    @keyframes growDisco {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.4);
      }

      100% {
        transform: scale(1);
      }
    }

    span {
      position: absolute;
      animation: growDisco 1s infinite;
      animation-iteration-count: 5;
      transform: translateZ(0);
      background-image: url("../img/disco-1.svg");
      width: 20px;
      height: 20px;
      display: block;
      background-size: contain;
      bottom: 5px;
      right: 0;

      &:nth-child(2) {
        animation-delay: 0.33s;
        left: 30px;
        top: 5px;
        right: auto;
        background-image: url("../img/disco-2.svg");
      }

      &:nth-child(3) {
        animation-delay: 0.66s;
        right: auto;
        left: auto;
        bottom: 0;
        background-image: url("../img/disco-3.svg");
      }
    }
  }

  .secondWrapper {
    animation-delay: 9.5s !important;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeOutDown 1s forwards;
  }

  .part.first {
    position: absolute;
    animation-delay: 5.5s !important;
    animation: zoomOutDown 1s forwards;
  }

  .part.second {
    position: absolute;
    opacity: 0;
    animation-delay: 6.5s !important;
    animation: fadeIn 1s forwards;
  }
}

.slide.perfection {
  top: 0;

  .part.first {
    animation-delay: 7s !important;
    animation: slideUp 1s forwards;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-100vh);
    }
  }

  .secondWrapper {
    animation-delay: 7.5s !important;
    animation: slideInUp 1s forwards;
  }

  .shotWrapper {
    opacity: 0;
    transform: rotate(0);
    animation: shot 1s forwards;
    animation-delay: 4s !important;
    animation-timing-function: linear;
    transition: 1s;
    position: absolute;
    bottom: -280px;
    left: -795px;
    width: 1250px;
    height: 95px;
    transform: translateZ(0);
    will-change: transform;
  }

  .textLarge {
    position: absolute;
    opacity: 0;
    animation-delay: 8.5s !important;
    animation: fadeInUp 1s forwards;
  }

  .check {
    background-image: url("../img/i-check.svg");
    width: 100px;
    height: 100px;
    background-size: contain;
    transform: rotate(225deg);
    position: absolute;
    margin-right: -100px;
    left: -2px;
    top: -2px;
    opacity: 0;
    animation-delay: 8s !important;
    animation: spinIn 2s forwards;
    transform: translateZ(0);

    @keyframes spinIn {
      0% {
        opacity: 0;
        transform: rotate(225deg);
      }

      100% {
        opacity: 1;
        transform: rotate(945deg);
      }
    }
  }

  .bounce {
    position: absolute;
    width: 1250px;
    height: 95px;
    bottom: 0;
    left: 0;
    transform: translateY(0) translateX(0px);
    animation: bounce 2s forwards;
    animation-delay: 5s !important;
    animation-timing-function: cubic-bezier(0.17, 0.67, 0, 1.04);
    transform: translateZ(0);

    @keyframes bounce {
      0% {
        transform: translateY(0px) translateX(0px);
      }

      50% {
        transform: translateY(-200px) translateX(-50px);
      }

      100% {
        transform: translateY(75vh) translateX(-50px);
      }
    }
  }

  .basketball {
    height: 95px;
    width: 95px;
    background-image: url("../img/basketball-ball.svg");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 44;
    animation-timing-function: linear;
    animation: rotate 2s forwards;
    left: -100px;
    animation-iteration-count: 6;
    transform: translateZ(0);
    will-change: transform;

    @keyframes shot {
      0% {
        opacity: 1;
        transform: rotate(0) scale(1.5);
      }

      100% {
        opacity: 1;
        transform: rotate(135deg) scale(1);
      }
    }
  }

  .backboard {
    width: 400px;
    height: 400px;
    background-image: url("../img/basketball-backboard.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .hoop {
    margin: 0 auto;
    z-index: 50;
    position: absolute;
    margin-top: -165px;
    width: 400px;
    height: 300px;
    background-size: 200px;
    background-position: top center;
    background-image: url("../img/basketball-hoop.svg");
    background-repeat: no-repeat;
    animation: shakeY 0.65s forwards;
    animation-delay: 5s !important;
  }
}

.slide.dedication {
  .season {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;

    &.Fall {
      background-color: rgba(0, 0, 0, 0);
      animation-delay: 5s !important;
      animation: goToFall 1s forwards;

      @keyframes goToFall {
        0% {
          background-color: rgba(0, 0, 0, 0);
        }

        100% {
          background-color: #f1b481;
        }
      }
    }

    &.Winter {
      background-color: rgba(0, 0, 0, 0);
      animation-delay: 5s !important;
      animation: goToWinter 1s forwards;

      @keyframes goToWinter {
        0% {
          background-color: rgba(0, 0, 0, 0);
        }

        100% {
          background-color: #0e91a9;
        }
      }
    }

    &.Spring {
      background-color: rgba(0, 0, 0, 0);
      animation-delay: 5s !important;
      animation: goToSpring 1s forwards;

      @keyframes goToSpring {
        0% {
          background-color: rgba(0, 0, 0, 0);
        }

        100% {
          background-color: #baf2b8;
        }
      }
    }

    &.Summer {
      background-color: rgba(0, 0, 0, 0);
      animation-delay: 5s !important;
      animation: goToSummer 1s forwards;

      @keyframes goToSummer {
        0% {
          background-color: rgba(0, 0, 0, 0);
        }

        100% {
          background-color: #f4ee71;
        }
      }
    }

    .cloud {
      width: 200px;
      height: 150px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../img/cloud.svg");
      position: fixed;
      right: 20vw;
      top: 200px;
      z-index: 2;
      animation: floating 70s forwards;

      &:nth-child(2) {
        width: 140px;
        margin-top: 250px;
        margin-right: -200px;
      }

      &:nth-child(3) {
        transform: scaleX(-1);
        width: 120px;
        z-index: 1;
        margin-top: -20px;
        margin-right: -50px;
      }

      &:nth-child(4) {
        width: 120px;
        z-index: 1;
        margin-top: -20px;
        right: auto;
        left: 300px;
        bottom: 140px;
        top: auto;
      }
    }
  }

  .secondWrapper {
    animation-delay: 9s !important;
    animation: fadeOutDown 1s forwards;
  }

  .part.first {
    position: absolute;
    animation-delay: 4.5s !important;
    animation: flipOutX 1s forwards;
  }

  .part.second {
    opacity: 0;
    animation-delay: 5.5s !important;
    animation: fadeIn 1s forwards;

    .textLarge {
      margin: 0 auto;
    }
  }

  .cal-complete {
    display: block;
    width: 80px;
    height: 80px;
    background-image: url("../img/cal-complete.svg");
    margin: 25px 15px;
    animation: jello 1s forwards;
  }

  .mm-logo {
    background-color: rgba(0, 0, 0, 0);
    width: 150vw;
    height: 150vw;
    opacity: 0.05;
    position: absolute;
    top: -75vw;
    left: -75vw;
    transform: translateZ(0);
  }
}

.slide.goGetter {
  color: white;

  .logoWrapper {
    animation-delay: 10s !important;
    animation: fadeOutDown 1s forwards;
  }

  .secondWrapper {
    animation-delay: 10s !important;
    animation: bounceOutUp 1s forwards;
    position: absolute;
  }

  .textLarge {
    z-index: 5;
    position: relative;
  }

  .part.first {
    animation-delay: 4.5s !important;
    animation: fadeOutRightBig 1s forwards;
  }

  .part.second {
    opacity: 0;
    animation-delay: 5s !important;
    animation: zoomInFadeIn 1s forwards;
  }

  .checks {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    top: -50vh;
    display: flex;
    left: -50vw;
  }

  .icon.check {
    width: 100px;
    height: 100px;
    position: absolute;
    animation-delay: 3s !important;
    animation: shoot 0.6s ease-out infinite;
    animation-name: shoot, fade;
    opacity: 0;

    @for $i from 0 to 400 {
      $t: (1 + 0.01 * random(100)) * 1s;

      &:nth-child(#{$i + 1}) {
        transform: translate(random(100) * 1vw, random(100) * 1vh);
        animation-duration: $t;
        animation-delay: -0.01 * random(100) * $t;
      }
    }
  }

  @keyframes shoot {
    0% {
      transform: translate(10vw, 10vh);
    }
  }

  @keyframes fade {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .mm-logo {
    background-color: rgba(0, 0, 0, 0);
    width: 150vw;
    height: 150vw;
    opacity: 0.05;
    position: absolute;
    top: -75vw;
    left: -75vw;
  }
}

.background-tile {
  z-index: -1;
  opacity: 0;
  background-size: 180px;
  animation: animateBg 30s forwards;
  animation-timing-function: linear;
  position: fixed;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  transform: translateZ(0);

  &.coffee-bg {
    background-image: url("../img/coffee-bg.svg");
  }

  &.time-bg {
    background-image: url("../img/time-bg.svg");
  }

  &.trophy-bg {
    background-image: url("../img/trophy-bg.svg");
  }

  &.study-bg {
    background-image: url("../img/study-bg.svg");
  }

  &.check-bg {
    animation-delay: 3s !important;
    background-image: url("../img/check-bg.svg");
  }

  &.rock-bg {
    background-image: url("../img/rock-bg.svg");
    animation: animateBg 30s infinite;
  }

  &.rocket-bg {
    background-image: url("../img/rocket-bg.svg");
  }

  &.club-100-bg {
    background-image: url("../img/100-bg.svg");
  }

  &.club-75-bg {
    background-image: url("../img/75-bg.svg");
  }

  &.club-50-bg {
    background-image: url("../img/50-bg.svg");
  }

  @keyframes animateBg {
    0% {
      opacity: 0;
      background-position: left 100% center;
    }

    5% {
      opacity: 0.1;
    }

    100% {
      opacity: 0.1;
      background-position: left 0% center;
    }
  }
}

.slide.achievements {
  max-height: 100vh;
  top: 0;

  .header {
    opacity: 0;
    animation-delay: 5.5s !important;
    animation: fadeInLeftBig 1s forwards;
    margin-bottom: 25px;
    text-align: left;
  }

  .part {
    &.first {
      animation-delay: 5.5s !important;
      animation: fadeOutDown 1s forwards;
      .textLarge {
        opacity: 0;
        animation-delay: 2s !important;
        animation: fadeInUp 1s forwards;
      }
    }

    &.second {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      justify-content: center;
      align-items: flex-start;
      bottom: 0;
      display: flex;
      padding-left: 100px;
      flex-direction: column;
      animation: fadeOutRightBig 1s forwards;
    }
  }

  .stats {
    .count {
      font-size: 48px;
      font-weight: bold;
      position: relative;
      top: 7px;
      margin-left: 10px;
    }

    strong {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }

  .groupWrapper {
    margin-bottom: 15px;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .statGroup {
    display: flex;
    opacity: 0;
    flex-direction: column;

    ul {
      left: 0;
      bottom: -50px;
      display: flex;
      flex-wrap: wrap;
      width: 80vw;
      justify-content: flex-start;
      align-items: flex-start;

      li {
        opacity: 0;
        will-change: transform;
        transform: translateZ(0);
        animation: backInUp 0.2s forwards;
      }

      .icon {
        margin-right: 3px;
      }
    }

    &.coursesCompleted {
      opacity: 0;
      animation: fadeInUp 1s forwards;
    }

    &.badgesEarned {
      opacity: 0;
      animation: fadeInUp 1s forwards;
    }

    &.learningPaths {
      opacity: 0;
      animation: fadeInUp 1s forwards;
    }

    .icon {
      display: block;
      width: 30px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 10px;

      &.badge {
        background-image: url("../img/i-badge.svg");
      }

      &.laptop {
        background-image: url("../img/laptop.svg");
      }

      &.learningPath {
        background-image: url("../img/learning-path.svg");
      }
    }
  }
}

.slide.categoryResults {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .part.second {
    .textLarge {
      margin: 0 0 45px 0 !important;
    }
  }

  .topFive {
    margin: 0;
    padding: 0;
    width: 500px;

    li {
      display: flex;
      align-items: center;
      opacity: 0;
      animation: fadeInLeftBig 0.4s forwards;
      margin-bottom: 15px;
      font-size: 18px;

      .total {
        flex: 1;
        text-align: right;
        text-transform: uppercase;
        font-size: 14px;

        strong {
          font-size: 28px;
          position: relative;
          top: 4px;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    .categoryImage {
      margin-right: 15px;
      width: 45px;
      height: 45px;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 100%;
      overflow: hidden;
    }
  }

  .part {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backface-visibility: visible !important;

    &.first {
      animation-delay: 8s !important;
      animation: flipOutX 1s forwards;
    }

    &.second {
      position: absolute;
      //      animation-delay: 8.3s !important;
      animation: flipInX 1s forwards;
    }
  }

  .textLarge {
    margin-top: -300px;
    margin-bottom: 300px;
    opacity: 0;
    animation-delay: 1.25s !important;
    animation: fadeInUp 1s forwards;
  }

  .message {
    position: absolute;
    margin-top: 120px;
    font-weight: bold;
    left: 0;
    right: 0;
    opacity: 0;
    animation: zoomInFadeIn 1s forwards;
    animation-delay: 2.5s;

    p {
      max-width: 120px;
      font-size: 18px;
      line-height: 20px !important;
      margin: 0 auto;
    }
  }
}

.slide.timeSpent {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .flexRow {
    justify-content: center;
  }

  .icon.hand {
    animation-timing-function: linear;
    animation-delay: 2s;
    position: absolute;
    left: 0;
    width: 300px;
    height: 300px;
    margin-left: 50px;
  }

  .icon.minute {
    animation: rotate 2s infinite;
    animation-timing-function: linear;
    background-image: url("../img/clock-hand.svg");
    transform: translateZ(0);
  }

  .icon.hour {
    animation: rotate 20s forwards;
    animation-timing-function: linear;
    background-image: url("../img/clock-hand-hour.svg");
  }

  p {
    text-align: right;
  }

  .clockWrapper {
    position: relative;

    .time {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(250, 250, 250, 0.95);
      border-radius: 100%;
      width: 260px;
      height: 260px;
      margin: 20px;
      text-transform: uppercase;

      strong {
        margin-bottom: -15px;
        font-size: 56px;
        color: $blue;
        display: block;
      }
    }

    .clock {
      background-image: url("../img/clock-large.svg");
      width: 300px;
      height: 300px;
      margin-left: 50px;
    }
  }
}

.textLarge {
  margin: 0;
  text-align: center;
  font-size: 44px !important;
  max-width: 80vw;
  font-weight: bold;
}

.favoriteStats {
  opacity: 0;
  animation: fadeInUp 1s forwards;
  animation-delay: 6s;
  text-align: center;
  width: 800px;
  z-index: 5;

  strong {
    font-size: 36px;
  }
}

@keyframes marginDown {
  0% {
    margin-bottom: 0;
  }

  100% {
    margin-bottom: -175px;
  }
}

.textWrapper {
  animation-delay: 7s;
  animation: marginDown 1s forwards;
}

.categoryWheel {
  opacity: 0;
  animation: zoomInFadeIn 1s forwards;
  animation-delay: 2s;
  margin-top: 145px;
  transform: translateZ(0);

  .rotateWrapper {
    animation: rotate 2s forwards;
    animation-delay: 4s;
  }

  .favorite {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
  }

  .favoriteImage {
    width: 160px;
    height: 160px;
    margin-top: -75px;
    margin-bottom: 20px;
    display: block;
    background-size: contain;
    opacity: 0;
    animation: zoomInFadeIn 0.7s forwards;
    animation-delay: 6s;
  }

  .hub {
    animation: rotate 4s infinite;
    animation-timing-function: linear;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .spokeWrapper {
    position: absolute;

    &.extend1 .spoke {
      top: -80px;

      &:before {
        bottom: -10px;
      }

      &:after {
        height: 110px;
      }
    }
  }

  .spoke {
    height: 360px;
    width: 40px;
    padding: 5px;
    position: relative;
    animation: fade 1s forwards;
    animation-delay: 6s;

    &:before {
      display: block;
      content: "";
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 100%;
      position: absolute;
      bottom: 80px;
      left: 10px;
    }

    &:after {
      display: block;
      content: "";
      height: 40px;
      width: 1px;
      background-color: white;
      position: absolute;
      left: 25px;
    }
  }

  .categoryImage {
    display: block;
    background-size: contain;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
  }
}

.mm-logo.large {
  opacity: 0.3;
  width: 100px;
  height: 100px;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  background-color: white;
  border-radius: 100%;
  will-change: transform;
  animation: rotate 10s infinite;
  animation-timing-function: linear;
  margin: 0 auto;
  display: block;
  transition: 2s;

  will-change: transform;
  transform: translateZ(0);

  &.slide-1 {
    opacity: 1;
    animation: slide1 2s forwards;
  }

  &.slide-2 {
    opacity: 1;
    animation: slide2 2s forwards;
  }

  &.slide-3 {
    opacity: 1;
    animation: slide3 2s forwards;
  }

  &.slide-4 {
    opacity: 1;
    animation: slide4 2s forwards;
  }

  &.slide-5 {
    opacity: 1;
    animation: slide5 2s forwards;
  }

  &.slide-6 {
    opacity: 1;
    animation: slide6 2s forwards;
  }

  &.slide-7 {
    opacity: 1;
    animation: slide7 2s forwards;
  }
}

@keyframes floating {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-60vw);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes slide6 {
  0% {
    width: 29000px;
    height: 29000px;
    margin-left: 2660px;
    margin-top: 16800px;
    transform: translateZ(0) rotate(148deg);
  }

  50% {
    width: 500px;
    height: 500px;
    margin-left: 0;
    margin-top: 0;
  }

  100% {
    width: 28000px;
    height: 28000px;
    margin-left: 1980px;
    margin-top: 16800px;
    transform: translateZ(0) rotate(45.5deg);
  }
}

@keyframes slide7 {
  0% {
    width: 29000px;
    height: 29000px;
    margin-left: 2660px;
    margin-top: 16800px;
    transform: translateZ(0) rotate(148deg);
  }

  50% {
    width: 500px;
    height: 500px;
    margin-left: 0;
    margin-top: 0;
  }

  100% {
    width: 25000px;
    height: 25000px;
    margin-left: 1800px;
    margin-top: 14800px;
    transform: translateZ(0) rotate(-389deg);
  }
}

@keyframes slide5 {
  0% {
    width: 28000px;
    height: 28000px;
    margin-left: 1980px;
    margin-top: 16800px;
    transform: translateZ(0) rotate(45.5deg);
  }

  50% {
    width: 500px;
    height: 500px;
    margin-left: 0;
    margin-top: 0;
  }

  100% {
    transform: translateZ(0) rotate(245deg);
    margin-top: 15500px;
    margin-left: 680px;
    width: 20000px;
    height: 20000px;
  }
}

@keyframes slide4 {
  0% {
    width: 28000px;
    height: 28000px;
    margin-left: 1980px;
    margin-top: 16800px;
    transform: translateZ(0) rotate(45.5deg);
  }

  50% {
    width: 500px;
    height: 500px;
    margin-left: 0;
    margin-top: 0;
  }

  100% {
    width: 29000px;
    height: 29000px;
    margin-left: 2660px;
    margin-top: 16800px;
    transform: translateZ(0) rotate(148deg);
  }
}

@keyframes slide3 {
  0% {
    width: 25000px;
    height: 25000px;
    margin-left: 1800px;
    margin-top: 14800px;
    transform: translateZ(0) rotate(-389deg);
  }

  50% {
    width: 500px;
    height: 500px;
    margin-left: 0;
    margin-top: 0;
  }

  100% {
    width: 28000px;
    height: 28000px;
    margin-left: 1980px;
    margin-top: 16800px;
    transform: translateZ(0) rotate(45.5deg);
  }
}

@keyframes slide2 {
  0% {
    width: 25000px;
    height: 25000px;
    margin-left: 1800px;
    margin-top: 14800px;
    transform: translateZ(0) rotate(0deg);
  }

  50% {
    width: 500px;
    height: 500px;
    margin-left: 0;
    margin-top: 0;
  }

  100% {
    width: 25000px;
    height: 25000px;
    margin-left: 1800px;
    margin-top: 14800px;
    transform: translateZ(0) rotate(-389deg);
  }
}

@keyframes slide1 {
  from {
    width: 100px;
    height: 100px;
    margin-left: 0;
    margin-top: 0;
    transform: translateZ(0) rotate(0deg);
  }

  to {
    width: 30000px;
    height: 30000px;
    margin-left: 2075px;
    margin-top: 17800px;
    transform: translateZ(0) rotate(0deg);
  }
}

/* leaf animations */
#fallingObjects {
  position: fixed;
  margin-top: -70vh;
  width: 100%;
  left: 50vw;
  transform: scale(4);
}

#fallingObjects {
  i {
    display: inline-block;
    width: 200px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    animation: falling 5s 0s forwards;
    animation-iteration-count: 2s;
    transform: translateZ(0);
  }

  &.Fall i {
    background-image: url("../img/leaf.svg");
  }

  &.Winter i {
    background-image: url("../img/snowflake.svg");
  }

  &.Spring i {
    background-image: url("../img/flower.svg");
  }

  &.Summer i {
    background-image: url("../img/beach-ball.svg");
  }
}

#fallingObjects i:nth-of-type(2n) {
  animation: falling2 5s 0s infinite;
}

#fallingObjects i:nth-of-type(3n) {
  animation: falling3 5s 0s infinite;
}

#fallingObjects i:nth-of-type(n) {
  height: 13px;
  width: 10px;
}

#fallingObjects i:nth-of-type(n):before {
  width: 12px;
  height: 5px;
  top: 17px;
  right: 1px;
}

#fallingObjects i:nth-of-type(n):after {
  width: 4px;
  height: 10px;
  left: 12px;
  top: 0px;
}

#fallingObjects i:nth-of-type(2n + 1) {
  height: 11px;
  width: 16px;
}

#fallingObjects i:nth-of-type(2n + 1):before {
  width: 8px;
  height: 13px;
  top: 7px;
  right: 0px;
}

#fallingObjects i:nth-of-type(2n + 1):after {
  width: 2px;
  height: 6px;
  left: 5px;
  top: 1px;
}

#fallingObjects i:nth-of-type(3n + 2) {
  height: 8px;
  width: 12px;
}

#fallingObjects i:nth-of-type(3n + 2):before {
  height: 4px;
  width: 2px;
  top: 12px;
  right: 1px;
}

#fallingObjects i:nth-of-type(3n + 2):after {
  height: 10px;
  width: 2px;
  top: 1px;
  left: 8px;
}

#fallingObjects i:nth-of-type(n) {
  animation-delay: 1.9s;
}

#fallingObjects i:nth-of-type(2n) {
  animation-delay: 5.9s;
}

#fallingObjects i:nth-of-type(3n) {
  animation-delay: 1.3s;
}

#fallingObjects i:nth-of-type(4n) {
  animation-delay: 2.4s;
}

#fallingObjects i:nth-of-type(5n) {
  animation-delay: 1.5s;
}

#fallingObjects i:nth-of-type(6n) {
  animation-delay: 3.5s;
}

#fallingObjects i:nth-of-type(7n) {
  animation-delay: 2.8s;
}

#fallingObjects i:nth-of-type(8n) {
  animation-delay: 2.5s;
}

#fallingObjects i:nth-of-type(9n) {
  animation-delay: 3.3s;
}

#fallingObjects i:nth-of-type(10n) {
  animation-delay: 2.5s;
}

#fallingObjects i:nth-of-type(11n) {
  animation-delay: 1.2s;
}

#fallingObjects i:nth-of-type(12n) {
  animation-delay: 4.1s;
}

#fallingObjects i:nth-of-type(13n) {
  animation-delay: 1s;
}

#fallingObjects i:nth-of-type(14n) {
  animation-delay: 4.7s;
}

#fallingObjects i:nth-of-type(15n) {
  animation-delay: 3s;
}

#fallingObjects i:nth-of-type(n) {
  opacity: 0.7;
}

#fallingObjects i:nth-of-type(3n + 1) {
  opacity: 0.5;
}

#fallingObjects i:nth-of-type(3n + 2) {
  opacity: 0.4;
}

#fallingObjects i:nth-of-type(n) {
  transform: rotate(180deg);
}

#fallingObjects i:nth-of-type(n) {
  animation-timing-function: ease-in-out;
}

@keyframes falling {
  0% {
    -webkit-transform: translate3d(300, 0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate3d(-350px, 700px, 0) rotate(90deg);
    opacity: 0;
  }
}

@keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-20deg);
  }

  100% {
    -webkit-transform: translate3d(-230px, 640px, 0) rotate(-70deg);
    opacity: 0;
  }
}

@keyframes falling2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
  }

  100% {
    -webkit-transform: translate3d(-400px, 680px, 0) rotate(0deg);
    opacity: 0;
  }
}

.lamp {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-delay: 2.5s !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.12);
  height: 100vh;
  height: 100vh;
  width: 100vw;
  min-width: 100vw;
  z-index: -1;
}

.lava {
  filter: url("#goo");
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.blob {
  border-radius: 50%;
  background: $blue;
  position: absolute;
}

.blob:nth-child(1) {
  width: 200px;
  height: 200px;
  left: 35%;
  bottom: -15%;

  animation: wobble 4s ease-in-out alternate infinite,
    blob-one ease-in-out 13s infinite;
}

.blob:nth-child(2) {
  width: 330px;
  height: 330px;
  right: 24%;
  bottom: -65%;
  animation: wobble 5s ease-in-out alternate infinite,
    blob-two ease-in-out 22s infinite;
}

.blob:nth-child(3) {
  width: 150px;
  height: 150px;
  bottom: -15%;
  left: 34%;

  animation: wobble 6s ease-in-out alternate infinite,
    blob-three ease-in-out 16s infinite;
}

.blob:nth-child(4) {
  width: 235px;
  height: 235px;
  bottom: -19%;
  left: 30%;

  animation: wobble 7s ease-in-out alternate infinite,
    blob-four ease-in-out 12s infinite;
}

.blob:nth-child(4) {
  width: 235px;
  height: 235px;
  bottom: -19%;
  left: 30%;
  animation: wobble 8s ease-in-out alternate infinite,
    blob-four ease-in-out 12s infinite;
}

.blob:nth-child(5) {
  width: 55px;
  height: 55px;
  bottom: -25%;
  left: 34%;

  animation: wobble 9s ease-in-out alternate infinite,
    blob-five ease-in-out 32s infinite;
}

.blob:nth-child(6) {
  width: 35px;
  height: 35px;
  bottom: -25%;
  right: 34%;

  animation: wobble 10s ease-in-out alternate infinite,
    blob-six ease-in-out 12s infinite;
}

.blob:nth-child(7) {
  width: 435px;
  height: 435px;
  bottom: -85%;
  right: 40%;

  animation: wobble 11s ease-in-out alternate infinite,
    blob-seven ease-in-out 32s infinite;
}

@keyframes blob-one {
  0%,
  100% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-700%);
  }
}

@keyframes blob-two {
  0%,
  100% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-420%);
  }
}

@keyframes blob-three {
  0%,
  100% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-305%);
  }
}

@keyframes blob-four {
  0%,
  100% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-605%);
  }
}

@keyframes blob-five {
  0%,
  100% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-700%);
  }
}

@keyframes blob-six {
  0%,
  100% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-700%);
  }
}

@keyframes blob-seven {
  0%,
  100% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-300%);
  }
}

@keyframes wobble {
  50% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  }

  100% {
    border-radius: 38% 52% 75% 36% / 50% 40% 50% 60%;
  }
}

.rewind-modal .MuiDialog-paper {
  max-width: 640px;

  .flexRow {
    padding: 20px;
  }

  .shareLink {
    height: auto !important;
    padding: 0px;
  }

  h2 {
    display: flex;
    align-items: center;

    .title {
      flex: 1;
      font-weight: bold;
    }

    .timestamp {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.loadingOverlay {
  position: absolute;
  background-color: rgba(250, 250, 250, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  .loadingWrapper {
    top: 200px;

    span {
      margin: 0;
    }

    .square {
      margin-left: -25px;
    }
  }
}

.onePager {
  width: 520px;
  padding: 0px 20px 0 20px;
  background-color: white;

  h2 {
    margin: 0;
    padding: 0 0px 20px;
  }

  .icon.rewind {
    margin-right: 10px;
    background-image: url("../img/rewind.svg");
  }

  ul {
    display: flex;
    margin: 0 -20px;
    flex-wrap: wrap;

    .timeSpent {
      font-weight: bold;
    }

    li {
      width: 260px;
      padding: 20px;

      &:nth-child(1) {
        background-color: #cdedf7;
      }

      &:nth-child(2) {
        background-color: #eef9fc;
      }

      &:nth-child(3) {
        background-color: #e9f6e1;
      }

      &:nth-child(4) {
        background-color: #def2d3;
      }

      &:nth-child(5) {
        background-color: #cdedf7;
      }

      &:nth-child(6) {
        background-color: #eef9fc;
      }

      &:nth-child(7) {
        background-color: #e9f6e1;
      }

      &:nth-child(8) {
        background-color: #def2d3;
      }

      span {
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 10px;
        display: block;
      }

      .category-icon {
        border-radius: 100%;
        overflow: hidden;
      }

      .icon {
        background-repeat: no-repeat;
        background-position: center;
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 10px 0 0;

        &.laptop {
          background-image: url("../img/laptop.svg");
        }

        &.Fall {
          background-image: url("../img/leaf.svg");
        }

        &.Winter {
          background-image: url("../img/snowflake.svg");
        }

        &.Spring {
          background-image: url("../img/flower.svg");
        }

        &.Summer {
          background-image: url("../img/beach-ball.svg");
        }
      }

      header {
        display: flex;
        align-items: center;
        font-size: 18px;
      }
    }
  }
}

.icon.loading {
  background-image: url("../img/loading.svg");
  width: 25px;
  height: 25px;
  display: block;
  animation: rotate 0.6s infinite linear;
}

.page.rewind.mobileRewind {
  transition: background-color 1s;

  .mm-logo.large {
    &.slide-1,
    &.slide-2,
    &.slide-3,
    &.slide-4,
    &.slide-5,
    &.slide-6,
    &.slide-7,
    &.slide-8,
    &.slide-9 {
      display: none !important;
      animation: none !important;
    }
  }

  &.slide-bg-1 {
    background-color: #99d2de;
  }

  &.slide-bg-2 {
    background-color: #b2d491;
  }

  &.slide-bg-3 {
    background-color: #99d2de;
  }

  &.slide-bg-4 {
    background-color: #767674;
  }

  &.slide-bg-5 {
    background-color: #9ed4df;
  }

  &.slide-bg-6 {
    background-color: #b2d491;
  }

  &.slide-bg-7 {
    background-color: #767674;
  }

  &.slide-bg-8 {
    background-color: #99d2de;
  }

  &.slide-bg-9 {
    background-color: black;
  }

  .slide.intro {
    justify-content: space-between;
    padding-bottom: 20px;

    p {
      margin-top: 25%;
      padding: 20px;
      font-size: 1.2em;
    }

    .MuiButtonBase-root.primary {
      background-color: #24dc91;
      border-radius: 55px;
      margin-bottom: 150px;
      box-shadow: none;
      padding: 10px;

      .MuiButton-label {
        color: black;
      }
    }
  }

  .slide.timeSpent {
    .flexRow {
      flex-direction: column;
    }

    p.textLarge {
      padding: 20px;
      max-width: 100% !important;
      font-size: 1.3em !important;
      text-align: center;
    }

    .clockWrapper {
      margin-top: 100px;

      .icon.hand {
        margin-left: 0px;
      }
    }

    .clockWrapper .clock {
      margin-left: 0 !important;
    }
  }

  .slide.achievements {
    .part.first {
      .textLarge {
        padding: 20px;
        font-size: 2em !important;
      }
    }

    .part.second {
      padding-left: 0px;
      padding: 20px;

      .statGroup {
        ul {
          margin-top: 5px;
        }

        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .stats {
        header {
          display: flex;
          align-items: "center";
        }

        width: 100%;

        strong {
          flex: 1;
          display: flex;
          margin-bottom: 0px;
          font-size: 1.5em !important;
        }

        .count {
          margin-top: -20px;
          font-size: 3em !important;
        }
      }

      .textLarge.header {
        font-size: 1.5em !important;
      }
    }
  }

  .slide.goGetter .textLarge {
    font-size: 1.5em !important;
    padding: 30px;
  }

  .slide.dedication {
    .secondWrapper {
      max-width: 100%;
    }

    .icon.cal-complete {
      background-repeat: no-repeat;
      width: 60px;
      height: 60px;
    }

    .part.second {
      p.textLarge {
        padding: 20px;
      }
    }

    p.textLarge {
      font-size: 1.6em !important;
      margin-bottom: 20px;
    }
  }

  .slide.perfection {
    .bounce {
      animation: bounceMobile 2s forwards;
      animation-delay: 5s !important;
      animation-timing-function: cubic-bezier(0.17, 0.67, 0, 1.04);
      transform: translateZ(0);

      @keyframes bounceMobile {
        0% {
          transform: translateY(0px) translateX(0px);
        }

        50% {
          transform: translateY(-200px) translateX(-50px);
        }

        100% {
          transform: translateY(100vh) translateX(-50px);
        }
      }
    }

    .shotWrapper {
      top: 100px;
      left: -780px !important;
    }

    .textLarge {
      padding: 20px;
      font-size: 2em !important;
    }
  }

  .slide.encouragement {
    p.textLarge {
      padding: 20px;
      font-size: 1.5em !important;
    }
  }

  .slide.century {
    p.textLarge {
      padding: 20px;
      font-size: 2em !important;
    }
  }

  .slide.outro .categoryList li {
    span {
      animation: rotate 2.5s infinite;
      width: 35px;
      height: 35px;
    }
  }

  .slide.outro {
    p.textLarge {
      padding: 20px;
      max-width: 450px;
      font-size: 1.5em !important;
    }

    .third {
      footer {
        flex-direction: column;
        align-items: center;

        button {
          display: none;
        }

        a {
          display: none;
        }
      }
    }
  }

  .slide.categoryResults {
    .part.first {
      .message {
        margin-top: 60px;

        &.tablet {
          margin-top: 30px;
        }
      }
    }

    .topFive {
      width: 100%;

      .categoryImage {
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25) !important;
      }
    }

    p.textLarge {
      font-size: 2em !important;
    }
  }
}
