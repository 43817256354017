@import "variables.scss";

.MuiButtonBase-root:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

#date-range-container {
  label {
    margin-left: -15px;
  }
}

.page.reports {
  padding-bottom: 150px;

  .MuiTable-root {
    margin-bottom: 25px;
  }

  .userTotals {
    .capsule {
      font-weight: normal;
      text-transform: none;
      padding: 5px 15px;
      margin: 0 10px;
      background-color: $green;
      color: black;

      .icon.clock {
        margin-left: 0;
        width: 15px;
        height: 15px;
        background-image: url("../img/clock-black.svg");
      }
    }
  }

  .empty {
    font-style: italic;
    margin-left: 15px;
    margin-top: -25px;
  }

  .icon {
    opacity: 1;
  }

  .userRank {
    margin-left: 20px;
    flex-shrink: 0;

    .rank {
      font-family: "Nunito";
      font-size: 14px;
      color: black;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      animation: fadeInUp 1.25s forwards;
      flex-shrink: 0;
      flex: 1;
      text-align: right;
      margin-right: 15px;
      font-weight: bold;

      .icon {
        display: inline-block;
        width: 30px;
        position: relative;
        top: -2px;
        margin-right: 8px;
        height: 30px;
        background-repeat: no-repeat;
        background-image: url("../img/ranking.svg");

        &.first {
          background-image: url("../img/trophy.svg");
        }
      }
    }
  }

  .loadingWrapper {
    animation: fadeInUp 1s forwards;
  }

  .userImage {
    display: block;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    border-radius: 100%;
    flex-shrink: 0;
  }

  .MuiTableRow-head {
    th {
      font-family: "Nunito";
      color: #333;
      border-bottom: 0px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
    }
  }

  #sidebar {
    padding-top: 60px;

    .filters {
      margin-left: -5px;

      .MuiSelect-root {
        padding-left: 0;
      }
    }

    .inputSelect {
      margin-top: 0;
    }

    .MuiInputAdornment-root {
      button:focus {
        background-color: $blue;
      }
    }

    .MuiInputBase-inputAdornedEnd {
      padding-left: 0px !important;
    }

    .MuiFormControl-root {
      margin-bottom: 20px;
    }
  }

  thead {
    border: 0px;
  }

  tbody {
    background-color: white;

    td {
      padding: 20px;
      border-bottom: 1px solid $light-grey;

      .flexRow.total {
        justify-content: flex-end;
        font-family: "Nunito";
      }
    }

    tr.current {
      border-left: 5px solid $green;

      td:first-child {
        padding-left: 15px;
      }
    }

    tr:first-child {
      td {
        border-top: none;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  .MuiTableCell-root {
    user-select: none;
  }

  .inputSearch {
    .icon {
      position: absolute;
      margin-top: -5px;
    }
  }

  th {
    padding-bottom: 30px;
    border-bottom: none !important;
    padding-right: 20px;

    .count {
      margin: 0 8px;
    }

    .userImage {
      width: 30px;
      height: 30px;
      margin-right: 0;
    }

    .flexRow {
      justify-content: flex-end;
      line-height: 14px;
      font-family: "Nunito";
    }

    .icon.sort {
      margin-left: -10px;
    }

    span {
      margin: 0 10px;
      font-size: 28px;
      text-transform: none;
      font-weight: 700;
      color: $blue;
    }
  }

  .courses-completed {
    font-size: 28px;
    font-weight: 700;
    color: $blue;
    display: flex;
    align-items: center;

    .capsule {
      padding: 5px 15px;
      font-weight: normal;

      .icon.clock {
        width: 15px;
        height: 15px;
        margin-left: 0;
      }
    }

    .activeUser {
      .capsule {
        background-color: $green;
        color: black;

        .icon.clock {
          background-image: url("../img/clock-black.svg");
        }
      }
    }
  }

  .icon.check {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background-image: url("../img/i-check.svg");
  }

  .icon.clock {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .icon.badge {
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
    margin-left: 10px;
    background-image: url("../img/i-badge.svg");
  }
}

.dateTabs {
  margin-bottom: 25px;

  button {
    align-items: flex-start;
  }

  .MuiTabs-flexContainer {
    flex-direction: column;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
    font-weight: bold;
    background-color: $green;
    border-radius: 6px;
    color: black !important;
  }

  .MuiTab-wrapper {
    align-items: flex-start;
  }
}

#sidebar {
  .MuiSelect-select {
    padding-top: 25px;
    padding-left: 0;
  }
}

.MuiOutlinedInput-notchedOutline {
  display: none;
}
