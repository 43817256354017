@import "variables";

.exploreLayout {
  .loadingWrapper {
    margin-top: 25px;
  }

  .filters {
    .tagSelectWrapper {
      width: 200px;
    }
  }
}

.filters.explore {
  div.category-icon-wrapper {
    top: 30px !important;
  }

  .categorySelectFormControl .MuiFormLabel-root[data-shrink="false"] {
    transform: translate(0px, 17px) !important;
  }

  .MuiAutocomplete-root {
    margin-top: 15px;
  }

  .tagSelectWrapper {
    width: 200px;
    margin-top: 5px;

    .selected {
      width: 225px;
      margin-left: 30px;
    }

    &.hasTags {
      width: 220px;

      input {
        padding-left: 0px;
      }

      .selected {
        margin-left: 0;
      }
    }
  }

  .inputSelect.tags {
    .icon.tags {
      margin-top: 17px;
      background-image: url("../img/tag.svg");
    }
  }
}

.exploreLayout.hasFetched {
  .loadingWrapper {
    position: fixed;
    left: 258px;
    top: 0;
    bottom: 0;
    max-width: 1080px;

    .panel {
      border-radius: 6px;
      background-color: white;
    }
  }

  padding-bottom: 200px;
}

.layout.in-progress {
  .course-list {
    margin-top: 30px;
  }

  p.statusText {
    margin: 0;
    margin-bottom: -25px;
    font-size: 14px;
    padding: 15px 0;

    &.submitted {
      font-style: italic;
    }
  }

  .button.refresh {
    height: 30px;
    display: flex;
    font-size: 12px;
    color: $blue;
    text-transform: uppercase;
    position: absolute;
    left: 75px;
    margin-top: -36px;

    &:focus {
      background: rgba(0, 0, 0, 0);
    }

    .icon {
      background-image: url("../img/refresh.svg");
      width: 13px;
      height: 13px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 5px;
      position: relative;
      top: -1px;
    }
  }

  .MuiGrid-item {
    &:hover {
      .timestamp-inner {
        font-weight: 600;
        top: -32px !important;
      }

      .actions {
        align-items: center;
        bottom: 4px;
        pointer-events: all;
        opacity: 1;

        .unsave {
          .icon {
            transform: scaleX(-1);
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.course-grid {
  transition: margin-top 0.4s;
  margin-top: 15px;

  &.isExpanded {
    margin-top: 200px;
  }

  .MuiGrid-item {
    max-width: 270px;
    position: relative;
  }

  .actions {
    display: flex;
    position: absolute;
    left: 30px;
    right: 5px;
    margin-bottom: -10px;
    opacity: 0;
    transition: 0.4s;
    justify-content: space-between;

    &.prompt {
      opacity: 1;
      flex-direction: column;
      bottom: 46px !important;

      .flexRow {
        justify-content: space-between;

        button:last-child {
          color: red;
        }
      }

      p {
        padding: 0 6px;
        line-height: 16px;
        font-size: 12px;
      }
    }

    &.removable {
      justify-content: space-between;
    }
  }
}

button.remove {
  width: 35px;
  margin-top: 5px;
  margin-left: 5px;

  img {
    max-width: 14px;
  }
}

.course-library {
  .category-icon {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    transition: 0.4s;
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 100%;
  }

  .star {
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    margin: 0px 1px;
    position: relative;
    top: -1px;

    &:last-child {
      margin-right: 0;
    }
  }

  .course-level {
    display: flex;
    align-items: center;

    .level {
      margin-right: 5px;
      display: inline-block;
    }
  }
}

@mixin collapsedCard {
  h2 {
    margin-top: 0px;
    transform: scale(0.8);
    height: 54px;
  }

  .course-details {
    bottom: 0;
  }

  .course-label {
    padding-left: 20px;
  }

  .category-icon-wrapper {
    width: 20px;
    top: 13px;
    left: 10px;
    border-radius: 100%;

    .category-icon {
      border-width: 2px;
      height: 20px;
      width: 20px;
    }
  }

  @media (max-width: $screen-sm-max) {
    .category-icon-wrapper {
      top: 20px;
    }

    .course-label {
      margin-top: -3px;
    }
  }
}

.card.small {
  animation: fadeInUp 0.3s forwards;
  position: relative;
  opacity: 0;
  width: 250px;
  height: 250px;
  top: 0;
  transition: all 0.3s !important;
  margin: 0;
  padding: 0;

  .category-icon-wrapper,
  .category-icon {
    border-radius: 100%;
  }

  .category-icon {
    border: 4px solid white;
  }

  @media (min-width: 1025px) {
    &.notStarted:hover {
      @include collapsedCard;
    }
  }

  .MuiCardContent-root {
    position: relative;
  }

  header {
    margin-bottom: 0 !important;
  }

  .timestamp {
    overflow: hidden;
    background-color: white;
    position: absolute;
    text-align: left;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    font-size: 12px;
    font-weight: 600;

    &.prompt {
      height: 95px;

      .timestamp-inner {
        opacity: 0;
      }
    }

    .timestamp-inner {
      position: relative;
      transition: 0.4s;
      top: 0;
    }

    p {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &.isCompleted {
    border: 0px;
    box-shadow: none;

    .timestamp {
      border-top: 4px solid $green !important;
    }
  }

  &.inProgress.hasFeedback {
    @include collapsedCard;
  }

  &.isCompleted,
  &.hasFeedback {
    .timestamp {
      background-color: white;
      padding: 10px;
      transition: 0.4s;
      position: absolute;
      right: 0;
      left: 0;
      top: 210px;
      font-size: 12px;
      align-items: center;
      font-weight: 600;

      &.in-progress {
        top: 40%;
      }

      .feedback {
        &:before {
          margin-top: 5px;
          display: inline-block;
          content: "";
          background-image: url("../img/feedback.svg");
          width: 15px;
          height: 15px;
          margin-right: 5px;
          position: relative;
          top: 3px;
          background-size: contain;
        }
      }

      p {
        margin: 10px 0 0;
      }

      strong {
        display: inline-block;
        margin-top: 5px;
        font-weight: 600;
      }

      .flexRow {
        align-items: center;
      }

      .icon.check {
        background-image: url("../img/i-check.svg");
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }

  &.hasFeedback.isCompleted {
    &:hover {
      @include collapsedCard;

      .timestamp {
        top: 40% !important;
      }
    }

    .timestamp {
      font-weight: 600;
      border-top: 4px solid $blue;
    }
  }

  .card-image {
    max-height: none;
    position: absolute;
  }

  h2 {
    margin-top: 90px;
    font-size: 18px;
    color: white;
    text-align: center;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
    transition: 0.4s;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(1);

    @media (max-width: $screen-md-max) {
      font-size: 16px;
      line-height: 18px;
      margin-top: 80px;
    }
  }

  .course-label {
    padding-left: 0;
    transition: 0.4s;
    font-size: 10px;
    color: white;
    font-weight: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .category-icon-wrapper {
    top: 60px;
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: 0.4s;

    @media (max-width: $screen-md-max) {
      transform: scale(0.8);
      margin-top: -10px;
    }
  }

  .course-details {
    background-color: white;
    height: 150px;
    position: absolute;
    top: auto;
    bottom: -150px;
    left: 0;
    right: 0;
    transition: 0.4s;
    padding: 15px;

    p {
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 18px;
    }

    footer {
      display: flex;
      position: absolute;
      right: 10px;
      bottom: 10px;

      .icon.badge {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }

      .category-icon-wrapper.secondary {
        width: 20px;
        top: 0px;
        left: 0px;
        position: relative;
      }
    }
  }
}

.card-image {
  background-size: cover;
  background-position: center center;
  transition: height 0.4s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $blue;

  // Microsoft
  &[data-bg="one note"] {
    background-color: #823264;
  }

  &[data-bg="sway"] {
    background-color: #18986e;
  }

  &[data-bg="teams"] {
    background-color: #302c89;
  }

  &[data-bg="gmail"] {
    background-color: #3fa5ff;
  }

  &[data-bg="digital citizenship"] {
    background-color: #00284b;
  }

  &[data-bg="powerpoint"] {
    background-color: #fa630b;
  }

  &[data-bg="21st century learning"] {
    background-color: #296dca;
  }

  &[data-bg="microsoft forms"] {
    background-color: #178174;
  }

  &[data-bg="accessibility"] {
    background-color: #6296cc;
  }

  &[data-bg="microsoft certification"] {
    background-color: #44cdff;
  }

  &[data-bg="flip"] {
    background-color: #ef17e7;
  }

  &[data-bg="chrome + extensions"] {
    background-color: #44a1be;
  }

  &[data-bg="digital learning"] {
    background-color: #44b391;
  }

  &[data-bg="more google"] {
    background-color: #b8b8b8;
  }

  &[data-bg="curriculum + instruction"] {
    background-color: #a79d5a;
  }

  &[data-bg="samr"] {
    background-color: #af6e7f;
  }

  &[data-bg="new teachers"] {
    background-color: #ecc32e;
  }

  &[data-bg="policies + procedures"] {
    background-color: #da525d;
  }

  &[data-bg="district"] {
    background-color: #6fbad1;
  }

  &[data-bg="other"] {
    background-color: #50b2d0;
  }

  &[data-bg="technology"] {
    background-color: #393939;
  }

  &[data-bg="school"] {
    background-color: #6fb37f;
  }

  &[data-bg="general"] {
    background-color: #ed2061;
  }

  &[data-bg="zoom"] {
    background-color: #3071c9;
  }

  &[data-bg="seesaw"] {
    background-color: #8acfe1;
  }

  &[data-bg="sites"] {
    background-color: #3243a3;
  }

  &[data-bg="meet"] {
    background-color: #057166;
  }

  &[data-bg="google"] {
    background-color: #b8b8b8;
  }

  &[data-bg="mobilemind"] {
    background-color: #8acbe0;
  }

  &[data-bg="docs"] {
    background-color: #4486f4;
  }

  &[data-bg="classroom"] {
    background-color: #38761d;
  }

  &[data-bg="mobilemind hq"] {
    background-color: #6f7071;
  }

  &[data-bg="calendar"] {
    background-color: #2e5ecc;
  }

  &[data-bg="slides"] {
    background-color: #ffb942;
  }

  &[data-bg="drive"] {
    background-color: #555;
  }

  &[data-bg="youtube"] {
    background-color: #b00002;
  }

  &[data-bg="earth + beyond"] {
    background-color: #669ff7;
  }

  &[data-bg="certification info"] {
    background-color: #2f3b7e;
  }

  &[data-bg="sheets"] {
    background-color: #109d58;
  }

  &[data-bg="forms"] {
    background-color: #7248bc;
  }
}

.course-list {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  &.path-courses-list {
    overflow: visible;

    .courseWrapper:first-child .course-list-item {
      border-radius: 12px 12px 0 0;
    }

    .courseWrapper:last-child .course-list-item {
      border-radius: 0 0 12px 12px;
    }

    .courseWrapper.lastCourseDisabled {
      .course-list-item {
        animation: none;
        pointer-events: none;
        opacity: 0.5;
        display: block;
        a {
          cursor: not-allowed;
          pointer-events: none;
          display: block;
        }
      }
    }
  }

  &.mobile-list {
    margin-top: 10px;
    transition: margin-top 0.4s;

    &.isExpanded {
      margin-top: 220px;
    }
  }

  a {
    color: black;
  }
}

.course-list-item {
  background-color: white;
  position: relative;
  border-left-width: 0px;
  border-style: solid;
  transition: 0.4s;
  opacity: 0;
  animation: fadeInUp 0.3s forwards;
  border-bottom: 1px solid darken($light-grey, 10%);
  border-top: none;
  border-right: none;
  border-bottom-color: darken($light-grey, 20%) !important;

  .estimatedTime {
    position: absolute;
    right: 10px;
    top: 0px;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: $blue;
    font-weight: bold;

    .icon {
      position: relative;
      top: -2px;
      margin-right: 5px;
    }
  }

  .excludedText {
    padding: 10px 20px;
    margin: 20px 2px -10px;
    border-radius: 100px;
    background-color: #f0f0f0;
  }

  &.isExcluded,
  &.hasNotReleased {
    .not-released-popover {
      background-color: white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
      border-radius: 40px;
      display: inline-flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      opacity: 0;
      transition: 0.4s;
    }
    &:hover {
      .not-released-popover {
        opacity: 1;
      }
    }
    header,
    h2,
    footer {
      opacity: 0.4;
    }
  }

  .button.unsave {
    @media (max-width: $screen-sm-max) {
      font-size: 10px;

      .icon {
        height: 15px;
        width: 15px;
      }
    }

    .icon {
      transform: scaleX(-1);
      margin-right: 5px !important;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &.completed {
    border-bottom-color: transparent !important;

    @media (max-width: $screen-sm-max) {
      border-bottom: 1px solid #f0f0f0 !important;
      padding: 10px 0 10px 10px;

      a {
        border-bottom: 0px !important;
      }
    }

    @media (min-width: $screen-md-max) {
      &:hover {
        border-left: 5px solid $green;
      }
    }

    .category-label {
      margin-left: 0px !important;
    }
  }

  .button.unsave {
    position: absolute;
    right: 10px;
    bottom: 12px;

    .icon {
      margin-right: 0;
    }
  }

  &.completed a {
    border-left: 0px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    padding: 20px 15px;

    @media (max-width: $screen-sm-max) {
      padding: 10px 0;
    }

    .course-label {
      padding-left: 0;

      @media (max-width: $screen-sm-max) {
        padding-left: 5px;
      }
    }

    h2 {
      margin-top: 5px;
      font-size: 22px;

      @media (max-width: $screen-sm-max) {
        font-size: 14px;
        margin-top: 4px;
      }
    }

    .commentsWrapper {
      header {
        margin-top: -5px;
        font-size: 12px;
        color: #555;
        text-transform: uppercase;
      }

      p {
        margin-top: 5px;
        font-style: italic;
        font-size: 16px;
        font-weight: lighter;
      }
    }

    .category-icon-wrapper {
      position: relative;
      margin-right: 15px;

      .icon.check {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
        border-radius: 100%;
        width: 20px;
        height: 20px;
        background-image: url("../img/i-check.svg");
        position: absolute;
        top: -6px;
        right: -6px;
      }

      .category-icon {
        width: 35px;
        height: 35px;
      }
    }
  }

  footer.prompt {
    padding-left: 15px;
    background-color: #f0f0f0;
    display: flex;
    z-index: 15;
    justify-content: center;
    align-items: center;
    height: 0;
    transition: 0.4s;
    overflow: hidden;

    .flexRow {
      flex: 0;
    }

    p {
      flex: 1;
      padding: 5px;
      margin-top: 0px;
    }

    button {
      margin: 0px 10px;

      &:last-child {
        color: red;
      }
    }

    &.active {
      border-top: 1px solid #f0f0f0;
      height: 50px;
    }
  }

  &:hover {
    @media (min-width: $screen-md-max) {
      background-color: lighten($light-grey, 3%);

      button.remove {
        opacity: 1;
        margin-top: -45px;
      }
    }
  }

  button.remove {
    position: absolute;
    z-index: 10;
    right: 160px;
    margin-top: -35px;
    opacity: 0;
    transition: 0.4s;

    @media (max-width: $screen-md-max) {
      display: none;
    }
  }

  &.isCompleted {
    border-left: 5px solid $green;

    &:hover {
      border-left: 5px solid $green;
    }
  }

  &.in_progress {
    .timestamp.in-progress {
      font-weight: 600;
      position: absolute;
    }
  }

  a,
  .courseListItemDisabled {
    position: relative;
    color: black;
    padding: 20px;
    display: block;

    .feedback {
      &:before {
        display: inline-block;
        content: "";
        background-image: url("../img/feedback.svg");
        width: 15px;
        height: 15px;
        margin-right: 5px;
        position: relative;
        top: 3px;
        background-size: contain;
      }

      margin-top: 10px;
    }

    @media (max-width: $screen-md-max) {
      padding: 15px 20px;
    }

    @media (max-width: $screen-sm-max) {
      padding: 10px;
    }
  }

  p.feedback {
    border-top: 1px solid #f0f0f0;
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -21px;
    position: relative;

    @media (max-width: $screen-md-max) {
      padding: 10px 20px;
      margin-bottom: -12px;
    }
  }

  .resume {
    position: absolute;
    right: 10px;
    margin-top: -45px;

    @media (max-width: $screen-md-max) {
      display: none;
    }
  }

  header {
    display: flex;
    align-items: center;
  }

  h2 {
    margin-top: 10px;

    @media (max-width: $screen-sm-max) {
      font-size: 16px;
    }
  }

  p {
    margin-bottom: 0px;

    @media (max-width: $screen-md-max) {
      margin-top: 8px;
    }

    @media (max-width: $screen-sm-max) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .course-details {
    margin-right: 80px;

    @media (min-width: 1025px) {
      margin-right: 140px;
    }

    @media (max-width: $screen-sm-max) {
      margin-right: 0;
    }
  }

  .timestamp {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 12px;
    color: #555;
    font-weight: 600;

    @media (max-width: $screen-sm-max) {
      font-size: 8px;
      right: 10px;
      top: 10px;
    }
  }

  .course-label {
    text-transform: uppercase;
    font-size: 12px;
    padding-left: 10px;
    display: flex;
    align-items: center;

    @media (max-width: $screen-sm-max) {
      font-size: 10px;

      .category-label {
        margin-right: 5px !important;
      }

      .icon.badge {
        margin-left: 5px;
        width: 15px;
        height: 15px;
      }
    }

    .category-label {
      margin-left: -5px;
      margin-right: 15px;
    }

    .star {
      width: 15px;
      height: 15px;
      position: relative;
      top: -2px;
      background-image: url("../img/star.svg");

      @media (max-width: $screen-sm-max) {
        width: 10px;
        height: 10px;
        top: -1px;
      }
    }

    .badge {
      margin-left: 10px;
      margin-top: -1px;
      width: 20px;
      height: 20px;
    }
  }

  .category-icon-wrapper {
    &.secondary {
      margin-left: 5px;
    }

    .category-icon {
      height: 20px;
      width: 20px;

      @media (max-width: $screen-sm-max) {
        height: 15px;
        width: 15px;
      }
    }
  }
}

.button.fixed {
  position: absolute;
  right: 10px;
  bottom: 15px;
  transition: 0.3s;
  opacity: 0;
}

.button.fixed:hover {
  opacity: 1;
}
