@import "variables";

.page.dashboard {
  @media (max-width: $screen-sm-max) {
    padding: 0 10px;
    margin-top: -50px;
  }
}

@media (max-width: $screen-sm-max) {
  .widgetWrapper {
    width: 100%;
  }
}

.widget.announcement.featuredLearningPath {
  .card-image {
    height: 200px;
  }

  h2 {
    color: black;
  }
}

.widget.announcement.isMandatedTraining {
  padding: 0;
  p p {
    padding: 0 20px;
  }
  footer {
    padding: 10px;
  }
}

.widget.mobile-reminder {
  padding: 0;

  .mobileImg {
    width: 100%;
    border-radius: 10px 10px 0 0;
    margin-top: -10px;
  }

  p {
    padding: 15px;
    margin-top: -10px;
    margin-bottom: 0;
  }

  .flexRow {
    padding: 0 15px;
    justify-content: center;

    a:last-child {
      margin-left: 10px;
    }
  }

  .button.large {
    font-size: 12px;
    padding: 8px 5px;
    width: 100%;
    min-width: 100px;
    margin-left: 0;
    background-color: $light-grey;
    transition: background-color 0.4s;

    .icon {
      margin-right: 5px;
    }

    &:hover {
      background-color: darken($light-grey, 10%);
    }
  }
}

.widget {
  padding: 40px 20px 20px;
  margin: 10px;
  background-color: white;
  border-radius: 10px !important;
  opacity: 0;
  animation: fadeIn 0.2s forwards;
  width: 350px;

  @media (max-width: 1440px) {
    padding: 12px;
    padding-top: 40px;
    margin: 8px;
    width: 285px;

    &.social .flexRow {
      div.logo.circle {
        display: none;
      }
    }
  }

  .timestamp {
    position: absolute;
    right: 20px;
    left: 20px;
    top: 18px;
    font-size: 12px;
    text-align: right;
    opacity: 0.5;
    font-weight: 700;
  }

  @media (max-width: $screen-sm-max) {
    width: 100%;
    margin: 15px 0;
    padding: 10px;
  }

  .widgetIcon {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 17px;
    right: 15px;
    background-repeat: no-repeat;

    &.laptop {
      width: 18px;
      background-image: url("../img/laptop.svg");
    }

    &.calendar {
      background-image: url("../img/event-calendar.svg");
    }

    &.pin {
      background-image: url("../img/pin.svg");
    }
  }

  h2 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    line-height: 18px;
  }

  footer {
    display: flex;
    justify-content: flex-end;

    .MuiButton-label {
      font-size: 10px;
      text-align: left;

      @media (max-width: $screen-sm-max) {
        font-size: 9px !important;
      }
    }
  }

  ul {
    &.course-list-widget {
      margin: 0 -20px;
      padding: 0 20px;
    }

    li {
      a {
        font-weight: bold;
        transition: background-color 0.4s;
        padding: 10px;
        margin: 0 0px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        color: black;
        border-radius: 8px;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }

  .category-icon-wrapper {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 100%;
    overflow: hidden;
    background-size: contain;
    border-radius: 100%;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.34);
    position: relative;
    top: -1px;

    .category-icon {
      width: 30px;
      height: 30px;
      display: block;
      border: 3px solid white;
    }
  }

  .flexRow {
    margin: 10px 0;
  }

  .profilePicture {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;

    @media (max-width: $screen-sm-max) {
      width: 75px;
      height: 75px;
    }
  }
}

.widgetLabel {
  background-color: $blue;
  color: white;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 20px;
  text-transform: uppercase;
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  position: absolute !important;
  left: 15px;
  top: 15px;
  right: auto;
  z-index: 2;

  &.important {
    background-color: #fd4755;
  }

  &.success {
    color: black;
    display: inline-block;
    background-color: $green;
  }
}

.widget.badgeEarned {
  a {
    color: black;
  }

  .imageWrapper {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin-right: 10px;
    background-color: #fff;
  }

  .badgeImage {
    border-radius: 100%;
    overflow: hidden;
    width: 100px;
    height: 100px;
    display: block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  }

  .icon.check {
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    right: 2px;
    top: 2px;
    width: 30px;
    height: 30px;
    background-image: url("../img/i-check.svg");
  }

  .badgeInfo {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-direction: column;

    button {
      margin-top: 5px;
    }
  }
}

.page.dashboard {
  padding-top: 0px;
  transition: padding-top 0.6s;

  &.loading {
    padding-top: 20px;
  }

  .widget.mandated-training {
    .popover {
      border-radius: 12px;
      font-weight: bold;
      background-color: white;
      font-size: 12px;
      padding: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
      position: absolute;
      min-width: 150px;
      right: 35px;
      top: 9px;
      transition: 0.4s;
      opacity: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      pointer-events: none;
    }
    .progress-circle {
      position: relative;
      &:hover {
        .popover {
          opacity: 1;
          right: 40px;
        }
      }
    }

    .icon.mandated-training {
      background-image: url("../img/mandatory.svg");
    }
    ul {
      margin-top: 10px;
      button {
        flex: 1;
        display: flex;
        align-items: center;
        min-width: 100%;
        text-align: left;
        max-width: 100%;
        &:hover {
          background-color: lighten($light-grey, 1%) !important;
        }
      }
    }
    .widgetLabel {
      background-color: #e49542;
    }
    strong {
      margin-bottom: 5px;
    }

    [data-test-id="CircularProgressbarWithChildren"] {
      height: 45px;
      width: 45px;
    }
    .CircularProgressbar-trail {
      stroke-width: 6px;
    }
    .CircularProgressbar-path {
      stroke: $green;
    }
    .progress-text {
      font-weight: bold;
      font-size: 12px;
      position: relative;
      left: -2px;
      span {
        font-size: 8px;
        position: absolute;
        top: 3px;
      }
    }
  }

  .widget.userGoal {
    .topContent {
      height: 125px;

      .widgetLabel {
        top: 0;
        left: 0;
      }
    }
  }
}

.widget.learningPath {
  z-index: 0;

  a {
    color: black;
  }

  .pathStatus {
    font-size: 10px;
    position: relative;
    z-index: 2;
    margin-left: -10px;
    margin-right: 10px;
    text-transform: uppercase;
  }

  .widgetLabel.success {
    left: 0;
    top: 0;
    left: auto;
  }

  .progressBarWrapper {
    background-color: white;
  }

  .topContent {
    position: relative;
    padding-bottom: 15px;
  }

  h2 {
    margin: 10px 80px 0 0;
  }

  .bottomContent {
    ul.categoryList {
      margin: 15px 0 0 0;
      display: flex;
      flex-wrap: wrap;

      .category-icon-wrapper {
        margin: 0 5px 5px 0;
      }
    }
  }

  p {
    margin: 15px 0;
  }

  .pathImage {
    position: absolute;
    top: -20px;
    right: -20px;
    left: -20px;
    bottom: 0px;
    background-size: cover;
    background-position: center;
  }

  h2 {
    margin: 10px 0 10px;
  }
  &.widget-dashboard {
    h2 {
      position: relative;
      z-index: 4;
      margin: 10px 0px 0px;
      color: white;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
    }
    .pathStatus {
      color: white;
    }
    .topContent {
      padding-top: 35px;
    }
    .pathImage {
      &:after {
        display: block;
        content: "";
        background: linear-gradient(
          0deg,
          rgb(0, 0, 0) 7%,
          rgba(255, 255, 255, 0) 100%
        );
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        top: 10px;
        bottom: 0;
        border-radius: 15px;
      }
    }
  }
}

.widget.social {
  .widgetLabel {
    background-color: #555;
  }

  .logo.circle {
    background-image: url("../img/logo-circle.png");
    width: 80px;
    height: 80px;
    background-size: contain;
    margin-right: 20px;
  }

  .links {
    display: flex;
    flex-direction: column;
  }

  .button {
    .icon {
      width: 20px;
      height: 20px;
      position: relative;
      top: -1px;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}

.widget.achievements,
.widget.quickLinks {
  font-size: 14px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  position: relative;
  padding: 0;

  .widgetLabel {
    position: absolute !important;
    top: 15px;
  }

  a {
    font-weight: bold;
  }

  header.flexRow {
    align-items: center;

    h2 {
      text-transform: capitalize;
      margin: 0 0 0 10px;
      position: absolute;
      left: 100px;
      right: 10px;

      @media (max-width: 1440px) {
        font-size: 14px;
      }

      span {
        @media (max-width: 1440px) {
          font-size: 12px;
        }

        opacity: 0.5;
        font-size: 14px;
        display: block;
      }
    }
  }

  .coverPhoto {
    height: 140px;
    background-position: center;
    background-size: cover;
    margin-bottom: 25px;
  }

  .text {
    cursor: pointer;
    height: 140px;
    position: absolute;
    background-size: 25px;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45);

    opacity: 0;
    padding-top: 80px;

    .icon.photo {
      background-image: url("../img/photo.svg");
      width: 20px;
      height: 20px;
      margin-right: 5px;

      &.profile {
        border-radius: 80px;
        top: 107px;
        height: 80px;
        left: 17px;
        width: 80px;
      }
    }

    &:hover {
      padding-top: 0px;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      background-position: center center;
    }
  }

  .icon.photo.profile {
    cursor: pointer;
    position: absolute;
    background-size: 25px;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s;
    background-image: url("../img/photo.svg");
    background-position: center top 50px;
    border-radius: 90px;
    width: 82px;
    height: 82px;
    left: 15px;
    top: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.4s;

    &:hover {
      background-position: center;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }

  .profilePicture {
    position: absolute;
    top: 100px;
    left: 10px;
    width: 82px;
    height: 82px;
    border: 5px solid white;
    box-sizing: content-box;
    background-position: center;
  }

  .userInitials {
    border-radius: 100px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 100px;
    left: 10px;
    width: 80px;
    height: 80px;
    border: 7px solid white;
    box-sizing: content-box;
    background-position: center;

    div > div {
      box-shadow: none;
    }

    .userInitials-initials {
      font-size: 36px !important;
    }
  }

  em {
    margin-top: 10px;
    display: block;
    font-size: 12px;
    line-height: 14px;
  }

  footer {
    padding: 10px;
  }

  .stats {
    position: relative;
    padding: 20px 20px 10px 20px;

    @media (max-width: 1440px) {
      padding: 20px 10px 10px 10px;
    }

    .flexRow {
      align-items: center;
      margin: -5px 0 0;
      padding: 5px;
      border-radius: 6px;
    }

    @media (max-width: $screen-sm-max) {
      font-size: 12px;

      span {
        font-size: 24px;
      }
    }

    span {
      flex: 1;
      text-align: right;
      color: $blue;
      font-weight: 700;
      font-size: 20px;
      display: block;
      margin: 0 0 0 5px;
    }
  }
}

.widget.quickLinks {
  padding: 40px 15px 0;
}

.widget.feedback {
  a {
    color: black;
  }

  .category-icon-wrapper {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }

  .widgetIcon {
    background-image: url("../img/feedback.svg");
  }

  .flexRow {
    align-items: center;
  }

  .content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
      margin: 0px;
      margin-left: 10px;
      max-width: 100%;

      border-radius: 8px;
      padding: 10px;
      flex: 1;
      background-color: #d5f9ea;
    }

    em {
      font-size: 14px;
    }
  }

  .category-icon {
    width: 50px;
    height: 50px;
  }
}

.widget.announcement {
  .videoWrapper {
    margin: 15px -20px 0;
  }

  .widgetLabel {
    right: auto;
    left: 15px;
  }

  .icon.tip {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    background-size: contain;
    background-image: url("../img/tip.svg");
    background-position: center;
    background-repeat: no-repeat;
  }

  .announcementImage {
    margin: 20px 0;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
    &.trainingImage {
      padding: 20px;
      overflow: hidden;
      width: 100%;
      position: relative;
      border-radius: 12px 12px 0 0;
      &:before {
        display: block;
        content: "";
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 8%,
          rgba(255, 255, 255, 0) 100%
        );
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        top: 0;
        bottom: -25px;
      }
      .icon.mandated {
        width: 25px;
        height: 25px;
        background-image: url("../img/mandatory.svg");
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.42);
        border-radius: 25px;
        z-index: 3;
        background-size: 19px;
      }
      h2 {
        text-align: center;
        z-index: 3;
        margin-bottom: -20px;
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
        color: white;
        font-size: 20px;
      }
    }
  }

  .card-image {
    position: relative;
    padding: 10px 20px;
    margin: 15px -20px;

    .icon.star {
      width: 10px;
      height: 10px;
      margin-right: 3px;
    }

    header {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }

    .course-label {
      font-size: 10px;
      color: white;
      display: flex;
      font-weight: normal;
      text-transform: uppercase;
      justify-content: space-between;
      flex: 1;
    }

    h2 {
      font-weight: 400;
      font-size: 18px;
      color: white;
      text-align: center;
      margin: 15px 0 25px;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
      line-height: 22px;
    }
  }
}

.widget.support {
  .widgetLabel {
    background-color: #555;
  }
  button {
    text-align: left;
    line-height: 14px;
  }
  .icon {
    &.terms {
      background-image: url("../img/terms.svg");
    }

    &.book {
      background-image: url("../img/book.svg");
    }

    &.ticket {
      background-image: url("../img/ticket.svg");
    }

    &.idea {
      width: 25px;
      height: 25px;
      background-image: url("../img/idea.svg");
    }

    &.support {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../img/support.svg");
    }
  }

  .button {
    .MuiButton-label {
      justify-content: flex-start;
      display: flex;
      text-align: left;
      line-height: 14px;
    }

    .icon {
      position: relative;
      top: -2px;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.mobile-reminder {
  padding: 10px;
}

.widget.sync {
  .tabs {
    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-root {
      padding: 0 px !important;
    }

    .MuiButtonBase-root {
      padding: 0;
      font-family: "Nunito" !important;
      min-height: 30px;
      min-width: 0px !important;
      flex: 1;
      font-size: 12px;
      margin-top: 10px !important;
    }

    .MuiTabs-flexContainer {
      justify-content: center;

      .MuiTab-root {
        border-radius: 25px 0 0 25px !important;
        background-color: $light-grey;
        color: black;
        transition: 0.3s;

        &:last-child {
          border-radius: 0 25px 25px 0 !important;
        }
      }

      .Mui-selected {
        background-color: $green;
        color: black;
      }

      .MuiTab-wrapper {
        font-weight: 700;
      }
    }
  }

  li {
    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 315px;

      @media (max-width: 1440px) {
        width: 260px !important;
      }
    }

    div {
      flex: 1;
    }

    strong {
      display: block;
      opacity: 0.5;
      font-size: 12px;
    }
  }
}
