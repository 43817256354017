@import "./variables";

#pre-approval-form-responses {
  .MuiOutlinedInput-notchedOutline {
    display: block;
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: inherit;
  }
  textarea {
    overflow: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .date-question {
    input {
      padding-left: 15px;
    }
    .MuiInputAdornment-root {
      position: relative;
      left: -15px;
    }
  }
}

.buddy-approval-container {
  .flexRow {
    overflow: hidden;
    align-items: center;
    justify-content: center;
    width: auto;
  }
  .buddy-message.inline svg {
    top: 20px;
  }
}

.approval-stepper {
  .icon.check {
    background-image: url("../img/check-black-simple.svg");
  }
  .icon.denied {
    background-image: url("../img/x-white.svg");
  }
  .MuiStepConnector-root {
    min-width: 75px;
    max-width: 75px;
  }
}

.approval-notes {
  position: relative;
  .triangle {
    color: rgb(189, 245, 222);
    border-bottom-color: rgb(189, 245, 222);
    background-color: transparent;
    position: absolute;
    content: "";
    display: block;
    top: 10px;
    left: -9px;
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    border-right: 10px solid;
  }
}

.pd-attachment-list {
  .MuiChip-deleteIcon {
    opacity: 0.35;
    cursor: pointer;
    transition: 0.3s;
    width: 25px;

    &:hover {
      opacity: 0.7;
    }
  }

  li {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 15px 0;
    border-top: 1px solid $light-grey;

    &:last-child {
      border-bottom: 1px solid $light-grey;
    }

    .icon,
    img {
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }
}

.MuiAccordion-root {
  margin-bottom: 8px;
  border-radius: 12px !important;
  overflow: hidden;
}

#external-pd-request-modal {
  .helperText .highlight {
    background-color: white;
    transition: 0.3s;
    border-radius: 10px;

    &.warning {
      padding: 0 3px;
      margin-left: 5px;
      color: white;
      background-color: #ff676f;
    }
  }

  #approval-form-select {
    max-height: 350px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .MuiSelect-select {
      padding-left: 0;
    }
    .formSelectItem {
      cursor: pointer;

      .icon.form {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        background-image: url("../img/pre-approval-form.svg");
      }

      &:hover:not(.active) .MuiCard-root {
        transition: background-color 0.3s;
        background-color: $light-grey;
      }
    }
  }
  .capsuleWrapper {
    .capsule {
      transition: 0.3s;
    }

    &.warning {
      .capsule {
        background-color: #ff676f;
      }
    }
  }

  .MuiDialog-paperWidthSm {
    max-height: 90vh;
    max-width: 650px;
  }

  .loadingWrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 4;
    margin-top: -50px;
  }

  &.preview-step {
    .MuiDialog-paperWidthSm {
      max-width: 800px;
    }
  }

  .modal-body {
    overflow-y: auto;
    padding: 0 20px;
    margin: 0;
    &::-webkit-scrollbar {
      display: none;
    }

    max-height: 60vh;

    .preview {
      pointer-events: none;

      svg {
        width: 20px;
      }

      .MuiAccordionSummary-root {
        margin-bottom: -35px;
      }

      .MuiAccordionDetails-root {
        border-width: 0px;
      }

      .MuiPaper-root {
        border-radius: 8px;
        box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .dialog {
    padding: 25px;
    padding-bottom: 125px;
    min-width: 500px;
    max-width: 800px;
    opacity: 1;
    transition: 0.3s;

    &.isSaving {
      opacity: 0.2;
      pointer-events: none;
    }

    &.isLastStep {
      min-width: 700px;

      .request-list-item {
        .request-content .column:first-child {
          margin-right: 5px;
        }

        .MuiAccordionSummary-root {
          border-bottom: 1px solid $light-grey;
          border-radius: 8px 8px 0 0;
        }
      }
    }

    &.recommended {
      padding-top: 120px;

      .xpd-comments {
        .MuiFormControl-root {
          margin-top: 10px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 12px;
        }
      }

      h2 {
        .header-title {
          position: relative;
          z-index: 3;
        }

        .gradient {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
        }

        .icon.close {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 25px;
          height: 25px;
          z-index: 4;

          background-image: url("../img/close-white.svg");
        }
      }

      .dateTime {
        font-size: 1rem;
        font-weight: normal;
        line-height: 16px;

        .flexRow {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .dateBlock {
          margin-top: 15px;

          &:last-child {
            margin-left: 35px;
            text-align: right;
          }

          &:first-child {
            margin-left: 0;
            text-align: left;
          }

          strong {
            display: block;
            margin-top: 3px;
          }
        }
      }

      h2 {
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 35px;
        color: white;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        justify-content: center;
      }
    }

    .helperText {
      opacity: 0.7;
      font-size: 12px;
      display: block;
      line-height: 16px;
    }

    .buddy-container {
      margin: 50px auto 20px auto;
      overflow: visible;

      .buddy-image {
        position: relative;
      }

      .buddy-message {
        padding: 30px 20px 20px;
      }
    }

    .inputSelect {
      margin-top: 14px;

      label[data-shrink="true"] {
        top: -5px;
      }
    }

    .uploadImageWrapper {
      cursor: pointer;
      position: relative;
      transition: 0.4s;
      opacity: 1;
      background-color: $light-grey;
      border-radius: 8px;
      width: 495px;
      height: 95px;

      &.isDragging {
        transform: scale(1.05);
        opacity: 0.5;
      }

      &.isUploading {
        transform: scale(1.05);
      }

      &:hover {
        opacity: 0.5;
      }

      .instructions {
        font-size: 12px;
        padding: 20px;
      }

      strong {
        display: block;
      }

      .fileUpload {
        position: relative;
        transform: scale(1);
        transition: 0.4s;
        width: 495px;
        height: 95px;

        &.isRejected {
          animation-timing-function: ease-in-out;
          animation: headShake forwards 1.5s;
        }

        input {
          width: 495px;
          height: 95px;
          cursor: pointer;
          z-index: 5;
          opacity: 0;
          position: absolute;
        }
      }
    }

    .timeBlockList {
      margin-bottom: 30px;
      .MuiFormControl-root {
        position: relative;
        top: -7px;
      }
      &.categories-list {
        label[data-shrink="true"] {
          left: -15px;
        }
      }

      .category-icon-wrapper {
        left: -15px;
      }

      .MuiAutocomplete-inputRoot {
        margin-left: -15px;
      }

      li {
        align-items: flex-start;

        &:first-child {
          .MuiChip-deleteIcon {
            top: 30px;
          }
        }

        .MuiChip-deleteIcon {
          opacity: 0.3;
          transition: opacity 0.3s;
          position: relative;
          width: 30px;
          height: 25px;
          cursor: pointer;
          top: 15px;
          left: 6px;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .timeBlocks {
      max-width: 65px;
      margin-right: 20px;

      input {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .star-button {
      padding: 6px 6px;
      width: 20px;
      max-width: 20px;
      min-width: 45px;
      border-radius: 100px;
      margin-left: 2px;
      background-color: transparent;
      margin-top: 10px;
    }

    section {
      margin: 20px 0;
    }

    label[data-shrink="true"] {
      top: 0px;
      left: -15px;
    }

    header {
      font-weight: bold;
      opacity: 0.75;
      text-transform: uppercase;
      font-size: 14px;
    }

    input {
      border: none;
    }

    .MuiFormControl-root.time {
      width: 100px;
      flex-shrink: 0;

      input {
        text-transform: uppercase;
      }

      .MuiInputBase-root:before {
        opacity: 0;
      }
    }

    textarea {
      max-height: 150px;
    }

    .MuiFormControl-root {
      width: 100%;
    }
    .request-description {
      fieldset {
        display: block;
      }
    }
    .MuiInputBase-root {
      width: 100%;

      input {
        margin: 0;
      }
    }

    footer {
      flex-direction: column;
      background-color: white;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 20px;

      .flexRow {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
      }

      .button {
        margin-left: 0px;

        &.secondary {
          background-color: $light-grey;
        }
      }
    }
  }

  h2 {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px 0;

    .close {
      background-image: url("../img/x.svg");
      width: 15px;
      height: 15px;
      cursor: pointer;
      transition: 0.2s;
      opacity: 0.5;
      text-align: right;
      position: relative;
      top: 2px;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.page.recommended-events {
  max-width: 1240px;

  h2 {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0;
    text-transform: capitalize;

    .loadingWrapper {
      margin-bottom: -10px;
    }
  }
}

.recommended-grid {
  margin: 12px;
}

.card.recommended-event {
  overflow: visible;
  margin: 10px 20px 20px 0;
  cursor: pointer;
  min-width: 250px;
  position: relative;
  top: 0;
  transition: 0.3s;

  &.hasRequest {
    .request-status {
      .status-label {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 5px 8px;
        background-color: white;
        border-radius: 30px;
        font-size: 10px;
        text-transform: uppercase;
        margin: 10px;
        box-shadow: 0px 2px 1px 0px rgb(0 0 0 / 30%),
          0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 8%);

        &.accepted {
          background-color: $green;
        }

        .icon {
          margin-right: 5px;
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &:hover {
    top: -5px;
  }

  .MuiCardContent-root {
    padding: 0;
  }

  .image-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.5s;
    height: 90px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }

  .external-backdrop {
    width: 100%;
    height: 90px;
    position: absolute;
    z-index: 1;
    background-position: center top -45px;
    background-repeat: no-repeat;
    background-size: 220px;
    opacity: 0.25;
    background-image: url("../img/external-pd-white.svg");
  }

  .external-pd {
    display: block;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    height: 50px;
    background-image: url("../img/external-pd-color.svg");
  }

  .inner {
    padding: 10px 20px 20px;
    width: 275px;
    text-align: center;

    header {
      line-height: 18px;

      strong {
        margin-bottom: 5px;
      }
    }
  }

  .capsule-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    transform: scale(0.8);
  }
}

.request-list-item {
  margin: 3px 0;
  border-radius: 8px;

  .MuiTabs-root {
    .MuiTab-root {
      padding-inline: 15px;
    }
  }

  .MuiPaper-root {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%),
      0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  }

  .icon.declined {
    background-image: url("../img/declined-red.svg");
  }

  header {
    font-weight: bold;
    opacity: 0.75;
    text-transform: uppercase;
    font-size: 14px;
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding: 0;
    footer {
      margin: 25px 25px 0 0;
    }
  }

  .request-content {
    display: flex;
    flex-wrap: wrap;

    .column {
      flex: 1;

      .category-icon {
        border-radius: 20px;
        width: 25px;
        height: 25px;
        border: 3px solid white;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%),
          0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
        display: inline-block;
        margin-right: 5px;
        background-size: contain;
        position: relative;
        top: -1px;
        box-sizing: border-box;
      }

      .pd-attachment-list {
        li {
          padding: 8px 0;
          border: none !important;
          .icon.image {
            background-size: cover;
          }
        }
      }

      &:first-child {
        margin-right: 25px;
      }

      section {
        margin-bottom: 20px;
      }

      .flexRow svg {
        margin-right: 10px;
        margin-top: 5px;
      }
    }

    p {
      margin: 5px 0 0;
    }
  }

  .prompt {
    animation: fadeInUp 1s;
  }

  footer {
    .button {
      margin-left: 25px;

      &.secondary {
        background-color: $light-grey;
      }
    }
  }

  .icon.resubmit {
    background-image: url("../img/resubmit.svg");
  }

  .icon.delete {
    background-image: url("../img/trash-black.svg");
  }

  .MuiAccordionSummary-root {
    transition: 0.3s;
  }

  [aria-expanded="true"],
  .MuiAccordionSummary-root:focus,
  .MuiAccordionSummary-root:active {
    background-color: white;
    border-radius: 8px;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
    margin: 5px 0 0 !important;
  }

  .flexRow {
    align-items: center;
  }

  .icon.external-pd.color {
    background-image: url("../img/external-pd-color.svg");
    width: 40px;
    height: 40px;
  }

  .capsule {
    margin-right: 35px;
    margin-left: 35px;
  }

  .icon.declined,
  .raised-hand {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .timestamp {
    position: relative;
    top: -1px;
    font-size: 14px;
    opacity: 0.7;
  }
}

div.pending-list {
  .MuiAccordionSummary-root {
    width: 100%;
    padding: 0 20px;
  }
}

.MuiPickersLayout-contentWrapper {
  .MuiDateCalendar-root div {
    //  font-family: "Nunito", sans-serif;
  }

  button {
    //  font-family: "Nunito", sans-serif !important;
  }

  .MuiMultiSectionDigitalClock-root {
    div,
    li {
      //  font-family: "Nunito", sans-serif !important;
      border-radius: 6px;
    }
  }
}
