@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");

.page.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  background-color: $blue;
  text-align: center;
  background-size: cover;
  background-position: right bottom;

  .background {
    mix-blend-mode: hard-light;
    opacity: 0.85;
  }

  .background,
  .backgroundWallpaper {
    position: fixed;
    top: 0;
    transition: 1s;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .backgroundWallpaper {
    filter: grayscale(1);
    background-size: cover;
    background-position: center;
  }

  img {
    display: block;
    width: 250px;
    margin: 35px auto;
  }

  .loginWrapper {
    max-width: 90vw;
    position: relative;
    z-index: 1;
    animation: fadeInUp 1s;
    margin-top: -50px;
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    .MuiButton-label {
      text-transform: none;
      font-size: 14px;
      color: #444;

      .icon {
        width: 18px;
        margin-right: 12px;
        height: 18px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    button {
      text-transform: none;
      font-weight: 300;
      margin: 20px auto;
      display: block;
      background-image: url("../img/i-google.svg");
      background-repeat: no-repeat;
      background-position: left 10px top 45%;
      background-size: 20px;
      padding-left: 40px;
      padding-right: 10px;
    }

    @media (max-width: $screen-sm-max) {
      img {
        padding: 20px;
        margin-bottom: -20px;
      }

      .flexRow {
        padding: 10px;
        width: auto;
        margin-bottom: 20px;
      }
    }
  }

  .flexRow {
    justify-content: center;
    padding: 20px;

    &:first-child {
      padding: 20px 40px 10px;

      @media (max-width: 667px) {
        padding: 20px;
      }
    }
  }

  .button.login {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    padding: 0 8px;
    border-radius: 4px;
    height: 40px;
    background-color: white;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    font-family: "Roboto";
    text-transform: none;

    &.microsoft {
      padding: 0 12px;
      height: 41px;
      font-family: "Segoe UI Regular", sans-serif;
      font-weight: 600;
      margin-top: -20px;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid #8c8c8c;

      .MuiButton-label {
        font-size: 14px !important;
        color: #5e5e5e !important;
      }

      .icon.microsoft {
        margin-right: 12px;
      }
    }
  }
}
