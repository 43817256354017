@import "variables";

html {
  scroll-behavior: smooth;
}

.stickyHeader {
  position: sticky;
  top: 50px;
  right: 0;
  margin: 0 -20px;
  padding: 35px 20px 15px;
  left: 280px;
  z-index: 19;
  background-color: #f0f0f0;

  .button.save,
  .button.unsave {
    position: absolute;
    right: 0;
    top: 40px;
  }

  .button.large {
    position: absolute;
    right: 0;
    top: 0;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.disclaimer {
  font-style: italic;
  margin-top: 10px;
  display: block;
  line-height: 14px;
  font-size: 12px;
  transition: 0.3s;
}

#tour-sidebar-filters {
  .entity-meta.course {
    span.category-icon.large {
      width: 60px !important;
      height: 60px !important;
      background-color: white !important;
      border: 6px solid white !important;
    }
  }
}

.entity-meta {
  width: 250px;
  margin-left: -20px;

  &.course {
    .backButton {
      justify-content: flex-start;
      align-items: center;
      width: 250px;
      padding: 20px;
      margin-top: -20px;
      border-radius: 0;
      transition: 0.4s;
      font-size: 18px;
      text-transform: capitalize;
      font-weight: normal;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        transition: 0.3s;

        .icon {
          flex-shrink: 0;
          display: block;
          float: none;
          height: 20px;
          width: 20px;
          margin: 0 5px 0 0;
        }
      }
    }

    .category-icon.large {
      width: 60px;
      height: 60px;
      display: block;
      position: absolute;
      top: 86px;
      right: 12px;
      position: absolute;
      border-radius: 100%;
      border-width: 6px;
      box-sizing: content-box;
    }

    .course-level {
      .icon {
        transition: 0.4s;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        margin-right: 3px;
        background-image: url("../img/star.svg");
      }
    }

    .category-icon-wrapper {
      display: flex;
      text-transform: capitalize;
      font-size: 14px;
      margin: 5px 0;
      align-items: center;

      .category-icon {
        margin-right: 5px;
        border-radius: 100%;
        box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.12),
          0 2px 3px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 20px;
        display: block;
      }
    }

    header {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 10px;
      opacity: 0.75;
    }
  }

  h3 {
    padding: 25px 20px;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
  }

  .buddy-container {
    overflow: visible;
    margin-top: 20px;
    margin-bottom: -5px;
    animation: bounceIn 1s forwards;
  }

  .buddy-message {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    width: 100%;

    p {
      line-height: 16px;
    }
  }

  section {
    padding: 20px 15px;
    border-bottom: 1px solid darken($light-grey, 8%);
    margin-bottom: 0px !important;

    &.back-container {
      padding: 0px;
    }
  }
}

.actions {
  height: 58px;
  transition: 0.3s;
  opacity: 1;
  display: flex;
  align-items: center;

  &.fade {
    transform: scale(0.5);
    margin: 0;
    height: 0px;
    opacity: 0;
    pointer-events: none;
  }
}

.content-section {
  margin-top: 30px;
  ul {
    list-style-type: disc;
    padding-left: 25px;
    li {
      list-style-type: disc;
    }
  }

  img {
    max-width: 100%;
  }

  .button.small {
    background-color: rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  h3 {
    margin: 0 0 0 0;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;

    .icon {
      margin-right: 8px;
      width: 30px;
      height: 30px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      &.idea {
        background-image: url("../img/idea-box.svg");
      }

      &.pencil-box {
        background-image: url("../img/pencil-box.svg");
      }

      &.crates {
        background-image: url("../img/crates.svg");
      }

      &.help {
        background-image: url("../img/customer-service.svg");
      }
    }
  }
}

.course-single {
  margin-top: -25px;
  padding-left: 220px;
  margin-left: -220px;
  max-width: 1220px;
  padding-bottom: 25px;
  position: relative;
  opacity: 1;
  transition: 0.8s;

  .supportEmail {
    .button.small {
      color: black !important;
    }
  }

  .videoWrapper {
    margin-top: 20px;
    iframe {
      border: none;
    }
  }

  a {
    font-weight: bold;
    color: darken($blue, 15%);
  }

  section {
    .attachment {
      display: inline-flex;
      align-items: center;
      margin: 10px 20px 0 -10px;
      cursor: pointer;
      transition: 0.3s;
      padding: 10px 15px;
      border-radius: 90px;
      max-width: 450px;
      font-weight: bold;

      &:first-child {
        margin-left: 0px !important;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
      }

      .icon {
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 10px;
      }
    }
  }

  audio {
    cursor: pointer;
    border-radius: 50px;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  .button.small:focus {
    background-color: #f0f0f0;
  }

  .button.large {
    margin-top: 25px;
  }

  .button.unsave {
    .icon {
      transform: scaleX(-1);
    }
  }

  .assessmentStatus {
    margin-top: 20px;
    background-color: white;
    border-radius: 8px;
    min-height: 0px;

    .status {
      margin: 0;
    }

    &.new {
      animation: fadeInUp 0.4s;
    }

    .flexRow {
      padding: 5px 15px;
      justify-content: space-between;
    }

    .icon {
      width: 20px;
      height: 20px;
      background-size: contain;
      margin-right: 10px;
      padding: 0px;
      background-repeat: no-repeat;

      &.started {
        background-image: url("../img/laptop.svg");
      }

      &.submitted {
        background-image: url("../img/inbox.svg");
      }

      &.completed {
        background-image: url("../img/i-check.svg");
      }
    }

    .submissionContent {
      display: flex;
      flex-direction: column;
    }

    .submissionInfo {
      font-weight: 600;
      display: block;
      margin-top: 20px;
      font-style: normal;
      font-size: 12px;
      margin-top: 10px;
      position: relative;

      &.image {
        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }

      &:after {
        content: "";
        background-color: rgba(0, 0, 0, 0.4);
        background-size: 30px;
        border-radius: 4px;
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        background-image: url("../img/preview.svg");
        transition: 0.3s;
        opacity: 0;
        pointer-events: none;
      }
    }

    #revisionHistory {
      margin-top: 35px;

      .feedbackContent {
        padding: 0;
        display: flex;
        align-items: flex-start;

        .quote {
          margin-left: 10px;
          border-radius: 8px;
          padding: 10px;
          flex: 1;
          background-color: #d5f9ea;
        }

        p {
          margin: 0;
        }
      }

      footer {
        margin-bottom: -5px;
        border-top: 1px solid #f0f0f0;
        display: flex;
        justify-content: center;
        padding: 5px 0;

        button {
          border-radius: 0px 0px 5px 5px;
          margin: 0 auto;

          &:focus {
            background-color: white !important;
          }

          &:hover {
            background-color: $light-grey !important;
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;
        overflow: hidden;
        transition: 0.4s;
      }

      ul li {
        font-style: italic;
        padding: 15px 15px;
        font-size: 12px;
        line-height: 18px;
        position: relative;
        border-top: 1px solid #f0f0f0;

        &:first-child {
          border-top: none;
        }

        .imageWrapper {
          position: relative;
          cursor: pointer;
          display: block;
        }

        .submissionImage {
          height: 200px;
          width: 300px;
          cursor: pointer;
          border-radius: 4px;
          background-size: cover;
          background-position: top left;
          transition: 0.3s;
          position: relative;
          opacity: 1;
        }

        .flexColumn {
          display: flex;
          flex-direction: column;
        }

        .timestamp {
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          width: 150px;
          flex-shrink: 0;
          font-style: normal;

          .date {
            opacity: 0.6;
          }

          strong {
            margin-bottom: -18px;
            display: block;
            line-height: 12px;
          }
        }

        .feedback,
        .submission {
          padding: 10px 0;
          margin: 0;
        }

        .icon.feedback {
          display: block;
          width: 18px;
          margin-right: 5px;
          height: 18px;
          background-image: url("../img/feedback.svg");
        }

        .submission,
        .feedback {
          display: flex;
        }
      }
    }
  }

  h1 {
    font-size: 28px;
    text-align: left;
    font-weight: 600;
    padding-right: 210px;
    display: flex;
    align-items: center;

    .category-icon-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 10px;
      position: relative;
      top: -2px;

      .category-icon {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        border: 4px solid white;
        border-radius: 100%;
        display: block;
        width: 34px;
        height: 34px;
      }
    }

    @media (max-width: $screen-sm-max) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  p {
    overflow: hidden;

    @media (max-width: $screen-sm-max) {
      max-width: 100%;
    }
  }
}

.learning-path-single {
  max-width: 920px;
  margin-left: 30px;

  .fade {
    opacity: 0;
    pointer-events: none;
  }

  .description {
    margin-top: 35px;
    line-height: 20px;

    p {
      margin: 0;
    }
  }

  #sidebar {
    overflow-x: visible;
    width: 280px;
    z-index: 8;
  }

  h2 {
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 22px;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
  }

  .statusBar {
    .icon.check {
      background-image: url("../img/i-check.svg");
      width: 20px;
      height: 20px;
    }

    strong {
      flex: 1;
      margin: 0 0 0 10px;
    }

    display: flex;
    justify-content: space-between;
    background: white;
    padding: 15px;
    border-radius: 6px;
    align-items: center;
  }

  .prerequisiteMessage {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px;
    margin: 0;
    display: inline-block;
    border-radius: 14px;
    color: white;
    display: inline-flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      background-image: url("../img/lock.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
  }

  section {
    margin-bottom: 35px;
  }

  .video-content {
    .button {
      margin-right: 10px;
    }
  }

  .course-details {
    margin-right: 175px;
  }

  .pathType {
    background-color: $green;
    position: relative;
    top: -10px;
    padding: 3px 6px;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 10px;
  }

  .course-list-item {
    @media (max-width: $screen-sm-max) {
      .unsave {
        display: none;
      }
    }

    a,
    .courseListItemDisabled {
      @media (max-width: $screen-sm-max) {
        padding-bottom: 25px;

        .timestamp {
          top: auto;
          bottom: 4px;
          left: 10px;
        }

        .course-details {
          margin: 0;

          p {
            line-height: 14px;
          }
        }
      }

      transition: 0.3s;

      header,
      p,
      footer,
      h2,
      .button {
        filter: blur(0px);
        pointer-events: all;
      }

      p.feedback {
        padding-right: 75px;
      }
    }
  }

  .section-nextUp {
    animation: fadeInUp forwards 0.3s;
  }

  .course-list {
    margin-left: 60px;

    &.disabled {
      pointer-events: none;

      .course-list-item {
        header,
        p,
        footer,
        h2,
        .button {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    &.nextUp {
      margin-left: 0px;
    }

    .courseWrapper {
      position: relative;

      &.single {
        li {
          border-radius: 12px !important;
        }
      }

      &:not(.single) {
        &:first-child {
          border-radius: 10px 10px 0 0;
        }
      }

      &.isExcluded {
        border-bottom: 0px;

        .status.icon {
          background-color: #f0f0f0;
          box-shadow: none;
        }

        .courseIndex:before {
          display: none;
        }
      }

      &:hover {
        .courseIndex {
          .icon {
            transform: scale(1.7);
          }
        }
      }

      &:first-child {
        .courseIndex:before {
          height: 50% !important;
          margin-top: 100% !important;
          border-radius: 4px 4px 0 0 !important;
        }
      }

      &.single .courseIndex:before {
        display: none;
      }

      &:last-child {
        .courseIndex:before {
          height: 50%;
          margin-top: -85%;
          border-radius: 0 0 4px 4px;
        }
      }

      &.isCompleted {
        .course-list-item {
          border-left: 0px;
        }

        .courseIndex:before {
          content: "";
          background-color: $green;
        }
      }
    }

    .courseIndex {
      position: absolute;
      left: -85px;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media (max-width: $screen-sm-max) {
        transform: translateX(10px);
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 4px;
        background-color: white;
        height: 100%;
      }

      .icon {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        height: 20px;
        width: 20px;
        transform: scale(1);
        border-radius: 100%;
        background-color: white;
        position: relative;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center center;

        &.in_progress {
          background-image: url("../img/laptop.svg");
        }

        &.submitted,
        &.grading_in_progress {
          background-position: center top 3px;
          background-image: url("../img/inbox.svg");
        }

        &.hasComments {
          background-image: url("../img/feedback.svg");
        }

        &.saved_for_later {
          background-image: url("../img/pin.svg");
        }

        &.completed {
          background-position: center;
          background-size: cover;
          background-color: $green;
          transform: scale(1.7);
          background-image: url("../img/i-check.svg");
        }
      }
    }

    h2 {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: -5px;
    }

    p {
      line-height: 18px;
    }
  }
}

.getHelp {
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;

    .buddy-image {
      transition: 0.3s;
      transform: scale(1.1);
    }
  }

  p {
    color: black;
  }

  .flexRow {
    flex: 1;
  }

  .button {
    color: white;
  }
}

.panel.quiz-panel {
  background-color: white;
  border-radius: 12px;
  padding: 25px;
  max-width: 1000px;
  opacity: 0;
  transition: 0.8s;
  overflow: hidden;
  height: 10px;

  &.shift {
    height: auto;
    opacity: 1;
  }

  ul {
    li {
      margin-bottom: 20px;
      border-top: 1px solid $light-grey;

      &:first-child {
        border: none;
      }

      header {
        font-weight: bold;
        font-size: 24px;
        margin-top: 30px;
        padding-left: 35px;
        position: relative;

        span {
          font-weight: normal;
          font-size: 14px;
          position: absolute;
          left: 0;
          top: 5px;
          margin-right: 5px;
          opacity: 0.6;
          width: 25px;
          height: 25px;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid black;
        }
      }

      ul {
        padding-bottom: 10px;

        li {
          border: none;
          margin: 15px 0;

          button {
            background-color: $light-grey;
            color: black !important;
            border-radius: 40px;
            text-transform: none !important;
            text-align: left;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
  }

  &.shift {
    display: block;
  }
}

#submit-modal {
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }
}

.quizFooter {
  animation: fadeInUp 1s forwards;
  position: fixed;
  left: 280px;
  top: 80px;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 8;

  &.submitFooter {
    top: auto;
    bottom: 35px;
  }

  .back {
    .MuiButtonBase-root {
      background-color: darken($light-grey, 5%);
    }
  }
}
