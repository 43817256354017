@import "./variables.scss";

.page.backpack-layout.print {
  display: flex;
  margin: 0;
  padding: 20px 20px 100px;
  max-width: 1200px !important;
  margin: 0 auto !important;

  #report-header {
    opacity: 1;
    align-items: center;
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 45px;

    .allCategories {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;

      li {
        flex: 0;
        flex-shrink: 0;
        text-transform: none;
        color: black;
        margin-right: 25px;
        padding: 0px;
        border-bottom: none !important;
        margin-bottom: 10px;

        strong {
          white-space: nowrap;
        }

        &:last-child {
          margin-right: 0px;
        }

        .capsule {
          margin-left: 5px;
          transform: scale(0.8);
          font-weight: normal;
        }
        .categoryIcon {
          width: 25px;
          height: 25px;
        }
        .categoryIcon,
        .capsule {
          flex-shrink: 0;
        }
      }
    }

    .timeInfo {
      display: flex;
      align-items: center;
    }

    .totalTime {
      margin-left: 15px;
      text-transform: none;
      font-size: 36px;
    }
  }

  .userContainer {
    flex: 1;
    padding-right: 50px;

    .userPicture {
      width: 75px;
      height: 75px;
      background-size: cover;
      border-radius: 100%;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.42);
      margin-right: 10px;
    }

    .userInfo {
      strong {
        font-size: 24px;
        text-transform: none;
      }

      span {
        opacity: 0.6;
        display: block;
        font-weight: normal;
        text-transform: uppercase;
        margin-top: -5px;
        font-size: 12px;
      }
    }
  }

  #floatingButton {
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 5;
    height: 80px;
    padding: 20px;
    align-items: center;

    .button {
      height: 50px;
    }
  }

  .content-row {
    animation: none;
    opacity: 1;
    max-width: 100%;

    header {
      border-bottom: 1px solid #c7c7c7;

      .count {
        .icon {
          width: 25px;
          margin-right: 5px;
        }

        strong {
          font-size: 24px;
        }
      }

      .button.small {
        display: none;
      }
    }
  }

  .print-section {
    display: flex;
    flex-wrap: wrap;

    .capsule {
      transform: scale(0.8);
      color: black;
      font-weight: bold;
      background-color: #24dc91;

      .icon {
        display: none;
      }
    }

    .item {
      width: 33%;
      display: flex;
      align-items: center;
    }

    .external-item {
      width: 33%;
      padding: 5px 8px;

      .icon {
        margin-right: 5px;
        width: 25px;
        height: 25px;
        background-image: url("../img/external-black.svg");
      }
    }

    .event-item {
      padding: 5px 8px;
      width: 33%;

      strong {
        margin-bottom: -2px;
      }

      .icon.calendar {
        position: relative;
        top: -1px;
        margin-right: 5px;
        width: 25px;
        height: 25px;
        background-image: url("../img/calendar-black.svg");
      }
    }

    .course-item {
      padding: 5px 8px;

      strong {
        flex: 1;
        font-size: 12px;
      }

      .category-icon {
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        display: inline-block;
        border-radius: 20px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
        overflow: hidden;
      }
    }

    .badge-item {
      width: 25%;
      padding: 6px 10px;

      .badgeImage {
        width: 35px;
        height: 35px;
        background-size: cover;
        border-radius: 35px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.42);
        margin-right: 5px;
        overflow: hidden;
        box-sizing: content-box;
      }

      strong {
        font-size: 12px !important;
        margin-bottom: -5px;
      }

      span {
        font-size: 10px;
      }
    }

    .learning-path-item,
    .rubric-item,
    .mandated-item {
      padding: 5px 8px;

      .title {
        font-size: 12px;
        margin-bottom: -2px;
      }

      .icon {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        margin-right: 5px;
      }
    }
    .learning-path-item .icon {
      background-image: url("../img/learning-path-black.svg");
    }
  }
}

@media print {
  main,
  .appInner,
  body,
  html {
    background-color: white !important;
  }

  #floatingButton {
    display: none !important;
  }

  .page.backpack-layout.print {
    display: block;
    margin: 0;
    padding: 20px;
    margin-left: -130px;
    background-color: white;
    width: 100% !important;
    margin-left: -200px !important;
    overflow: visible;

    .print-section {
      overflow: visible;
    }

    .totalTime {
      margin-right: 45px;
    }

    .capsule {
      background-color: black !important;
      background: black !important;
      color: white !important;
    }

    #report-header {
      width: 100%;
      margin-top: 0px !important;
      padding: 0px;
    }
  }
}
