@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Edu+QLD+Beginner:wght@400..700&family=Grape+Nuts&family=Italianno&family=Just+Another+Hand&family=Mrs+Saint+Delafield&family=Ms+Madi&family=Over+the+Rainbow&family=Playwrite+AR:wght@100..400&family=Qwitcher+Grypen:wght@400;700&family=Vibur&display=swap");

@keyframes delay {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.signature-text {
  font-size: 28px;
  font-optical-sizing: auto;
  font-style: normal;

  &.Edu-QLD-Beginner {
    font-family: "Edu QLD Beginner", serif !important;
  }
  &.Playwrite-AR {
    font-size: 24px;
    font-family: "Playwrite AR", serif !important;
    line-height: 1.1;
  }
  &.Mrs-Saint-Delafield {
    font-size: 34px;
    font-family: "Mrs Saint Delafield", serif !important;
  }
  &.Italianno {
    font-size: 34px;
    font-family: "Italianno", serif !important;
  }
  &.Just-Another-Hand {
    font-size: 34px;
    font-family: "Just Another Hand", serif !important;
  }
  &.Ms-Madi {
    font-size: 32px;
    font-family: "Ms Madi", serif !important;
  }
  &.Over-The-Rainbow {
    font-family: "Over the Rainbow", serif !important;
    line-height: 1.1;
  }
  &.Vibur {
    font-size: 34px;
    font-family: "Vibur", serif !important;
  }
  &.Qwitcher-Grypen {
    font-size: 38px;
    font-family: "Qwitcher Grypen", serif !important;
  }
  &.Grape-Nuts {
    font-size: 34px;
    font-family: "Grape Nuts", serif !important;
  }
}

.learningPathCertificatePrintContainer,
.certificateWrapper.print {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  .certificateBadge,
  .certificateEvent {
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
  }
  .certificateLearningPath {
    width: 1100px;
    height: 850px;
    border-radius: 12px;
    overflow: hidden;
    .inner h2 {
      line-height: 26px;
    }
    .category-icon {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      border: 3px solid white;
      display: block;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    }
  }
}

.eventCertificatePrintContainer {
  .certificateWrapper.print {
    background-color: white !important;
  }
}

.MuiDialog-paper {
  footer {
    display: flex;
    justify-content: center;
    padding: 20px 10px;

    &.certificateFooter {
      display: flex;
      justify-content: center;
      padding: 20px 10px;

      .button {
        margin: 0 15px;
        color: black;

        a {
          display: flex;
          align-items: center;
          color: black;
        }
      }
    }
  }

  .certificateWrapper {
    padding-top: 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0) !important;
    display: flex;
    flex-direction: column;

    .mm-bg {
      animation: delay 0.1s forwards;
      animation-delay: 1s;
      opacity: 0;
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-image: url("../img/certificate-bg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .category-icon {
      width: 20px;
      display: inline-block;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 100%;
      margin: 5px 5px 0 0;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    }

    .inner {
      z-index: 4;
      position: relative;
    }
  }
}

.learningPathImage {
  margin: 0 auto;
  position: absolute;
  left: 0%;
  top: 0;
  opacity: 0.15;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.certificateLearningPath {
  position: relative;
  width: 100%;

  .loadingWrapper {
    margin: 0 auto;
    position: relative;
    top: 40%;
  }

  .inner {
    animation: fadeIn 1s forwards;
    opacity: 0;
    width: 1100px;
    height: 850px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    .printBackground {
      height: 100%;
      width: auto;
      display: none;
    }

    @media print {
      .learningPathImage {
        display: none;
      }

      .printBackground.learningPathImage {
        display: block;
      }

      padding-top: 200px;
      transform-origin: top left;
      transform: scale(0.9);

      h2 {
        font-size: 24px;
        max-width: 100%;
      }

      .title {
        font-size: 58px;
        line-height: 64px;
        display: block;
        margin-top: 25px;
        margin-bottom: 20px;
      }

      .totalEstimatedTime {
        .icon {
          display: none;
        }

        bottom: 50px;
      }

      .categories {
        max-width: 100%;

        .category {
          float: left;
        }
      }

      .categories img {
        margin: -3px 8px 0 0;
        width: 30px;
        height: 30px;
        position: relative;
        border: 2px solid white;
        box-sizing: content-box;
      }

      .categories img:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }
  }

  h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 30px;
    line-height: 22px;

    span {
      font-size: 16px;
      font-style: italic;
    }
  }

  .categories {
    flex-wrap: wrap;
    justify-content: center;
    .category {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-right: 25px;

      .time {
        display: flex;
        flex-direction: column;

        strong {
          margin-bottom: -3px;
        }
      }
    }

    img {
      border: 3.5px solid white;
      box-sizing: content-box;
      width: 30px;
      margin-right: 10px;
      height: 30px;
      border-radius: 100%;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    }
  }

  .title {
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
  }

  .totalEstimatedTime {
    position: absolute;
    right: 35px;
    bottom: 35px;
    text-align: right;
    flex-direction: column;

    header {
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 2px;
    }

    strong {
      .icon {
        margin-right: 8px;
        position: relative;
        top: 1px;
      }

      font-size: 28px;
    }

    em {
      margin-top: 0px;
      font-size: 14px;
    }
  }

  .logoWrapper {
    bottom: 35px;
    width: 250px;
    position: absolute;

    img {
      width: auto;
      max-height: 50px;
      max-width: 250px;
    }
  }

  @media (max-width: 768px) {
    width: 400px;
    height: 310px;

    h2 {
      max-width: 250px;
      font-size: 18px;
      margin-bottom: 20px;

      span {
        margin: 5px 0;
        line-height: 14px;
        font-size: 12px;
      }
    }

    .title {
      line-height: 26px;
      font-size: 26px;
      margin-top: 0px;
    }

    .categories {
      max-width: 250px;
      margin-bottom: 75px;

      img {
        width: 15px;
        margin-top: 5px;
        margin-right: 5px;
        height: 15px;
      }
    }

    .learningPathImageWrapper {
      right: -150px;
      transform: scale(0.5);
    }

    .logo {
      left: -30px;
      bottom: 10px;
      transform: scale(0.5);
    }
  }
}

.learningPathImageWrapper {
  position: absolute;
  border-radius: 100%;
  overflow: hidden;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  right: -120px;

  img {
    width: auto;
    height: 300px;
  }
}

.certificateWrapper {
  .loadingWrapper {
    flex-direction: column;

    span {
      margin-left: 0px;
      top: 10px;
    }
  }
}

.bg-badge {
  width: 500px;
  height: 500px;
  z-index: 1;
  position: absolute;

  // background-color: silver;
  // background-image:
  //   radial-gradient(circle at 100% 150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
  //   radial-gradient(circle at 0 150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
  //   radial-gradient(circle at 50% 100%, white 10%, silver 10%, silver 23%, white 23%, white 30%, silver 30%, silver 43%, white 43%, white 50%, silver 50%, silver 63%, white 63%, white 71%, transparent 71%, transparent),
  //   radial-gradient(circle at 100% 50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent),
  //   radial-gradient(circle at 0 50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent);
  // background-size: 100px 50px;
}

.certificateBadge {
  width: 500px;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .printBadge {
    display: none;
  }

  @media print {
    .badgeImage {
      display: none;
    }

    .badgeImage.printBadge {
      display: block;
    }
  }

  h2 {
    margin-top: 0px;
    z-index: 2;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    padding: 35px;
    flex: 1;

    strong {
      display: block;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  footer {
    display: flex;
    z-index: 2;
    padding: 35px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 28px;
      text-align: center;
      max-width: 400px;
      line-height: 32px;
    }

    .timestamp {
      text-transform: uppercase;
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }

  .logo {
    height: 25px;
    width: auto;
  }
}

.badgeImageWrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .badgePlaceholder {
    background-image: url("../img/i-badge.svg");
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-size: contain;
    position: absolute;
    z-index: -1;
    animation: delay 0.1s forwards;
    animation-delay: 2s;
  }

  .badgeImage {
    animation: tada 2s forwards;
    width: 150px;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100%;
  }
}

.shareLink {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  position: relative;
  padding-top: 20px;
  height: 200px;

  .loadingWrapper {
    left: -30px;
    margin-top: 10px;

    .square {
      border-color: white;
    }
  }

  .shareUrl {
    padding: 0 20px 20px;
  }

  .MuiFormControl-root {
    width: 100%;
    padding-top: 5px;

    .MuiFormHelperText-root {
      opacity: 0;
      transition: 0.4s;
      text-align: center;
    }

    &.showHelperText {
      .MuiFormHelperText-root {
        opacity: 1;
      }
    }
  }

  .flexRow {
    padding-bottom: 30px;
    justify-content: center;

    .button {
      margin: 0 5px;

      .icon {
        position: relative;
        top: -1px;
        margin-right: 5px;
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }

  input {
    text-align: center;
    width: 100%;
    cursor: pointer;
    margin: 0 !important;
  }
}

.MuiDialog-scrollPaper {
  .certificateFooter {
    margin-top: -12px;
    z-index: 1;
    background: white;
  }

  .learningPathImage {
    opacity: 0.1;
  }
}
