@import "./variables.scss";

.icon.calendar.white {
  background-image: url("../img/calendar-white.svg");
  background-color: rgba(0, 0, 0, 0) !important;
}

.icon.calendar.blue {
  background-image: url("../img/calendar-blue.svg");
  background-color: rgba(0, 0, 0, 0) !important;
}

.icon.rsvp {
  background-image: url("../img/rsvp.svg");
}

.icon.rsvp-decline {
  background-image: url("../img/rsvp-decline.svg");
}

.icon.rsvp-tentative {
  background-image: url("../img/rsvp-tentative.svg");
}

#faq-link {
  .icon {
    width: 20px;
    height: 20px;
    background-image: url("../img/books.svg");
  }
}

.rsvpIndicator {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 5px;
  border-radius: 20px;
  box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.4);
  top: 5px;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 21111456780;

  &.accept {
    background-color: $green;
  }

  &.tentative {
    background-color: #efefef;
  }

  .icon {
    width: 12px;
    height: 12px;

    &.accept {
      background-image: url("../img/rsvp-new.svg");
    }

    &.tentative {
      background-image: url("../img/rsvp-tentative-new.svg");
    }
  }

  &.attended {
    .icon {
      width: 18px;
      height: 18px;
    }
  }
}

.conferenceScheduleWrapper {
  .rsvpIndicator {
    top: -8px;
  }
}

.buddyContainer {
  overflow: hidden;
  margin-top: 45px;
  .actions {
    flex: 1;

    .MuiFormControl-root {
      margin-bottom: 0;
      min-width: 100px;
      input {
        margin-bottom: 0;
      }
    }

    .MuiInput-underline:after,
    .MuiInput-underline:before {
      opacity: 0;
    }

    .flexRow {
      justify-content: flex-end;

      .button {
        margin-left: 20px;
      }
    }
  }
}

#inline-buddy-message {
  transform: translate3d(-2000px, 0, 0);
  animation: fadeInLeftBig 1s forwards;

  p {
    text-align: left !important;
  }

  .arrow {
    position: absolute;
    left: -10px;
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right-width: 10px;
  }

  &.inline > div {
    display: flex;
    align-items: center;
  }
}

#eventHeader {
  padding: 20px 20px 20px 30px;
  border-radius: 15px;
  margin: 15px 0 0 0px !important;
  justify-content: flex-end;
  max-width: 1024px;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%),
    0px 1px 1px 0px rgb(1 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  align-items: flex-start;
  position: relative;
  height: 200px;
  background-repeat: no-repeat;

  &.noImage {
    background-image: url("../img/calendar-white.svg") !important;
    background-position: top 20px center !important;
    background-size: 100px !important;
  }

  &:before {
    display: block;
    content: "";
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 8%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    top: 0;
    bottom: 0;
    border-radius: 15px;
  }

  .loadingWrapper {
    position: absolute;
    z-index: 5;
    right: 23px;
    top: 23px;
    margin-top: 0;
  }

  .register-button {
    z-index: 3;
    position: absolute;
    right: 20px;
    top: 20px;

    .button {
      min-width: 100px;
    }
  }

  a {
    position: absolute;
    bottom: -40px;
    right: 0px;
  }

  .capsuleWrapper {
    position: absolute;
    bottom: -18px;
    z-index: 2;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.8s;
    pointer-events: none;

    .inner {
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      border-radius: 40px;
      background-color: black;
      color: white;
      font-size: 14px;

      .capsule {
        background-color: transparent;
        padding: 0;
        margin: 0;
        height: 35px;
        display: flex;
        font-size: 14px;
        align-items: center;

        .icon.clock {
          width: 20px;
          height: 20px;
        }
      }

      section {
        margin: 0 15px;
      }

      .capacity-status {
        display: flex;
        align-items: center;

        .icon.chair {
          background-image: url("../img/chair-white.svg");
        }
      }

      .category-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        position: relative;
        box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.15);
        border-radius: 100%;
        border: 3px solid white;
        background-size: cover;
        display: inline-block;
      }

      .categoryInfo {
        display: flex;
        align-items: center;
        font-weight: normal;
      }
    }
  }

  .title {
    font-size: 32px;
    line-height: 30px;
    color: white;
    padding: 0 20px;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 30px;
    z-index: 3;

    .dateTime {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      display: block;
    }
  }

  .tagContainer {
    margin-top: 15px;
    margin-bottom: -5px;

    .tag-wrapper {
      cursor: initial;
      margin: 0;
      position: relative;
      top: -2px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding-right: 12.5px !important;
      margin-right: 10px !important;
    }
  }

  @media (max-width: $screen-sm-max) {
    padding: 15px;
    height: auto;

    .title {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .rsvp-info {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    z-index: 3;

    .icon.check {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      width: 35px;
      height: 35px;
      border-radius: 35px;
    }

    .button {
      color: black !important;
      background-color: $green;
      padding: 5px;
      height: 35px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      width: 35px;

      &:hover {
        background-color: lighten($green, 10%);
      }

      &.decline {
        background-color: $red;

        &:hover {
          background-color: lighten($red, 10%);
        }
      }

      &.tentative {
        background-color: #efefef;

        &:hover {
          background-color: darken(#efefef, 10%);
        }
      }

      .icon {
        width: 20px;
        margin-right: 0px;
        height: 20px;
        background-repeat: no-repeat;

        &:before {
          display: block;
          color: white;
          text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
          position: absolute;
          right: 45px;
        }

        &.rsvp {
          background-image: url("../img/rsvp-new.svg");

          &:before {
            content: "Attending";
          }
        }

        &.rsvp-tentative {
          background-image: url("../img/rsvp-tentative-new.svg");

          &:before {
            content: "Tentative";
          }
        }

        &.rsvp-decline {
          background-image: url("../img/rsvp-decline-new.svg");

          &:before {
            content: "Declined";
          }
        }
      }
    }
  }
}

#rsvp-menu {
  .MuiListItem-root {
    padding: 10px 20px;
    background-color: white;
    width: 200px;
    font-family: "Nunito";
    flex-direction: column;
    display: flex;

    &.active {
      background-color: $light-grey;
    }

    &:hover {
      background-color: $light-grey;
    }
  }

  &.hasRSVPd {
    .MuiPopover-paper {
      margin-left: -82px;
    }
  }

  .MuiPopover-paper {
    overflow: visible;
    margin-top: 5px;

    ul {
      display: flex;
      flex-direction: column;

      li {
        font-family: "Nunito", sans-serif !important;
        padding: 10px;
        width: 150px;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      right: 50%;
      margin-right: -8px;
    }
  }
}

.page.event-single {
  margin-left: 90px;
  margin-top: -25px;
  max-width: 1024px;

  .loadingWrapper {
    margin-top: 25px;
    margin-left: -15px;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
  }

  @media (max-width: $screen-sm-max) {
    margin-left: -180px !important;
    max-width: 95% !important;

    .eventCategory {
      opacity: 0;
    }

    .buddyContainer {
      flex-direction: column;
      height: auto;
    }

    .register-button {
      width: auto !important;
      position: relative !important;
      top: 0 !important;
    }

    span.title {
      margin-right: 0 !important;
    }

    .event-attendees {
      h3 {
        margin-bottom: 55px;
      }

      .inputSearch {
        top: -10px;
        left: 0px;
      }
    }
  }

  &.shift {
    margin-top: 40px;
  }

  &.edit {
    .sidebar-event {
      position: fixed;
    }
  }

  .sidebar-event {
    width: 325px !important;
    transition: 0.3s;

    &.shift {
      margin-top: 50px;
    }

    .icon.feedback {
      background-image: url("../img/feedback-black.svg");
    }

    li a {
      color: black;
      display: flex;
      align-items: center;
      height: 60px;
      width: 100%;
    }

    .entity-meta .backButton {
      width: 325px !important;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      margin-top: -20px;
      border-radius: 0;
      transition: 0.4s;
      font-size: 18px;
      text-transform: capitalize;
      font-weight: normal;
      background-color: transparent;

      &:hover {
        background-color: lighten($light-grey, 2%);
      }
    }

    .googleMapsWrapper {
      height: 250px;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 20px;
    }

    section {
      width: 325px;
      margin: 0 0 0 -20px;
      padding: 12px 20px;

      h3 {
        margin: 0 0 10px;
      }

      .icon {
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &.star {
          background-image: url("../img/star.svg");
        }

        &.calendar {
          background-image: url("../img/event-calendar.svg");
        }
      }
    }

    .count {
      color: #59c3e5;
      font-size: 18px;
      font-weight: 700;
    }

    .dateTime {
      display: flex;
      align-items: flex-start;

      strong {
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0.5;
        display: block;
      }
    }

    .conferenceLabel {
      animation: fadeInDown 0.3s forwards;
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0;

      a {
        color: white;
        display: flex;
        align-items: flex-start;

        &:hover {
          .icon {
            margin-right: 20px;
          }
        }
      }

      .icon {
        transition: 0.3s;
        width: 25px;
        height: 25px;
        background-image: url("../img/back-white.svg");
      }
    }

    @media (max-width: $screen-sm-max) {
      max-width: 60px;

      .buttonText {
        display: none;
      }

      .sidebar-tabs {
        li {
          text-indent: -9999px;
        }
      }

      .dateTime {
        opacity: 0;
      }

      .googleMap {
        opacity: 0;
      }

      .locationWrapper {
        opacity: 0;
      }
    }
  }

  section {
    margin: 40px 0;
  }

  .eventImageWrapper {
    height: 150px;
    width: 325px;
    margin-left: -20px;
    background-position: center;
    background-repeat: no-repeat;
  }

  h1 {
    font-size: 28px;
    text-align: left;
    font-weight: 600;
    display: flex;
    align-items: center;

    @media (max-width: $screen-sm-max) {
      font-size: 20px;
      line-height: 28px;
    }

    .actions {
      text-align: right;
      margin-left: 25px;
      position: relative;

      .register-button {
        position: absolute;
        right: 0;
        width: 270px;
        top: -28px;
        z-index: 4;
        line-height: 18px;
      }

      p {
        font-size: 14px;
        margin: 5px 0 0;
      }

      .button.small {
        margin-left: 35px;
      }

      .isAttending {
        position: relative;
        top: 5px;
      }
    }
  }

  .event-details {
    animation: fadeIn 0.3s;
    margin-top: 25px;

    .eventEntityWrapper {
      .course {
        color: black;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 10px;
        cursor: pointer;
        transition: 0.3s;

        .title {
          flex: 1;
        }

        .icon {
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          position: relative;
          top: 2px;

          &.laptop {
            background-image: url("../img/laptop.svg");
          }

          &.inbox {
            background-image: url("../img/inbox.svg");
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.07);
        }
      }
    }

    .eventEntityWrapper {
      flex: 1;
      max-width: 50%;

      .category-icon {
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 100%;
        box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.15);
        margin-right: 10px;
        flex-shrink: 0;
      }
    }

    .badgeAwarded {
      .badge {
        border-radius: 100%;
        width: 100px;
        height: 100px;
        box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.3);
        background-size: cover;
        background-position: center;
        margin-right: 15px;
      }

      strong {
        font-size: 18px;
      }
    }

    .presenter {
      align-items: flex-start;
      margin-bottom: 25px;
      max-width: 678px;
    }

    .presenterImage {
      width: 70px;
      height: 70px;
      border-radius: 100%;
      background-position: center;
      background-size: cover;
      box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.3);
      flex-shrink: 0;
    }

    .presenterInfo {
      padding-top: 0px;
      margin-left: 20px;

      strong {
        display: block;
        font-size: 18px;
      }
    }

    .attachment {
      font-weight: bold;
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 15px;
      border-radius: 10px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      transition: 0.3s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
      }

      .icon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .event-rubric {
    .MuiStack-root.criteria-grid {
      margin-bottom: 10px;
      flex-direction: row;
    }
    @media (max-width: 1250px) {
      .MuiStack-root.criteria-grid {
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 0px;
      }
    }
  }

  .event-updates {
    animation: fadeIn 0.3s;

    ul {
      margin: 0;
      padding: 0;

      li {
        background-color: white;
        padding: 20px;
        box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        margin-bottom: 20px;

        header {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          strong {
            flex: 1;
          }

          .timestamp {
            font-size: 12px;
            opacity: 0.5;
            font-weight: 700;
            font-weight: bold;
          }
        }
      }
    }
  }

  .appointmentInner.conference-event {
    &.fade {
      &:after {
        content: "";
        display: block;
        position: absolute;
        background-color: white;
        opacity: 0.6;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        transition: 0.4s;
      }

      &:hover {
        &:after {
          opacity: 0;
        }
      }
    }

    div {
      font-family: "Nunito" !important;
    }

    div:nth-child(2) {
      font-weight: normal;
      font-size: 8px;
    }

    .roomInfo {
      display: flex;
      align-items: center;
      position: absolute;
      top: 10px;
      right: 0px;
      font-weight: bold !important;
      font-size: 10px !important;

      .icon {
        width: 20px;
        height: 20px;
        background-image: url("../img/room-black.svg");
      }
    }

    header {
      line-height: 18px;
    }

    &.decline {
      header div {
        color: black !important;
      }
    }
  }

  .userPicture,
  .userPictureWrapper {
    width: 40px;
    height: 40px;
    background-size: cover;
    margin-right: 10px;
    background-image: url("../img/profile-pic.svg");
    border-radius: 100%;
    background-position: center;
  }

  .userPicture {
    box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.3);
  }

  .MuiInput-underline:before {
    display: none;
  }
  #categorySelect {
    margin-bottom: 0 !important;
  }
  .event-attendees {
    margin-top: 80px;
    animation: fadeIn 0.3s;

    .inputSearch {
      margin-top: 20px;

      label {
        margin-left: -15px;
      }
    }

    .MuiInput-underline:after,
    .MuiInput-underline:before {
      opacity: 0;
    }

    h3 {
      align-items: center;
      display: flex;

      .capacity-status {
        padding: 5px;
        margin-left: 20px;
        position: relative;
        top: 4px;

        strong {
          font-size: 28px;
          margin: 0 5px;
        }
      }
    }

    header {
      position: relative;
    }

    ul {
      margin-top: 25px;

      .loadingWrapper {
        left: 0;
        margin-left: 0;
      }

      li {
        padding: 10px 0;
        display: flex;
        font-weight: bold;
        align-items: center;

        .name {
          flex: 1;
        }

        .status {
          display: flex;
          align-items: center;
          position: relative;
          top: -3px;
          font-size: 12px;
          text-transform: uppercase;
        }

        .icon {
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 10px;
        }
      }
    }
  }

  .sidebar-tabs {
    width: 325px;
    margin-left: -20px !important;

    li {
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &.active {
          border-bottom: none;
        }
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        top: -1px;
        background-position: center;

        &.calendar {
          background-image: url("../img/calendar-black.svg");
        }

        &.message {
          background-image: url("../img/message.svg");
        }

        &.attendees {
          background-image: url("../img/attendees.svg");
        }

        &.details-info {
          background-image: url("../img/list.svg");
        }
      }

      font-weight: bold;
      font-size: 18px;
      padding: 0 20px;
      height: 60px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      &.active {
        border-top: 1px solid rgba(0, 0, 0, 0);
        background-color: $green;
      }
    }
  }
}

.calendarIntegrationModal {
  .small.cancel {
    color: #888;
  }

  .animationWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    &.tada {
      animation: tada 1.4s;
      margin-bottom: 45px;
    }
    .icon {
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
    }
  }

  ol li {
    margin-bottom: 10px;
  }
  .icon.calendar {
    background-image: url("../img/calendar.svg");
    opacity: 0.3;
    background-size: 20%;
    position: relative;
    left: -100px;
    animation: copyOver 2.25s infinite;

    @keyframes copyOver {
      0% {
        opacity: 0;
        left: -100px;
        transform: scale(1);
      }

      20% {
        opacity: 0.3;
        left: -100px;
        transform: scale(1);
      }

      50% {
        left: 0;
        transform: scale(2.4) rotate(-360deg);
      }

      80% {
        opacity: 0.3;
        left: 100px;
        transform: scale(1) rotate(-360deg);
      }

      100% {
        opacity: 0;
        left: 100px;
        transform: scale(1) rotate(-360deg);
      }
    }
  }

  .icon.google {
    background-image: url("../img/google-calendar-logo.svg");
    background-size: 90%;
  }

  .icon.outlook {
    background-image: url("../img/outlook-color.svg");
  }

  .icon.mm {
    background-image: url("../img/mm-logo-large.svg");
  }

  p {
    line-height: 20px;
    font-size: 14px;
  }

  .icon.close {
    background-image: url("../img/x.svg");
    width: 25px;
    height: 25px;
    position: absolute;
    background-size: 15px;
    display: block;
    opacity: 0.2;
    background-repeat: no-repeat;
    right: 20px;
    top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.4;
    }
  }

  footer {
    margin-bottom: -20px;
  }
}

.currentRoom {
  > p {
    margin: 0px 0 0 34px;
    font-size: 14px;

    p {
      margin: 0;
    }
  }

  .icon.room {
    background-image: url("../img/room.svg");
    width: 20px;
    height: 20px;
  }
}

.panel {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.event-content.calendarLayout {
  padding: 20px 0 0 !important;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-top: 5px;
  position: relative;
  border-radius: 12px;

  .MuiPaper-root {
    box-shadow: none;
    overflow: auto;
    max-width: 100%;
  }
}

.calendarLayout {
  opacity: 1;

  td {
    text-align: center;

    &:hover,
    &:focus {
      background-color: white;
    }
  }
}

.conference-events-list {
  border-top: 1px solid $light-grey;
  width: 100%;
  ul {
    list-style-type: none;
    padding: 0px;
    max-height: 560px;
    overflow: auto;
    margin: 0;

    li {
      position: relative;
      margin: 0;
      padding: 20px;
      padding-right: 40px;
      border-bottom: 1px solid $light-grey;
      transition: background-color 0.5s;
      background-color: white;
      cursor: pointer;

      .rsvp-info {
        position: relative;
        width: 30px;
        height: 30px;
        margin-left: 20px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.hasRSVPd {
          box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.25);
        }

        &.accept {
          background-color: $green;
        }

        &.tentative {
          background-color: #efefef;
        }

        &.decline {
          background-color: $red;
        }

        .rsvp {
          width: 15px;
          height: 15px;
          display: block;

          &.accept {
            background-image: url("../img/rsvp-new.svg");
          }

          &.tentative {
            background-image: url("../img/rsvp-tentative-new.svg");
          }

          &.decline {
            background-image: url("../img/rsvp-decline-new.svg");
          }
        }
      }

      .roomInfo {
        margin-top: 5px;
        font-weight: bold;
        margin-left: -3px;
        max-width: 150px;
        max-width: 210px;
        text-align: right;
      }

      .icon.room {
        display: block;
        width: 20px;
        height: 20px;
        margin-top: 1px;
        margin-right: 5px;
        background-image: url("../img/room.svg");
      }

      &:hover {
        background-color: $light-grey;
      }

      &:last-child {
        border-bottom: 0;
      }

      .title {
        flex: 1;
        position: relative;
      }

      .dateTime {
        margin-top: 3px;
        font-size: 14px;

        .date {
          opacity: 0.6;
        }
      }
    }
  }
}

.event-content.conferenceCalendar {
  margin-top: 60px !important;

  table {
    max-width: 100%;

    div + div div:first-child {
      display: none;
    }
  }

  .scheduleWrapper {
    margin-top: -53px;

    > div > div > div > div {
      width: 100%;

      &:nth-child(2) {
        > div {
          > div:last-child {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.MuiTableRow-root {
  td div,
  td span {
    background-color: transparent !important;
    font-family: "Nunito" !important;
  }
}

.event-content {
  [class*="makeStyles-container"] {
    padding-right: 35px;
    margin-right: -35px;
    overflow: auto !important;
  }

  .scheduleWrapper {
    height: 63vh;
    width: auto;

    th,
    td {
      div {
        font-family: "Nunito" !important;
      }
    }

    > div {
      margin-left: 100px;
    }
  }
}

.calendarInner {
  position: relative;

  .rightActions {
    position: absolute;
    right: 15px;
  }

  .adjustRange {
    margin-left: 20px;
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.page.event-single {
  .button.small.expand {
    margin-top: 0;

    img {
      width: 18px;
      margin-right: 0;
    }
  }

  &.isFullScreen {
    background-color: white;
    .conferenceScheduleWrapper {
      background-color: white;
      transition: 0s !important;
      position: fixed;
      top: 80px;
      right: 0;
      left: 0;
      z-index: 19;
      transition: 1s;
      border-radius: 0;
      box-shadow: none;

      &.multiDay {
        top: 70px;
      }
    }

    .conference-events-list {
      width: 100%;
      padding-right: 40px;
    }

    .conferenceCalendar {
      padding-top: 40px !important;
      height: 100vh;

      .calendarInner {
        padding-top: 20px;

        .conference-events-list {
          min-height: 74.5vh;
          padding-bottom: 150px;

          ul {
            max-height: 74.5vh !important;
          }
        }

        &.multiDay {
          padding-top: 0;
        }

        .MuiPaper-root {
          max-width: 100%;
        }

        &.isEditing > .MuiPaper-root {
          background-color: white;
        }

        .scheduleWrapper {
          margin-right: 0px;
          height: calc(100vh - 95px);
          opacity: 1 !important;
          width: auto;

          &.shift {
            &:before {
              content: "";
              display: block;
              position: fixed;
              background-color: rgba(0, 0, 0, 0.6);
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              z-index: 4;
            }
          }

          > div > div {
            overflow: auto;
            width: auto;
          }
        }
      }
    }
  }
}
